import {useState, useEffect, React} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfile} from '../../redux/profile';
import {BsGenderMale} from 'react-icons/bs';
import {BsGenderFemale} from 'react-icons/bs';
import './Profile.scss';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { updateUserProfile } from '../../redux/login';

function Profile() {
    const {userProfileList} = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({f_name: "", l_name: "", gender: ""});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
        if (name === 'gender') {
            setGender(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateUserProfile(formData));
        toast.success("Profile Info Updated", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0);

        if (userProfileList.length != 0) {
            console.log("userProfileList: ",userProfileList);
            setFirstName(userProfileList.f_name);
            setLastName(userProfileList.l_name);
            setGender(userProfileList.gender);


            setFormData({f_name: userProfileList.f_name, l_name: userProfileList.l_name, gender: userProfileList.gender})

        }

    }, [userProfileList]);

    useEffect(() => {

        dispatch(getUserProfile());

    },[])

    return (
        <div>
            <div>
                <h2 className='text-3xl font-medium mb-3'>General Profile Info</h2>
                <form className='general-profile-info' onSubmit={handleSubmit}>
                    <div className='field'>
                        <label htmlFor="f_name">First Name</label>
                        <input placeholder='First Name' required={true} defaultValue={firstName} type="text" id="f_name" name="f_name" onChange={handleChange}/>
                    </div>
                    <div className='field'>
                        <label htmlFor="l_name">Last Name</label>
                        <input placeholder='Last Name' required={true}  defaultValue={lastName} type="text" id="l_name" name="l_name" onChange={handleChange}/>
                    </div>
                    <div className='gender'>
                        <div className='field'>
                            <input checked={gender === 'Male'} type="radio" id="male" name="gender" value="Male"
                                   onChange={handleChange}/>
                            <label htmlFor="male">
                                <BsGenderMale className='stroke-1'/>
                                <span>Male</span>
                            </label>
                        </div>
                        <div className='field'>
                            <input checked={gender === 'Female'} type="radio" id="female" value="Female"
                                   name="gender"
                                   onChange={handleChange}/>
                            <label htmlFor="female">
                                <BsGenderFemale className='stroke-1'/>
                                <span>Female</span>
                            </label>
                        </div>
                    </div>
                    <div className='field'>
                        <button className="btn" type="submit">Update Info</button>
                    </div>
                </form>
            </div>
            <div>
                <h2 className='text-3xl font-medium mb-3 mt-8'>Security Info</h2>
                <div>
                    <div className='max-w-[640px]'>
                        <span className='block font-medium mb-3.5'>Email ID</span>
                        <span className='bg-white flex items-center rounded-full w-full px-8 h-[49px] text-[#D1D1D1]'>{userProfileList ? userProfileList.email : ''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
