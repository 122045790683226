// src/components/MultiItemCarousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './FeaturedListPromotionalBanner.scss'; // Add your custom CSS for styling
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from '../../Product/Product';
import { Link } from 'react-router-dom';

const FeaturedListPromotionalBanner = ({ data }) => {
    const settings = {
        infinite: false,
        slidesToShow: 4, // Number of items to show at once
        slidesToScroll: 1,
        variableWidth: true,
        nav: false,
        margin: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                },
            },
        ],
    };

    return (
        <Container fluid className='FeaturedListPromotionalBanner'>
            <Container>
                <div className="multi-item-carousel">
                    {data.map((element, index) => {
                        return (
                            <Row key={index}>
                                <Col xs={4} className='banner max-md:hidden md:pl-0 md:pr-3'>
                                    <Link to={element.handle}>
                                    <img src={element.promotional_banner} alt='' />
                                    </Link>
                                </Col>
                                <Col xs={12} md={8} className='px-0'>
                                    <Slider {...settings}>
                                        {element.product_featured_list.map((product, index) => {
                                            return (
                                                <div className="carousel-item" key={index}>
                                                    <Product productData={product} handle={element.handle}/>
                                                </div>
                                            );
                                        })}
                                    </Slider>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </Container>
        </Container>
    );
};

export default FeaturedListPromotionalBanner;
