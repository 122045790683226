import React from 'react'
import './MainLoader.scss';

function PaymentLoader() {
    return (
        <>
            {/*<Spinner size={50}/>*/}
            <div className='payment-loader'>
                <div className="loading">
                    <div className="loader"></div>
                </div>
            </div>
        </>
    )
}

export default PaymentLoader
