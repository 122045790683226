import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';

function CmsPage({ page }) {
    const { cmsPages } = useSelector((state) => state.customerServices);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container>
            {
                cmsPages.filter(cms => cms.handle === page.handle).map((details, index) => (
                    <div key={index}>
                        {
                            details.title !== "Valentines Day Special Giveaway" &&
                            <h2 className='md:pt-8 md:pb-16 text-4xl text-center font-extralight max-md:mb-8'>{details.title}</h2>
                        }
                        <div dangerouslySetInnerHTML={{ __html: details.description }} />
                    </div>
                ))
            }
        </Container>
    )
}

export default CmsPage
