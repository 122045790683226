import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHandleDetailsData } from "../services/apiCalls";

export const getHandleDetailsThunk = createAsyncThunk("Handle/getHandleDetails", async (slug) => {
    const res = await getHandleDetailsData(slug);
    return res.data;
})

const INITIAL_STATE = {
    handleData: {},
    handleTypeData: {},
    loader: false,
}

const handleSlugSlice = createSlice({
    name: "handleSlug",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHandleDetailsThunk.pending, (state, action) => {
                console.log("Handle Details API pending");
                state.loader = true;
            })
            .addCase(getHandleDetailsThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.handleData = action.payload.details;
                    state.handleTypeData = {
                        'code': action.payload.type_code,
                        'label': action.payload.type_label,
                        'name': action.payload.type_name,
                    };
                }
                else {
                    state.handleData = {}
                    state.handleTypeData = {}
                }
                state.loader = false;
                console.log("Handle Details API completed");
            })
            .addCase(getHandleDetailsThunk.rejected, (state, action) => {
                state.loader = false;
                state.handleData = {}
                state.handleTypeData = {}
                console.log("Handle Details API issue : ", action)
            })
    }
})

export default handleSlugSlice.reducer;