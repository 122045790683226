import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from 'react-redux';
import MobileDetect from 'mobile-detect';
import { BrowserView, MobileView, isDesktop, isMobile, isAndroid, isIOS } from 'react-device-detect';
import { processUserLoginApi, processUserRegistrationApi, processUserSendOtpApi, processUserVerifyOtpApi, getUserProfileDetailsApi, processUserLogoutApi, updateProfileData } from "../services/apiCalls";
import { 
    setCustomerToken, getCustomerToken, clearCustomerToken, 
    setIsLogin, getIsLogin, clearIsLogin, 
    setUniqueId, getUniqueId, clearUniqueId,
    setUser, getUser, clearUser
 } from "./../Constants/storedValues";

const INITIAL_STATE = {
    isUserLoggedIn: false,
    isLoginPopUpOpen: false,
    loginData: {},
    registrationData: {},
    sendOtpData: {},
    verifyOtpData: {},
    profileData: {},
    logoutData: {},
    profileName: 'Login',
    blogs : [],
    allProfileData:{},
    updateUserProfileData: {},

}

export const setLoggedInStatusThunk = createAsyncThunk("Login/setLoggedInStatus", async () => {
    let currentStatus = getIsLogin();
    return { 'status': (currentStatus === 'true') };
});

export const setLoginPopupStatusThunk = createAsyncThunk("Login/setLoginPopupStatus", async (statusValue) => {
    let currentStatus = statusValue;
    return { 'status': currentStatus };
});

export const processUserLoginThunk = createAsyncThunk("Login/processUserLogin", async (postData) => {
    const res = await processUserLoginApi(postData);
    return res.data;
});

export const processUserRegistrationThunk = createAsyncThunk("Login/processUserRegistration", async (thunkArgs) => {
    
    const res = await processUserRegistrationApi(thunkArgs.requestData, thunkArgs.token);
    return res.data;
});

export const processUserSendOtpThunk = createAsyncThunk("Login/processUserSendOtp", async (authToken) => {
    const res = await processUserSendOtpApi(authToken);
    return res.data;
});

export const processUserVerifyOtpThunk = createAsyncThunk("Login/processUserVerifyOtp", async (thunkArgs) => {
    const res = await processUserVerifyOtpApi(thunkArgs.requestData, thunkArgs.token);
    return res.data;
});

export const getUserProfileDetailsThunk = createAsyncThunk("Login/getUserProfileDetails", async () => {
    const res = await getUserProfileDetailsApi();
    return res.data;
});
export const updateUserProfile = createAsyncThunk("Profile/Update", async (userData) => {
    const res = await updateProfileData(userData);
    console.log("Result ", res);
    return res.data;
})

export const processUserLogoutThunk = createAsyncThunk("Login/processUserLogout", async () => {
    let postData = {};
    /* const mobileDetect = new MobileDetect(window.navigator.userAgent);
    if (mobileDetect.os() === "iOS") {
        postData['device'] = 'ios';
      } else if (mobileDetect.os() === "AndroidOS") {
        postData['device'] = 'android';
      } else if (mobileDetect.os() === "BlackBerryOS") {
        postData['device'] = 'blackberry';
      } else if (mobileDetect.os() === "WindowsOS") {
        postData['device'] = 'web';
      } else if (mobileDetect.os() === "MacOS") {
        postData['device'] = 'web';
      } else {
        postData['device'] = 'unknown';
      } */
    if (isIOS()) {
        postData['device'] = 'ios';
    } else if (isAndroid()) {
        postData['device'] = 'android';
    } else if (isDesktop()) {
        postData['device'] = 'web';
    } else {
        postData['device'] = 'unknown';
    }
    const res = await processUserLogoutApi(postData);
    return res.data;
});

export const setProfileDisplayNameThunk = createAsyncThunk("Login/setProfileDisplayName", async () => {
    let currentUsername = 'Login';
    let currentProfileDetails = getUser();
    if (Object.keys(currentProfileDetails).length > 0) {
        let displayName = currentProfileDetails['fName'] + ' ' + currentProfileDetails['lName'];
        if (displayName.trim() !== '') {
            currentUsername = displayName;
        }
    }
    return { 'displayName': currentUsername };
});

const loginStateSlice = createSlice({
    name: "loginState",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(setLoggedInStatusThunk.pending, (state,action)=>{
            console.log("login status pending");
        })
        .addCase(setLoggedInStatusThunk.fulfilled, (state,action)=>{
            state.isUserLoggedIn = action.payload.status;
            console.log("login status completed");
        })
        .addCase(setLoggedInStatusThunk.rejected, (state,action)=>{
            console.log("login status error api issue : ", action)
        })
        .addCase(setLoginPopupStatusThunk.pending, (state,action)=>{
            console.log("login popup status pending");
        })
        .addCase(setLoginPopupStatusThunk.fulfilled, (state,action)=>{
            state.isLoginPopUpOpen = action.payload.status;
            console.log("login popup status completed");
        })
    
        .addCase(setLoginPopupStatusThunk.rejected, (state,action)=>{
            console.log("login popup status error api issue : ", action)
        })
        .addCase(processUserLoginThunk.pending, (state,action)=>{
            console.log("User Login API status pending");
        })
        .addCase(processUserLoginThunk.fulfilled, (state,action)=>{
            state.loginData = {}
            if (action.payload.code === 1) {
                state.loginData = action.payload;
            }
        })
        .addCase(processUserLoginThunk.rejected, (state,action)=>{
            console.log("User Login API status error api issue : ", action)
        })
        .addCase(processUserRegistrationThunk.pending, (state,action)=>{
            console.log("User Registration API status pending");
        })
        .addCase(processUserRegistrationThunk.fulfilled, (state,action)=>{
            state.registrationData = {};
            if (action.payload.code === 1) {
                state.registrationData = action.payload;
            }
        })
        .addCase(processUserRegistrationThunk.rejected, (state,action)=>{
            console.log("User Registration API status error api issue : ", action)
        })
        .addCase(processUserSendOtpThunk.pending, (state,action)=>{
            console.log("User Send OTP API status pending");
        })
        .addCase(processUserSendOtpThunk.fulfilled, (state,action)=>{
            state.sendOtpData = {};
            if (action.payload.code === 1) {
                state.sendOtpData = action.payload;
            }
        })
        .addCase(processUserSendOtpThunk.rejected, (state,action)=>{
            console.log("User Send OTP API status error api issue : ", action)
        })
        .addCase(processUserVerifyOtpThunk.pending, (state,action)=>{
            console.log("User Verify OTP API status pending");
        })
        .addCase(processUserVerifyOtpThunk.fulfilled, (state,action)=>{
            state.verifyOtpData ={};
            if (action.payload.code === 1) {
                state.verifyOtpData = action.payload;
            }
        })
        .addCase(processUserVerifyOtpThunk.rejected, (state,action)=>{
            console.log("User Verify OTP API status error api issue : ", action)
        })
        .addCase(getUserProfileDetailsThunk.pending, (state,action)=>{
            console.log("User Profile Details API status pending");
        })
        .addCase(getUserProfileDetailsThunk.fulfilled, (state,action)=>{
            state.profileData = {};
            state.profileName = 'Login';
            state.blogs = [];
            if (action.payload.code === 1) {
                state.profileData = action.payload;
                const profDetails = action.payload.result[0];
                state.allProfileData = action.payload.result[0];
                console.log("profile data : ",profDetails);
                state.profileName = profDetails['f_name'] + ' ' + profDetails['l_name'];
                state.blogs =action.payload.blogs;
            }
        })
        .addCase(getUserProfileDetailsThunk.rejected, (state,action)=>{
            console.log("User Profile Details API status error api issue : ", action)
        })
        .addCase(processUserLogoutThunk.pending, (state,action)=>{
            console.log("User Logout API status pending");
        })
        .addCase(processUserLogoutThunk.fulfilled, (state,action)=>{
            state.logoutData = {};
            state.profileName = 'Login';
            if (action.payload.code === 1) {
                state.logoutData = action.payload;
                state.profileName = 'Login';
            }
        })
        .addCase(processUserLogoutThunk.rejected, (state,action)=>{
            console.log("User Logout API status error api issue : ", action)
        })
        .addCase(setProfileDisplayNameThunk.pending, (state,action)=>{
            console.log("User Profile Name Display status pending");
        })
        .addCase(setProfileDisplayNameThunk.fulfilled, (state,action)=>{
            state.profileName = action.payload.displayName;
            console.log("User Profile Name Display status completed");
        })
        .addCase(setProfileDisplayNameThunk.rejected, (state,action)=>{
            console.log("User Profile Name Display status error issue : ", action)
        })
        .addCase(updateUserProfile.pending, (state, action) => {
            console.log("pending")
        })
        .addCase(updateUserProfile.fulfilled, (state, action) => {
            if (action.payload.code === 1) {
                state.updateUserProfileData = action.payload;
                const profDetails = action.payload.result[0];
                state.profileName = profDetails['f_name'] + ' ' + profDetails['l_name'];
                console.log("Update Profile : ", action.payload);
                const userStorageData = {
                    'id': profDetails['id'],
                    'fName': profDetails['f_name'],
                    'lName': profDetails['l_name'],
                    'mobile': profDetails['mobile'],
                    'email': profDetails['email'],
                    'gender': profDetails['gender'],
                    'dob': profDetails['dob'],
                    'photoUrl': profDetails['photo_url'],
                };
                clearUser();
                setUser(userStorageData);
                window.dataLayer.push(
                    {
                        'event': 'mpCustomerSession',
                        "customer": {
                            "isLoggedIn": true,
                            "firstName": profDetails['f_name'],
                            "LastName": profDetails['l_name'],
                            "dob": profDetails['dob'],
                            "gender": profDetails['gender']
                          }
                    }
                );
            } else {
                console.log("Request error");
            }

        })
        .addCase(updateUserProfile.rejected, (state, action) => {
            console.log("rejected ", action)
        })
    }
});

export default loginStateSlice.reducer;
