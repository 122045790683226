import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { getAddressData, deleteUserAddress } from '../../redux/profile';

function Address() {
    const { addressList } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const pathURL = '/myProfile';
    const addAddressURL = '/add-address';
    const editAddressURL = '/edit-address';

    const navigate = useNavigate();
    const [fullscreen, setFullscreen] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleAddAddressClick = (targetUrl) => {
        navigate(pathURL + targetUrl);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        dispatch(getAddressData());


    };

    const handleConfirmDelete = (userID) => {
        dispatch(deleteUserAddress(userID));
        dispatch(getAddressData());
        handleCloseDeleteModal();
        toast.success("Address Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAddressData());
    }, []);

    useEffect(() => {
        dispatch(getAddressData());


    }, [showDeleteModal])

    const sortedAddressList = addressList.slice().sort((a, b) => {
        if (a.is_default === "1" && b.is_default !== "1") return -1;
        if (a.is_default !== "1" && b.is_default === "1") return 1;
        return 0;
    });

    return (
        <div className="container">
            <div className="flex justify-between">
                <h2 className="text-3xl font-medium">Addresses</h2>
                <button
                    className="font-normal h-auto text-base px-4"
                    onClick={() => handleAddAddressClick(addAddressURL)}
                >
                    Add New Address
                </button>
            </div>
            <div className="text-xl font-medium mt-3.5 mb-2">Default Address</div>
            <div className="address-items">
                {sortedAddressList.map((address, index) => {
                    if (address.id !== 'new') {
                        const isDefaultAddress = address.is_default === "1";
                        const addressType =
                            address.address_type === "1"
                                ? 'Home'
                                : address.address_type === "2"
                                    ? 'Office'
                                    : address.address_type === "3"
                                        ? 'Other'
                                        : "";

                        return (
                            <div className="address-item" key={index}>
                                {/* {isDefaultAddress && <span>Default Address</span>} */}
                                <div>
                                    <div className="address-type">
                                        <span>{addressType}</span>
                                    </div>
                                    <div className="name">
                                        <label>Name</label> <span>{`${address.f_name} ${address.l_name}`}</span>
                                    </div>
                                    <div className="address">
                                        <label>Address</label>
                                        <span>{`${address.building_name}, ${address.full_address}`}</span>
                                    </div>
                                    <div className="phone">
                                        <label>Phone</label>
                                        <span>{address.mobile}</span>
                                    </div>
                                </div>
                                <div className="actions">
                                    <Link
                                        className="btn"
                                        to={{
                                            pathname: `${pathURL}${editAddressURL}`,
                                        }}
                                        state={{ existingData: address }}
                                    >
                                        Edit
                                    </Link>
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setSelectedAddress(address);
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>

            <Modal
                className="delete-address"
                show={showDeleteModal}
                fullscreen={fullscreen}
                onHide={handleCloseDeleteModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the address?</p>
                    <div className="actions">
                        <Button onClick={handleCloseDeleteModal}>Cancel</Button>
                        <Button onClick={() => handleConfirmDelete(selectedAddress.id)}>Confirm</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Address;
