import React, {useState} from 'react';
import Container from 'react-bootstrap/Container'

function MoreDetails() {
    return (
        <Container className='bg-[#f8f8f8] p-4'>
            <div className='flex justify-between'>
                <div className='flex flex-col'>
                    <div className='font-bold'>805014</div>
                    <div className='text-sm'>Requested on 15 Jun 2023</div>
                </div>
                <div className='flex'>
                    <span className='text-sm'>Status: </span>
                    <span className='font-bold'> Approved</span>
                </div>
            </div>

            <div className='bg-white p-6 rounded-xl mt-6'>
                <table className='w-full text-center'>
                    <tbody>
                    <tr>
                        <td className='font-bold border-r'>Acm vitix Tablets</td>
                        <td className='border-r' width='200'>5</td>
                        <td className='border-r'>0 <br/> Insurance Rejected</td>
                        <td className='text-end'><button className='bg-black border-black font-normal'>Add To Cart</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='bg-white p-6 rounded-xl mt-6'>
                <table className='w-full text-center border-collapse' >
                    <tbody>
                    <tr className='columns-4'>
                        <td className='font-bold border-r'>Kafoless Syrup100Mi</td>
                        <td className='border-r'>10</td>
                        <td className='border-r'>20 <br/> CoPay</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='bg-white p-6 rounded-xl mt-6'>
                <table className='w-full text-center'>
                    <tbody>
                    <tr>
                        <td className='font-bold border-r'>Acm vitix Tablets</td>
                        <td className='border-r' width='200'>5</td>
                        <td className='border-r'>0 <br/> Insurance Rejected</td>
                        <td className='text-end'><button className='bg-black border-black font-normal'>Add To Cart</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Container>
    );
}

export default MoreDetails;
