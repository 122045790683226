import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './BannerSlider.scss';
import { Link } from 'react-router-dom';

function BannerSlider({ sliderData }) {
    const isTab = window.innerWidth <= 991;
    return (
        <div className='home_main_banner'>
            <Carousel autoPlay={true} interval={3000} infiniteLoop={true} showThumbs={false} emulateTouch={true} swipeable={true} Responsive ={true}  >
                {sliderData.map((element, index) => {
                    let url = '#';
                    if (element.redirect_url != null) {
                        const redirectUrl = element.redirect_url;
                        const myArray = redirectUrl.split("/");
                        url = myArray[myArray.length - 1];
                    }
                    return (
                        <div key={index}>
                            <Link className='inline-block max-md:px-3' to={`${url}`}>
                                <img className='max-md:rounded-lg w-full' src={isTab ? element.image : element.web_image} alt='' key={index} />
                            </Link>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
}

export default BannerSlider
