import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEmiratesData, setEditedAddressData } from '../../../redux/profile';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

function UpdateAddressMobileView() {
  const pathURL = '/myProfile';
    const listAddressURL = '/my-address'
    const location = useLocation()
    const existingData = location.state ? location.state.existingData : false;
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            address_type: existingData ? existingData.address_type : 0,
        },
        mode: "onChange"
    });
    const { editedAddressData } = useSelector(state => state.profile);
    const { getEmiratesList } = useSelector(state => state.profile);
    const [buildingName, setBuildingName] = useState(existingData ? existingData.building_name : '');
    const [mobile, setMobile] = useState(existingData ? existingData.mobile : '');
    const [city, setCity] = useState(existingData ? existingData.city : '');
    const [fullAddress, setFullAddress] = useState(existingData ? existingData.full_address : '');
    const [firstName, setFirstName] = useState(existingData ? existingData.f_name : '');
    const [lastName, setLastName] = useState(existingData ? existingData.l_name : '');
    const [isEditAddress, setIsEditAddress] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        const formattedData = { ...data };
        if (data.is_default === true) {
            formattedData.is_default = 1;
        }
        else if (data.is_default === false) {
            formattedData.is_default = 0;
        }
        dispatch(setEditedAddressData(formattedData));
        navigate(listAddressURL);
        toast.success("Address Edited Successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };
    useEffect(() => {
        setIsEditAddress(editedAddressData);
        if (isEditAddress) {
            navigate(listAddressURL);
            setIsEditAddress(false);
        }
    }, [editedAddressData]);
    useEffect(() => {
        dispatch(getEmiratesData());
    }, [dispatch]);
    useEffect(() => {
        if (existingData) {
            if (existingData.is_default === "1") {
                setEnabled(true);
            }
            else if (existingData.is_default === "0") {
                setEnabled(false);
            }
        }
        else {
            setEnabled(false);
        }
    }, [existingData ? existingData : ''])
    return (
        <div>
            <h2 className='text-3xl font-medium md:mb-12 sr-only'>Edit Address</h2>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-2.5 max-lg:px-3'>
                        <label className='sr-only'>First Name</label>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.f_name ? 'First Name Required' : 'First Name'}
                            defaultValue={firstName}
                            onChange={(e) => setFirstName(e.target.value)} {...register("f_name", {required: true})} />
                        <label className='sr-only'>Last Name</label>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.l_name ? 'Last Name Required' : 'Last Name'}
                            defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)} {...register("l_name", {required: true})} />
                        <label className='sr-only'>Address</label>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.full_address ? 'Address Required' : 'Address'}
                            defaultValue={fullAddress}
                            onChange={(e) => setFullAddress(e.target.value)}  {...register("full_address", {required: true})} />
                        <label className='sr-only'>Apartment</label>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.building_name ? 'Apartment Required' : 'Apartment'}
                            defaultValue={buildingName}
                            onChange={(e) => setBuildingName(e.target.value)} {...register("building_name", {required: true})} />
                        <label className='sr-only'>City</label>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.city ? 'City Required' : 'City'}
                            defaultValue={city}
                            onChange={(e) => setCity(e.target.value)} {...register("city", {required: true})} />
                        <label className='sr-only'>Emirate ID</label>
                        <div className='flex w-full gap-2 col-span-2'>
                            <label className='sr-only'>Phone</label>
                            <span
                                className='h-[48px] rounded-lg border px-4 placeholder:font-light bg-white inline-flex justify-center items-center text-gray-500 text-xs'>+971</span>
                            <input
                                className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border block w-full ${errors.f_name ? ' error' : ''}`}
                                placeholder={errors.mobile ? 'Contact Required' : 'Contact'}
                                defaultValue={mobile}
                                onChange={(e) => setMobile(e.target.value)} {...register("mobile", {required: true})} />
                            <input hidden {...register("other_address_type_name")} />
                        </div>
                        <select className='h-[48px] w-full rounded-lg px-3 mb-2 placeholder:font-light bg-white col-span-2 text-sm'
                                value={existingData ? existingData.emirate_master_id : '1'} {...register("emirate_master_id", { required: true })} >
                            {getEmiratesList.map((data, index) => {
                                return (
                                    <>
                                        <option key={index} value={data.id}>{data.name}</option>
                                    </>
                                );
                            })}
                        </select>
                        <div className='flex justify-between items-center flex-wrap col-span-2 px-3'>
                            <div className='inline-flex w-full mb-6'>
                                <label className='sr-only'>Address Type</label>
                                <div className='flex gap-6 address-type'>
                                    <label>
                                        <div>
                                        <span className='input_field'>
                                            <input defaultChecked type="radio"
                                                   value={1} {...register("address_type", {required: true})} />
                                        </span>
                                            <span>Home</span>
                                        </div>
                                    </label>
                                    <label>
                                        <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                   value={2}  {...register("address_type", {required: true})} />
                                        </span>
                                            <span>Work</span>
                                        </div>
                                    </label>
                                    <label>
                                        <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                   value={3}  {...register("address_type", {required: true})} />
                                        </span>
                                            <span>Other</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="inline-flex">
                                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                                    <input
                                        {...register("is_default")}
                                        type="checkbox"
                                        className="accent-btn-color w-4 h-4 border-none"
                                        checked={enabled}
                                        onClick={() => {
                                            setEnabled(!enabled);
                                        }}
                                    />
                                    <span className="ml-3 text-sm font-normal text-gray-900">
                                    Set this address as default
                                </span>
                                </label>
                            </div>
                        </div>
                        <input hidden value={existingData.id} {...register("address_id", { required: true })} />
                        <div className='flex w-full col-span-2 gap-3 px-3'>
                            <input className='bg-black rounded-full text-white px-3 w-full h-[2.5rem] uppercase mt-6 font-medium' value="Save"
                                   type="submit"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateAddressMobileView
