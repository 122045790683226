import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './SingleBanner.scss';
import { Link } from 'react-router-dom';

function SingleBanner({ bannerData }) {
    return (
        <Container fluid className='SingleBanner'>
            <Container>
                <Row>
                    {bannerData.map((element, index) => {
                        return (
                            <Col className='px-0' key={index}>
                                <Link to={element.redirect_url}>
                                    <img src={element.image} alt='' />
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    )
}

export default SingleBanner
