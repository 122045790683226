import React, {useEffect, useState} from 'react';
import PrescriptionForm from './PrescriptionForm';
import {Container} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {getCartPage, getCartPagePrecription} from "../../redux/cartRedux";
import Status from "./Status";
import MoreDetails from "./MoreDetails";
import './UploadPage.scss'


const UploadPage = () => {

    const dispatch = useDispatch();
    const [togglestate, settogglestate] = useState("upload");

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCartPage())
    }, [])
    useEffect(() => {
        dispatch(getCartPagePrecription())
    }, [])

    const toggleTab = (toggleData) => {
        settogglestate(toggleData)
        console.log("gfdgdf", togglestate);
    }

    return (
        <div>
            <Container className='max-md:px-0'>
                <div className='w-full flex items-center justify-center'>
                    <div className='px-12 w-1/2'>
                        <button className={togglestate === "upload" ?
                            "active block-tabs-cart bg-transparent rounded-0 border-x-0 hover:border-x-0 border-t-0 hover:border-t-0 border-b-4 hover:border-b-4 border-[#41BDDE] text-[#41BDDE] px-4 w-full" :
                            "block-tabs-cart bg-transparent text-[gray] rounded-0 border-x-0 hover:border-x-0 hover:border-t-0 border-t-0 border-b-4 hover:border-b-4 border-transparent hover:border-transparent px-4 w-full"
                        }
                                onClick={() => toggleTab('upload')}>
                            Upload
                        </button>
                    </div>
                    <div className='px-12 w-1/2'>
                        <button className={togglestate === "status" ?
                            "active block-tabs-cart bg-transparent rounded-0 border-x-0 hover:border-x-0 border-t-0 hover:border-t-0 border-b-4 hover:border-b-4 border-[#41BDDE] text-[#41BDDE] px-4 w-full" :
                            "block-tabs-cart bg-transparent text-[gray] rounded-0 border-x-0 hover:border-x-0 hover:border-t-0 border-t-0 border-b-4 hover:border-b-4 border-transparent hover:border-transparent px-4 w-full"
                        }
                                onClick={() => toggleTab('status')}>
                            Status
                        </button>
                    </div>
                </div>
                {
                    togglestate === "upload" ?
                        <PrescriptionForm/> : <Status/>
                }
            </Container>

        </div>
    );
}

export default UploadPage;
