import React, {useEffect, useState} from 'react'
import {cartTabIndexChange, getCartPagePrecription, precriptionItemRemove} from '../../redux/cartRedux';
import ic_payment_method from "../../assets/images/icons/ic_payment_method.svg";
import ic_cart_blue from "../../assets/images/icons/ic_cart_blue.svg";
import ic_cart_white from "../../assets/images/icons/ic_cart_white.png";
import icon_close_bgblack from "../../assets/images/icons/icon_close_bgblack.png";
import {useDispatch, useSelector, useStore} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FaTrashCan} from "react-icons/fa6";
import {Link, useNavigate} from 'react-router-dom';
import {getCartPrecription, precriptionItemRemoveApiCall} from '../../services/apiCalls';
import {toast} from 'react-toastify';
import {BsChevronRight} from "react-icons/bs";
import {setLoginPopupStatusThunk} from '../../redux/login';
import payment_options from "../../assets/images/payment_options.png";

function Precriptioncart() {
    const dispatch = useDispatch();
    const removePrecriptionCart = async (productId, precriptionProductId) => {
        console.log('productidfgfg ', productId)
        console.log('precriptionProductId ', precriptionProductId)
        const paramData = {
            "product_id": productId, "user_prescription_product_id": precriptionProductId
        }
        const removeItem = await precriptionItemRemoveApiCall(paramData)
        console.log("removeItem ", removeItem);
        if (removeItem.data.code === 1) {
            toast.error("Precription Product Removed Successfully", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
            dispatch(getCartPagePrecription())
        }
    }

    const {selectedAddress} = useSelector((state) => state.profile);

    const navigate = useNavigate();
    const goBack = () => {
        navigate('/uploadPage');
    }
    useEffect(() => {
        dispatch(cartTabIndexChange("2"))
    }, [])
    const {
        cartPrecriptionBillingData, cartPrecriptionProductData, cartPrecriptionProductCount
    } = useSelector((state) => state.cart);

    const [show, setShow] = useState(false);
    const store = useStore();

    const proceedAction = (e) => {
        if (cartPrecriptionProductData !== undefined && cartPrecriptionProductData.length !== 0) {
            e.preventDefault();
            if (store.getState().login.isUserLoggedIn === false) {
                dispatch(setLoginPopupStatusThunk(true));
            } else {
                navigate('/checkout', {state: {flag: "2"}});
            }
        } else {
            toast.error("Cart is empty", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    }

    const userLoginCheck = (e, pageName = "") => {
        e.preventDefault();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            navigate(pageName);
        }
    };

    const isTab = window.innerWidth <= 991;

    return (<div>
        {isTab ? (<div className='mob'>
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    <div
                        className="lg:w-4/6 w-full md:pl-0 pl-0 pr-10 md:pr-4 bg-white max-md:pr-0"
                        id="scroll">
                        {(cartPrecriptionProductData !== undefined) && (cartPrecriptionProductData.length > 0) ? <div>
                            {cartPrecriptionProductData.map((product, index) => (
                                <div className='mb-6 last-of-type:mb-0'>
                                    {console.log("product.id ", product.product_id)}
                                    <PrecriptioncartDetails productData={product}
                                                            removeCart={() => {
                                                                removePrecriptionCart(product.product_id, product.user_prescription_product_id)
                                                            }}/>
                                </div>

                            ))}
                        </div> : <div className='py-16 text-center'>
                            <p className='mb-3 text-sm max-md:font-semibold'>Your Cart is Empty</p>
                            <button
                                className='w-full max-w-[500px] text-sm max-md:w-auto max-md:h-[40px]'
                                onClick={goBack}>Upload Prescription
                            </button>
                        </div>}
                    </div>
                    {console.log("cartPrecriptionBillingData ", cartPrecriptionBillingData)}
                    <div
                        className="lg:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px] max-md:rounded-none max-md:py-0">
                        {(cartPrecriptionBillingData !== undefined) ? <div
                            className="flex flex-col px-8 pb-8 justify-between max-md:bg-white max-md:px-0 max-md:pb-0">
                            <div className='pb-[20px] max-md:pb-4'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Subtotal</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartPrecriptionBillingData.items_total}</p>
                                </div>
                                <div className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartPrecriptionBillingData.shipping_charge}</p>
                                </div>
                                <div className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Tax</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartPrecriptionBillingData.vat_amount}</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                    <p className="text-[25px] font-light leading-normal text-gray-800">Total</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED {cartPrecriptionBillingData.total_payable}</p>
                                </div>
                                <div className='pt-[10px] pb-[30px]'>
                                    <img src={ic_payment_method} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                    <button onClick={(e) => proceedAction(e)}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={() => setShow(!show)}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div> : <div
                            className="flex flex-col px-8 pb-8 justify-between max-md:bg-white max-md:px-0 max-md:pb-0">
                            <div className='pb-[20px] max-md:pb-4'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Subtotal</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED 0</p>
                                </div>
                                <div className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED 0</p>
                                </div>
                                <div className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Tax</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED 0</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b max-md:px-4 max-md:py-3">
                                    <p className="text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800 max-md:text-base max-md:font-semibold">AED
                                        0</p>
                                </div>
                                <div
                                    className='flex items-center pb-[6px] justify-between pt-[10px] border-b max-md:px-4 max-md:py-3 border-t-8 border-gray-100'>
                                    <h1 className='text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold'>Delivery
                                        Address</h1>
                                    {selectedAddress.id !== "new" ?
                                        <Link className='max-md:text-sm max-md:font-medium text-btn-color'
                                              to={"/my-address"}>Change</Link> : <Link to="" onClick={(e) => {
                                            userLoginCheck(e, "/add-address")
                                        }}>Add</Link>}
                                </div>
                                {selectedAddress.id !== "new" && <div
                                    className='flex flex-col gap-1 max-md:px-4 max-md:py-3 border-t-1 text-xs font-normal'>
                                    <p className='text-sm'>{selectedAddress.f_name} {selectedAddress.l_name}</p>
                                    <p>{selectedAddress.full_address}</p>
                                    <p>{selectedAddress.city},{selectedAddress.emirate_name}</p>
                                    <p>{selectedAddress.mobile}</p>
                                </div>}
                                <div className='pt-[10px] pb-[30px] border-t-8 border-gray-100'>
                                    <img src={payment_options} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto hidden'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={goBack}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>) : (<div className='pb-12 desktop'>
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    <div
                        className="lg:w-4/6 w-full md:pl-0 pl-0 pr-10 md:pr-4 bg-white max-md:pr-0"
                        id="scroll">
                        <div>
                            {(cartPrecriptionProductData !== undefined) && (cartPrecriptionProductData.length > 0) ?
                                <div>
                                    {cartPrecriptionProductData.map((product, index) => (
                                        <div className='mb-6 last-of-type:mb-0'>
                                            {console.log("product.id ", product.product_id)}
                                            <PrecriptioncartDetails productData={product}
                                                                    removeCart={() => {
                                                                        removePrecriptionCart(product.product_id, product.user_prescription_product_id)
                                                                    }}/>
                                        </div>

                                    ))}
                                </div> : <div className='pt-16 text-center max-md:pt-6'>
                                    <p className='mb-3 text-sm max-md:font-semibold'>Your Cart is Empty</p>
                                    <button
                                        className='w-full max-w-[500px] text-sm max-md:w-auto max-md:h-[40px]'
                                        onClick={goBack}>Upload Prescription
                                    </button>
                                </div>}

                        </div>
                    </div>
                    {console.log("cartPrecriptionBillingData ", cartPrecriptionBillingData)}
                    {(cartPrecriptionBillingData !== undefined) ?
                        <div className="xl:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px]">
                            <div
                                className="flex flex-col  px-8 py-14 pb-8  justify-between">
                                <div className='pb-[20px]'>
                                    <p className="text-[25px] font-medium leading-9 text-gray-800">Order
                                        Summary</p>
                                    <div className="flex items-center justify-between pt-6 font-light">
                                        <p className="text-base leading-none text-gray-800">Subtotal</p>
                                        <p className="text-base leading-none text-gray-800">AED {cartPrecriptionBillingData.items_total}</p>
                                    </div>
                                    <div className="flex items-center justify-between pt-[10px] font-light">
                                        <p className="text-base leading-none text-gray-800">Shipping</p>
                                        <p className="text-base leading-none text-gray-800">AED {cartPrecriptionBillingData.shipping_charge}</p>
                                    </div>
                                    <div className="flex items-center justify-between pt-[10px] font-light">
                                        <p className="text-base leading-none text-gray-800">Tax</p>
                                        <p className="text-base leading-none text-gray-800">AED {cartPrecriptionBillingData.vat_amount}</p>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                        <p className="text-[25px] font-light leading-normal text-gray-800">Total</p>
                                        <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED {cartPrecriptionBillingData.total_payable}</p>
                                    </div>
                                    <div className='pt-[10px] pb-[30px]'>
                                        <img src={ic_payment_method} alt={'payment method'}/>
                                    </div>
                                    <div
                                        className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                        <button onClick={(e) => proceedAction(e)}
                                                className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                            Proceed to checkout
                                            <BsChevronRight/>
                                        </button>
                                        <button onClick={() => setShow(!show)}
                                                className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                            <img src={ic_cart_blue} alt={'cart'}/>
                                            Continue Shopping
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="xl:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px]">
                            <div
                                className="flex flex-col  px-8 py-14 pb-8  justify-between">
                                <div className='pb-[20px]'>
                                    <p className="text-[25px] font-medium leading-9 text-gray-800">Order
                                        Summary</p>
                                    <div className="flex items-center justify-between pt-6 font-light">
                                        <p className="text-base leading-none text-gray-800">Subtotal</p>
                                        <p className="text-base leading-none text-gray-800">AED 0</p>
                                    </div>
                                    <div className="flex items-center justify-between pt-[10px] font-light">
                                        <p className="text-base leading-none text-gray-800">Shipping</p>
                                        <p className="text-base leading-none text-gray-800">AED 0</p>
                                    </div>
                                    <div className="flex items-center justify-between pt-[10px] font-light">
                                        <p className="text-base leading-none text-gray-800">Tax</p>
                                        <p className="text-base leading-none text-gray-800">AED 0</p>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                        <p className="text-[25px] font-light leading-normal text-gray-800">Total</p>
                                        <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED
                                            0</p>
                                    </div>
                                    <div className='pt-[10px] pb-[30px]'>
                                        <img src={ic_payment_method} alt={'payment method'}/>
                                    </div>
                                    <div
                                        className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                        <button onClick={(e) => proceedAction(e)}
                                                className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                            Proceed to checkout
                                            <BsChevronRight/>
                                        </button>
                                        <button onClick={() => setShow(!show)}
                                                className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                            <img src={ic_cart_blue} alt={'cart'}/>
                                            Continue Shopping
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>)}
    </div>)
}


function PrecriptioncartDetails({productData, removeCart}) {

    console.log("productData ", productData)

    return (<div className="md:flex items-center p-3 bg-gray-100 rounded-[30px]">
        <div className="h-full w-full relative">
            <div
                className="rounded-full w-[30px] h-[30px] flex items-center justify-center absolute top-1 right-1 bg-white"> {productData.qty} </div>
            <div className="flex items-center justify-between w-full pt-1">
                <p className="text-[20px] font-light leading-none text-gray-800">{productData.product_name}</p>
            </div>
            <div className="flex items-center justify-start gap-2 pt-4 pr-6 pb-3 mt-auto">
                <p className="text-[16px] font-bold leading-none text-gray-800">AED {productData.payable_amount}</p>
                {(productData.status === 1) &&
                    <p className="text-[16px] font-bold leading-none text-gray-800 line-through">AED {productData.selling_price}</p>}

            </div>
            {console.log("productData.insurance_flag ", productData.insurance_flag)}
            {(productData.status === "1") ? <div>
                {productData.insurance_flag === "1" ? <div>
                    <p className='covered-insurance'>Covered Insurance</p>
                </div> : <div
                    className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 mb-2 text-xs font-medium text-red-700">
                    Co-pay
                </div>}
            </div> : <div>
                <p>Insurance Rejected</p>
            </div>}

            <hr/>
            <div className='flex justify-end items-center pt-2'>
                <button onClick={() => removeCart()}
                        className='h-[36px] px-3 flex justify-center items-center gap-2 bg-black text-white border-white text-[16px]'>
                    Remove <FaTrashCan/>
                </button>
            </div>
        </div>
    </div>)
}


export default Precriptioncart