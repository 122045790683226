import { useDispatch, useSelector, useStore } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import ic_payment_method from "../../assets/images/icons/ic_payment_method.svg";
import ic_cart_blue from "../../assets/images/icons/ic_cart_blue.svg";
import React, { useEffect, useState } from "react";
import CartProduct from '../../Components/Product/CartProduct';
import { addToCart, applyCoupenApi, placeOrderApi, postPayOrderCreationApi, telrOrderCreationApi } from '../../services/apiCalls';
import { cartTabIndexChange, getCartPage, getCartPagePrecription } from '../../redux/cartRedux';
import { toast } from 'react-toastify';
import { BsChevronRight } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import postpay_image from '../../assets/images/icons/postpay.png'
import payment_options from '../../assets/images/payment_options.png'
import { setLoginPopupStatusThunk } from '../../redux/login';
import { getAddressData } from '../../redux/profile';
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { Avatar, Card, CardBody, Chip, Typography } from "@material-tailwind/react";
import { clearPaymentDetails, setPaymentDetails } from '../../Constants/storedValues';
import telrPayment from "../../assets/images/icons/telr-payment.png";
import postPayPayment from "../../assets/images/icons/postpay-payment.png";
import cardPayment from "../../assets/images/icons/card-payment.png";
import cashPayment from "../../assets/images/icons/cash-payment.png";
import freeShipping from "../../assets/images/icons/free-shipping.png";
import PaymentLoader from '../../Components/Common/Loaders/PaymentLoader';

function Cart() {
    const [paymentLoader, setPaymentLoader] = useState(false);
    const { cartpageBilling, shippingCharge } = useSelector((state) => state.cart);
    const { cartpageItems } = useSelector((state) => state.cart);
    const { cartproductCount, deliverySlot, cartId } = useSelector((state) => state.cart);
    const { userAddress } = useSelector((state) => state.cart);
    const { selectedAddress } = useSelector((state) => state.profile);
    const { isUserLoggedIn } = useSelector((state) => state.login);
    const TABLE_HEAD = ["Item", "Description", "Price", "Quantity", "Total"];
    const [selectedPayment, setSelectedPayment] = useState(3);
    const [coupen, setCoupen] = useState("");
    const store = useStore();
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/');
    }
    useEffect(() => {
        dispatch(cartTabIndexChange("1"))
    }, [])
    useEffect(() => {
        if (store.getState().login.isUserLoggedIn === true) {
            dispatch(getCartPage())
            dispatch(getAddressData())
        }
    }, [isUserLoggedIn])
    const proceedAction = (e) => {
        if (cartproductCount !== 0) {
            e.preventDefault();
            if (store.getState().login.isUserLoggedIn === false) {
                dispatch(setLoginPopupStatusThunk(true));
            } else {
                navigate('/checkout', { state: { flag: "1" } });
            }
        } else {
            toast.error("Cart is empty", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    }
    let [qty, setQty] = useState(0);
    const dispatch = useDispatch();

    const onApplyCoupenClick = async () => {
        const data = {
            'coupon_code': coupen, 'flag': "1",
        }
        const applyCoupen = await applyCoupenApi(data);
        if (applyCoupen.data.code === 1) {
            dispatch(getCartPage())
            toast.success("Coupon applied succesfully", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        } else {
            toast.error(applyCoupen.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    }

    const userLoginCheck = (e, pageName = "") => {
        e.preventDefault();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            navigate(pageName);
        }
    };

    const handlePaymentChange = (e) => {
        let intValue = parseInt(e.target.value)
        setSelectedPayment(intValue);

    }

    const selectedPaymentMethod = async () => {
        var tomorrowDate = new Date(+new Date() + 86400000);
        const inputDateString = deliverySlot !== undefined ? deliverySlot.date_show : tomorrowDate
        var inputDate = new Date(inputDateString);

        // Step 2: Format the Date object into the desired output format
        var year = inputDate.getFullYear();
        var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        var day = String(inputDate.getDate()).padStart(2, '0');
        var outputDateString = year + '-' + month + '-' + day;
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            if (selectedAddress.id !== "new") {
                window.dataLayer.push(
                    {
                        'event': 'begin_checkout',
                        'ecommerce': {
                            "currency": "AED",
                            "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                            "value": cartpageBilling.total_payable,
                            "items": cartpageItems,
                        }
                    }
                );
                window.dataLayer.push(
                    {
                        'event': 'add_shipping_info',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling.total_payable,
                            "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                            "shipping_tier": "Ground",
                            "items": cartpageItems,
                        }
                    }
                );
                window.dataLayer.push(
                    {
                        'event': 'add_payment_info',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling.total_payable,
                            "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                            "payment_type": selectedPayment === 1
                                ? "cash"
                                : selectedPayment === 2
                                    ? "card"
                                    : selectedPayment === 3
                                        ? "telr"
                                        : "postpay",
                            "items": cartpageItems,
                        }
                    }
                );
                setPaymentLoader(true)
                const reqData = {
                    "flag": "1",
                    "address_id": selectedAddress.id,
                    "slot_id": deliverySlot !== undefined ? deliverySlot.id : "",
                    "delivery_date": outputDateString,
                    "payment_type": selectedPayment,
                    "doorstep_delivery": "1",
                    "is_web": 1
                }
                const placeOrder = await placeOrderApi(reqData)
                if (placeOrder.data.code === 1) {

                    dispatch(getCartPage())
                    dispatch(getCartPagePrecription())
                    const baseURL = window.location.origin
                    const cancelURL = baseURL + "/cancel"
                    const declineURL = baseURL + "/decline"
                    setPaymentLoader(false)
                    switch (selectedPayment) {
                        case 1:
                            navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                            break;
                        case 2:
                            navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                            break;
                        case 3:
                            const telrRedirectURL = baseURL + "/telrPaymentScreen"
                            const telrData = {
                                "order_id": placeOrder.data.order_id,
                                "amount": cartpageBilling.total_payable,
                                "return_auth_url": telrRedirectURL,
                                "return_can_url": cancelURL,
                                "return_decl_url": declineURL,
                            }
                            const telrOrder = await telrOrderCreationApi(telrData)
                            if (telrOrder.data.code === 1) {
                                const orderRef = {
                                    "orderId": placeOrder.data.order_id,
                                    "paymentCartId": cartId,
                                    "orderRef": telrOrder.data.order_ref
                                }
                                clearPaymentDetails();
                                setPaymentDetails(orderRef);
                                window.location.replace(telrOrder.data.order_url)
                            } else {
                                toast.error(telrOrder.data.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                });
                            }
                            break;
                        case 4:
                            const redirectURL = baseURL + "/postPayPaymentScreen"
                            const data = {
                                "order_id": placeOrder.data.order_id,
                                "amount": cartpageBilling.total_payable,
                                "return_auth_url": redirectURL,
                                "return_can_url": cancelURL,
                                "return_decl_url": declineURL,
                            }
                            const postPayOrder = await postPayOrderCreationApi(data)
                            if (postPayOrder.data.code === 1) {
                                const orderRef = {
                                    "orderId": placeOrder.data.order_id,
                                    "paymentCartId": cartId,
                                    "orderRef": postPayOrder.data.order_ref
                                }
                                clearPaymentDetails();
                                setPaymentDetails(orderRef);
                                window.location.replace(postPayOrder.data.order_url)
                            } else {
                                toast.error(postPayOrder.data.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                });
                            }
                            break;
                        case 5:
                            navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                            break;
                        default:
                            break;
                    }
                } else {
                    setPaymentLoader(false)
                    toast.error(placeOrder.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            } else {
                toast.error("Add Delivery Address", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }

    }


    const isTab = window.innerWidth <= 991;

    return (<div>
        {isTab ? (<div className='mob pb-12'>
            {
                paymentLoader && <PaymentLoader />
            }
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    <div className="lg:w-4/6 md:w-2/3 w-full pr-10 md:pr-6 bg-white max-md:px-4 max-md:pt-4"
                        id="scroll">
                        {cartproductCount !== 0 ? <ul className='inline-block mb-3 max-h-[320px] overflow-x-scroll'>
                            {cartpageItems.map((products, index) => (
                                <CartProduct key={index} product={products} />))}
                        </ul> : <div className='py-16 text-center'>
                            <p className='mb-3 text-sm'>Your cart is empty</p>
                            <button className='w-full max-w-[500px] text-sm' onClick={goBack}>Start
                                Shopping
                            </button>
                        </div>}
                    </div>
                </div>
                <div className='checkout px-3'>
                    {/* <div className='rounded-full p-8 bg-[#ff6436] text-white mb-3 mt-3'>Spend AED250 get AED500 back!!
                    </div> */}
                    {
                        cartproductCount !== 0 &&
                        <div className="w-full p-3 md:pt-4 bg-gray-100 rounded-[30px] mb-3 promo-code">
                            <label className='mb-2 text-lg font-medium'>Got a Promo/Voucher Code?</label>
                            <div className='flex gap-2 justify-between'>
                                <input type='text' placeholder='Enter Promo/Voucher code' value={coupen}
                                    className='rounded-full px-4' onChange={(e) => {
                                        setCoupen(e.target.value)
                                    }} />
                                <button onClick={onApplyCoupenClick} className='bg-btn-color text-black font-light'>
                                    Apply
                                </button>
                            </div>
                        </div>
                    }

                    <div className=' w-full items-center justify-between bg-gray-100 rounded-[15px] mb-3'>
                        <div className='flex justify-between w-full bg-btn-color rounded-full p-2 px-3'>
                            <h1 className='text-lg font-light leading-normal text-white max-md:text-base max-md:font-medium'>Delivery
                                to</h1>
                            {selectedAddress.id !== "new" ?
                                <Link className='max-md:text-sm max-md:font-medium text-white'
                                    to={"/my-address"}>Change</Link> :
                                <Link className='max-md:text-sm max-md:font-medium text-white' to=""
                                    onClick={(e) => {
                                        userLoginCheck(e, "/add-address")
                                    }}>Add</Link>}
                        </div>
                        {selectedAddress.id !== "new" && <div
                            className='flex flex-col gap-1 max-md:px-0 max-md:py-3 border-t-1 text-xs font-normal p-3'>
                            <p><label className='w-full max-w-[60px]'>Name : </label>{selectedAddress.f_name} {selectedAddress.l_name}</p>
                            <p><label className='w-full max-w-[60px]'>Address :</label>{selectedAddress.full_address}</p>
                            <p><label className='w-full max-w-[60px]'>City :</label> {selectedAddress.city},{selectedAddress.emirate_name}</p>
                            <p><label className='w-full max-w-[60px]'>Phone :</label>{selectedAddress.mobile}</p>
                        </div>}
                    </div>

                    {/* <div className="w-full p-3 md:pt-4 bg-gray-100 rounded-[30px] mb-3">
                        <div className='image'>
                            <img src={''} alt={''} />
                        </div>
                        <div className='content'>
                            <label className='mb-2 text-lg font-medium'>Retum Policy</label>
                            <p className='text-sm'>Orders once placed can't br returned or exchanged <a href='#'
                                className='text-btn-color'>learn
                                more</a></p>
                        </div>
                    </div> */}

                    <div className="w-full p-3 md:pt-4 bg-gray-100 rounded-[30px] mb-3">
                        <div className='mb-2 text-lg font-medium text-btn-color text-center'>Order Summary</div>
                        <ul>
                            <li className='flex items-center justify-between mb-2 gap-3'>
                                <span className='text-base'>Order Total</span>
                                <span className='text-base'>AED {cartpageBilling.items_total ? cartpageBilling.items_total : "0.00"}</span>
                            </li>
                            {
                                cartpageBilling.hasOwnProperty("total_offer_discount") &&
                                <li className='flex items-center justify-between mb-2 gap-3'>
                                    <span className='text-base'>Items Discount</span>
                                    <span className='text-base'>-AED {cartpageBilling.total_offer_discount}</span>
                                </li>
                            }
                            {
                                cartpageBilling.hasOwnProperty("total_coupon_discount") &&
                                <li className='flex items-center justify-between mb-2 gap-3'>
                                    <span className='text-base'>Coupon Discount</span>
                                    <span className='text-base'>-AED {cartpageBilling.total_coupon_discount}</span>
                                </li>
                            }
                            {
                                cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 &&
                                <li className='flex items-center justify-between mb-2 gap-3'>
                                    <span className='text-base'>Estimated VAT%</span>
                                    <span className='text-base'>AED {cartpageBilling.vat_amount}</span>
                                </li>
                            }
                            <li className='flex items-center justify-between mb-2 gap-3'>
                                <span className='text-base'>Shipping</span>
                                <span className='text-base'>{cartpageBilling.shipping_charge > 0 ? `AED ${cartpageBilling.shipping_charge}` : "FREE"}</span>
                            </li>
                            <li className='flex items-start justify-between mb-2 gap-3'>
                                <span className='text-base font-semibold'>Total Amount<span className='font-light'>(Inclusive VAT)</span></span>
                                <span className='text-base whitespace-nowrap'>AED {cartpageBilling.total_payable ? cartpageBilling.total_payable : "0.00"}</span>
                            </li>
                        </ul>
                    </div>
                    {
                        cartproductCount !== 0 &&
                        <>
                            <div className="w-full p-3 md:pt-4 bg-gray-100 rounded-[30px] mb-3 select-payment-mode">
                                <div className='mb-2 text-lg font-medium text-btn-color text-center'>Select Payment Mode
                                </div>
                                <div className="flex flex-col gap-y-3">
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            id="Credit-Debit-card"
                                            name="payment-method"
                                            type="radio"
                                            className="inpu-radio"
                                            onChange={(e) => {
                                                handlePaymentChange(e)
                                            }}
                                            value={3}
                                            defaultChecked
                                        />
                                        <label htmlFor="Credit-Debit-card"
                                            className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                            <img className='object-contain' src={telrPayment} alt="telrPayment" height={25} width={25} />
                                            Credit/Debit card
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            id="Postpay"
                                            name="payment-method"
                                            type="radio"
                                            className="inpu-radio"
                                            onChange={(e) => {
                                                handlePaymentChange(e)
                                            }}
                                            value={4}
                                        />
                                        <label htmlFor="Postpay"
                                            className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                            <img className='object-contain' src={postPayPayment} alt="postPayPayment" height={25} width={25} />
                                            Postpay
                                        </label>
                                    </div>
                                    {/* <div className="flex items-center gap-x-3">
                                <input
                                    id="Tabby"
                                    name="payment-method"
                                    type="radio"
                                    className="inpu-radio"
                                    onChange={(e) => {
                                        handlePaymentChange(e)
                                    }}
                                    value={3}
                                />
                                <label htmlFor="Tabby"
                                    className="block text-base font-normal leading-6 text-gray-900">
                                    Tabby
                                </label>
                            </div> */}
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            id="card"
                                            name="payment-method"
                                            type="radio"
                                            className="inpu-radio"
                                            onChange={(e) => {
                                                handlePaymentChange(e)
                                            }}
                                            value={2}
                                        />
                                        <label htmlFor="card"
                                            className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                            <img className='object-contain' src={cardPayment} alt="cardPayment" height={25} width={25} />
                                            Card on Delivery
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            id="cash"
                                            name="payment-method"
                                            type="radio"
                                            className="inpu-radio"
                                            onChange={(e) => {
                                                handlePaymentChange(e)
                                            }}
                                            value={1}
                                        />
                                        <label htmlFor="cash"
                                            className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                            <img className='object-contain' src={cashPayment} alt="cashPayment" height={25} width={25} />
                                            Cash on Delivery
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <button className="rounded-full font-light text-base w-full"
                                    onClick={selectedPaymentMethod}>Pay Now
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>) : (<div className='pb-12 desktop'>
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    <div
                        className="lg:w-4/6 w-full pr-10 md:pr-6 bg-white max-md:pr-0 max-md:px-3"
                        id="scroll">
                        {cartproductCount !== 0 ?
                            <Card className="h-full w-full">
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max table-fixed text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head) => (
                                                    <th
                                                        key={head}
                                                        className="border-none border-blue-gray-100 bg-blue-gray-50/50 p-2 text-center"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal text-lg leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartpageItems.map(
                                                (products, index) => {
                                                    const isLast = index === cartpageItems.length - 1;
                                                    const classes = isLast
                                                        ? "p-2"
                                                        : "p-2 border-none border-blue-gray-50";

                                                    return (
                                                        <CartProduct key={index} product={products} classes={classes} />
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                            : <div className='pt-16 text-center'>
                                <p className='mb-3 text-sm'>Your cart is empty</p>
                                <button className='w-full max-w-[500px] text-sm' onClick={goBack}>Start
                                    Shopping
                                </button>
                            </div>}
                    </div>
                    <div
                        className="xl:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px] max-md:rounded-none max-md:py-0">
                        {(cartproductCount !== 0) ? <div
                            className="flex flex-col px-8 py-8 pb-8 justify-between max-md:bg-white max-md:px-0 text-center">
                            <div className='block text-center text-lg pb-2 border-b'>
                                <img className='inline-block' src={freeShipping} alt='' width={40} height={40} /> <br />
                                <p>FREE SHIPPING <br /> on orders over AED {shippingCharge}</p>
                            </div>
                            <div className='pb-[20px] max-md:pb-4 border-b'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-center pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-xl font-medium leading-none text-gray-800 max-md:text-sm">
                                        Subtotal : </p>
                                    <p className="text-xl font-medium leading-none text-gray-800 max-md:text-sm"> AED {cartpageBilling.items_total}</p>
                                </div>
                                {cartpageBilling.hasOwnProperty("total_offer_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.total_offer_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("total_coupon_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.total_coupon_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.vat_amount}</p>
                                </div>}
                                {/* <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none max-md:text-sm text-green-500">{cartpageBilling.shipping_charge > 0 ? cartpageBilling.shipping_charge : "FREE"}</p>
                                </div> */}
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b max-md:px-4 max-md:py-3 sr-only">
                                    <p className="text-[25px] font-light leading-normal text-gray-800  max-md:text-lg max-md:font-semibold">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800 max-md:text-lg max-md:font-semibold">AED {cartpageBilling.items_total}</p>
                                </div>
                                <div
                                    className='emi text-xl max-md:text-xs max-md:block max-md:w-full max-md:pt-2 sr-only'>
                                    <div className='flex text-base'>
                                        {cartpageBilling.total_payable !== undefined && <>
                                            <div
                                                className='emi flex items-center flex-wrap text-sm font-light max-md:text-xs max-md:block max-md:w-full max-md:pt-2 pt-2'>
                                                <span
                                                    className="font-semibold pr-1 text-base">AED {(parseFloat(cartpageBilling.total_payable.replace(",", "")) / 3).toFixed(2)} </span>
                                                <span> Pay in 3 interest-free payments with</span>
                                                <img alt='postpay' src={postpay_image} />
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                <div className='pt-[10px] pb-[30px]'>
                                    <img src={ic_payment_method} alt={'payment method'} />
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                        className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight />
                                    </button>
                                    <button onClick={goBack}
                                        className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'} />
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div> : <div
                            className="flex flex-col  px-8 py-14 pb-8 justify-between">

                            <div className='pb-[20px]'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800">Order
                                    Summary</p>
                                <div className="flex items-center justify-between pt-6 font-light">
                                    <p className="text-base leading-none text-gray-800">Order
                                        Total</p>
                                    <p className="text-base leading-none text-gray-800">AED 0</p>
                                </div>
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                <div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Shipping</p>
                                    <p className="text-base leading-none text-gray-800">0</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                    <p className="text-[25px] font-light leading-normal text-gray-800">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED
                                        0</p>
                                </div>
                                <div className='pt-[10px] pb-[30px]'>
                                    <img src={ic_payment_method} alt={'payment method'} />
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                        className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight />
                                    </button>
                                    <button onClick={goBack}
                                        className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'} />
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>)}
    </div>)
}

export default Cart