import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import error_image from '../../../assets/images/placeholder.png'

import './CategoryListing.scss';


function CategoryListing({ categoryData, fromCategory = false, handle = "" }) {
    const settings = {
        infinite: false,
        slidesToShow: 8, // Number of items to show at once
        slidesToScroll: 1,
        // variableWidth: true,
        nav: true,
        margin: 15,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                },
            },
        ],
    };


    return (
        <div className='container-fluid'>
            <div className='container'>
                <div className='row cotegory_listing'>
                    <Slider {...settings}>
                        {categoryData.map((element, index) => {
                            return (
                                
                                    <div className="carousel-item pr-2.5" key={index}>
                                        <Link to={handle + "/" + element.handle}>
                                        <img
                                            alt=""
                                            src={element.image}
                                            width="auto"
                                            height="auto"
                                            className="d-inline-block align-end rounded-[30px]"
                                        // onError={() => this.img.src = {error_image}}
                                        /></Link>
                                        {
                                            fromCategory && <p>{element.name}</p>
                                        }
                                    </div>
                            );
                        })}

                    </Slider>

                </div>
            </div>
        </div>
    );
}

export default CategoryListing;

