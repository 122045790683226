import React, { useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

import './NotFound.scss';
import { Container } from 'react-bootstrap';


function NotFoundPage({ product }) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
      <Container fluid>
        <Container>
          <Row>
          <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Home</Link>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default NotFoundPage;

