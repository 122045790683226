import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {getEmiratesData, getPrecriptionStatus} from '../../redux/profile';
import {useDispatch, useSelector} from 'react-redux';
import Container from 'react-bootstrap/Container'
import {PhotoIcon} from '@heroicons/react/24/solid'
import icon_call from '../../assets/images/icons/icon_call.png'
import icon_chat from '../../assets/images/icons/icon_chat.png'
import icon_add_document from '../../assets/images/icons/icon_add_document.png'
import './UploadPage.scss'
import {Link} from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';

function Status() {

    const {PrecriptionStatus} = useSelector((state) => state.profile);

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPrecriptionStatus());
    }, []);

    return (
        <Container
            className='flex bg-[#f8f8f8] p-6 rounded-[30px] mb-12 max-md:rounded-none max-md:flex-wrap max-md:p-4'>
            {
                PrecriptionStatus.length !== undefined ?
                    <ul className='md:w-2/3 max-md:w-full'>
                        {

                            PrecriptionStatus.map((statusData, index) => (

                                    <li className='flex items-center w-full mb-4' key={index}>
                                        <div
                                            className={`group-item  p-3 w-full rounded-xl bg-white relative ${isActive ? 'active' : ''}`}>
                                            <div className='font-bold'>{statusData.request_id}</div>
                                            <div className='font-light text-sm'>Requested
                                                on {statusData.created_date}</div>
                                            <div className='font-light text-base mt-3'>Status: <span
                                                className='font-bold'>{statusData.status}</span>
                                            </div>
                                            {

                                                (statusData.is_insured !== "1") && (statusData.status === "Request Approved") ?
                                                    <div
                                                        className='group-details absolute top-0 right-0 flex justify-between items-center p-3 h-full w-1/2 before:inline-block before:h-full before:w-[1px] before:bg-[#ccc] max-md:before:hidden max-md:justify-end'>
                                                        <Link to={`/requestDetail/${statusData.id}`}>
                                                            <button
                                                                className='md:bg-black border-black max-md:px-0 max-md:bg-transparent max-md:border-0 max-md:absolute max-md:right-2 max-md:top-2'>
                                                                <span className='max-md:sr-only'>More details</span>
                                                                <AiOutlineRight className='text-2xl text-black' />
                                                            </button>
                                                        </Link>
                                                    </div> : <div></div>
                                            }
                                        </div>
                                    </li>
                                )
                            )
                        }
                    </ul> : <div></div>
            }
            <div className='md:w-1/3 flex flex-wrap justify-center items-center h-full px-3 md:pt-20 max-md:w-full'>
                <p className='text-lg text-center mb-4 font-medium pb-8 w-full'>
                    This service is now only <br/> available in Dubai
                </p>
                <p className='font-bold'>Consult a Pharmacist</p>
                <div className='flex w-full justify-center gap-3 flex-col items-center mt-2'>
                    <a className='shadow-custom bg-white h-12 w-full inline-flex items-center justify-start rounded-3xl gap-2 px-3 text-xl max-w-[220px]'>
                        <span className='bg-black p-[6px] rounded-md w-8 flex'>
                            <img src={icon_chat} alt='icon_call'/>
                        </span>
                        Chat
                    </a>
                    <a
                        href='tel:800DOCIB'
                        className='shadow-custom bg-white h-12 w-full inline-flex items-center justify-start rounded-3xl gap-2 px-3 text-xl max-w-[220px]'>
                        <span className='bg-black p-[6px] rounded-md w-8 flex'>
                            <img src={icon_call} alt='icon_call'/>
                        </span>
                        Call
                    </a>
                </div>
            </div>
        </Container>
    )
        ;
}

export default Status;