import React, {useEffect, useRef, useState} from 'react'
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import MultiRangeSlider from "multi-range-slider-react";
import {
    processFilterBrandsThunk, processFilterOffersThunk, processFilterPriceRangeThunk
} from '../../../redux/subCategory';
import {checkValidJson} from '../../../services/commonServices';
import { useHref } from 'react-router-dom';

function MobileProductFilter({openpopup, onChangeselectedFilterOptions, target, selectedFilterValues}) {

    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [togglestate, settogglestate] = useState("sortby");
    const [filterToggle, setfilterToggle] = useState("");
    const [fulfillment, setfulfillment] = useState(false);
    const [brands, setBrands] = useState([]);
    const [minPriceRangeValue, setMinPriceRangeValue] = useState(0);
    const [maxPriceRangeValue, setMaxPriceRangeValue] = useState(3999);
    const [selectFulfilmentValue, setSelectFulfilmentValue] = useState([]);
    const [selectBrandsValue, setSelectBrandsValue] = useState([]);
    const [selectOffersValue, setSelectOffersValue] = useState([]);
    const [selectFlagsValue, setSelectFlagsValue] = useState([]);
    const {brandOptions, minPriceOption, maxPriceOption, offerOptions} = useSelector((state) => state.subCategory);
    const fulfillmentOptions = [{label: 'Speedy', value: 1, checked: false},];

    let selectBrand = useRef([]);
    let selectPriceMin = useRef(0);
    let selectPriceMax = useRef(3999);
    let priceRangeChangeCount = 0;
    let selectFlags = useRef([1]);
    let selectSortFlag = useRef(1);
    let selectBrands = useRef([]);
    let selectOffers = useRef([]);
    let selectFulfilment = useRef([]);

    const fullUrl = useHref();
    const urlSplit = fullUrl.split("/");

    const dispatch = useDispatch();
    const toggleTab = (toggleData) => {
        settogglestate(toggleData)
    }

    const handleSortOrderCheckChange = (e) => {
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        if (currentValueChecked === true) {
            selectSortFlag.current = currentEventValue;
        }
        let sortFlagArray = selectFlagsValue;
        sortOptions.forEach((sortEl, indexer) => {
            let currentKey = sortFlagArray.indexOf(sortEl.value);
            if (currentKey >= 0) {
                sortFlagArray.splice(currentKey, 1);
            }
        });
        sortFlagArray.push(currentEventValue);
        selectFlags.current = sortFlagArray;
        setSelectFlagsValue(current => sortFlagArray);
        passOnSelectedFilterOptopns();
    };

    const handleFulfillmentCheckChange = (e) => {
        selectFulfilment.current = selectFulfilmentValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectFulfilment.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectFulfilment.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectFulfilment.current.splice(currentKey, 1);
        }
        setSelectFulfilmentValue(current => selectFulfilment.current);
        passOnSelectedFilterOptopns();
    };

    const dispatchProductFilterApis = () => {
        let postData = {};
        if (target.type === 'featured_list') {
            postData['flag'] = 1;
            // postData['featured_list_id'] = target.id;
        } else if (target.type === 'sub_categories') {
            postData['flag'] = 2;
            // postData['sub_category_id'] = target.id;
            postData['handle_category'] = findCategoryHandle();
        } else if (target.type === 'collections') {
            postData['flag'] = 3;
            // postData['collection_mapping_id'] = target.id;
        } else if (target.type === 'similar_products') {
            postData['flag'] = 4;
            // postData['product_id'] = target.id;
            if (target.hasOwnProperty('similarFlag')) {
                postData['similar_flag'] = target.similarFlag;
            }
        } else if (target.type === 'brands') {
            postData['flag'] = 5;
            // postData['brand_id'] = target.id;
        }
        dispatch(processFilterBrandsThunk(postData));
        dispatch(processFilterPriceRangeThunk(postData));
        dispatch(processFilterOffersThunk(postData));
        console.log('newFiltersSelected Filter : ', selectedFilterValues);
    }

    const setFilterValues = () => {
        selectFlags.current = [1];
        if (selectedFilterValues.hasOwnProperty('filter_flag')) {
            selectFlags.current = JSON.parse(selectedFilterValues['filter_flag']);
        } else if (selectedFilterValues.hasOwnProperty('flag')) {
            selectFlags.current = JSON.parse(selectedFilterValues['flag']);
        }
        sortOptions.forEach((sortEl, indexer) => {
            let currentKey = selectFlags.current.indexOf(sortEl.value);
            if (currentKey >= 0) {
                selectSortFlag.current = sortEl.value;
            }
        });
        selectFulfilment.current = (selectedFilterValues['speedy'] === 1) ? [1] : [];
        selectBrands.current = (selectedFilterValues.hasOwnProperty('brand_id') && checkValidJson(selectedFilterValues['brand_id']) && Array.isArray(JSON.parse(selectedFilterValues['brand_id']))) ? JSON.parse(selectedFilterValues['brand_id']) : [];
        selectOffers.current = (selectedFilterValues.hasOwnProperty('offer_id') && checkValidJson(selectedFilterValues['offer_id'])) ? JSON.parse(selectedFilterValues['offer_id']) : [];
        selectPriceMin.current = (selectedFilterValues.hasOwnProperty('from_price') && (parseInt(selectedFilterValues['from_price']) >= minPriceOption)) ? parseInt(selectedFilterValues['from_price']) : minPriceOption;
        selectPriceMax.current = (selectedFilterValues.hasOwnProperty('to_price') && (parseInt(selectedFilterValues['to_price']) <= maxPriceOption)) ? parseInt(selectedFilterValues['to_price']) : maxPriceOption;
        setSelectFlagsValue(current => selectFlags.current);
        setSelectBrandsValue(current => selectBrands.current);
        setSelectFulfilmentValue(current => selectFulfilment.current);
        setSelectOffersValue(current => selectOffers.current);
        setMinPriceRangeValue(current => selectPriceMin.current);
        setMaxPriceRangeValue(current => selectPriceMax.current);
    }

    const handleBrandsCheckChange = (e) => {
        selectBrands.current = selectBrandsValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectBrands.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectBrands.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectBrands.current.splice(currentKey, 1);
        }
        setSelectBrandsValue(current => selectBrands.current);
    };

    const filterTab = (toggleData) => {
        console.log(toggleData);
        setfilterToggle(toggleData)
    }

    const handlePriceRangeChangeInput = (e) => {
        setMinPriceRangeValue(current => e.minValue);
        setMaxPriceRangeValue(current => e.maxValue);
        selectPriceMin.current = e.minValue;
        selectPriceMax.current = e.maxValue;
        if (priceRangeChangeCount % 2 === 0) {
            passOnSelectedFilterOptopns();
        }
        priceRangeChangeCount++;
    };

    const sortOptions = [{label: 'Price: Low to High', value: 1, checked: true}, {
        label: 'Price: High to Low',
        value: 2,
        checked: false
    }, {label: 'Most Popular', value: 3, checked: false}, {
        label: 'Discount',
        value: 4,
        checked: false
    }, {label: 'Best Rating', value: 5, checked: false},];

    const handleOffersCheckChange = (e) => {
        console.log("fdsfdssdfds");
        selectOffers.current = selectOffersValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectOffers.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectOffers.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectOffers.current.splice(currentKey, 1);
        }
        setSelectOffersValue(current => selectOffers.current);
    };

    const passOnSelectedFilterOptopns = () => {
        let passsingFilterOptions = {};
        if (target.type === 'featured_list') {
            // passsingFilterOptions['featured_list_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'sub_categories') {
            // passsingFilterOptions['sub_category_id'] = target.id;
            passsingFilterOptions['handle_sub_category'] = target.handle;
            passsingFilterOptions['handle_category'] = findCategoryHandle();
        } else if (target.type === 'collections') {
            // passsingFilterOptions['collection_mapping_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'similar_products') {
            // passsingFilterOptions['product_id'] = target.id;
        } else if (target.type === 'brands') {
            // passsingFilterOptions['brand_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'search') {
            passsingFilterOptions['search'] = target.handle;
        }
        let flagArray = [];
        if (selectSortFlag.current > 0) {
            flagArray.push(selectSortFlag.current);
        }
        passsingFilterOptions['speedy'] = ((selectFulfilment.current.length > 0) && (selectFulfilment.current.indexOf(1) >= 0)) ? 1 : 0;
        if ((minPriceRangeValue >= 0) && (maxPriceRangeValue >= 0)) {
            flagArray.push(7);
            passsingFilterOptions['from_price'] = selectPriceMin.current;
            passsingFilterOptions['to_price'] = selectPriceMax.current;
        }
        if ((target.type !== 'brands') && (selectBrands.current.length > 0)) {
            flagArray.push(6);
            passsingFilterOptions['brand_id'] = JSON.stringify(selectBrands.current);
        }
        if (selectOffers.current.length > 0) {
            flagArray.push(10);
            passsingFilterOptions['offer_id'] = JSON.stringify(selectOffers.current);
        }
        if (target.type === 'similar_products') {
            passsingFilterOptions['filter_flag'] = JSON.stringify(flagArray);
        } else {
            passsingFilterOptions['flag'] = JSON.stringify(flagArray);
        }
        onChangeselectedFilterOptions(passsingFilterOptions);
    };

    const findCategoryHandle = () => {
        if(urlSplit.length > 2){
            return urlSplit[1]
        }else{
            return ""
        }
    }

    useEffect(() => {
        dispatchProductFilterApis()
        setFilterValues()
        setShow(openpopup)
    }, []);

    useEffect(() => {
        setFilterValues()
        return
    }, [minPriceOption,maxPriceOption]);

    const handleApply = (data) => {
        console.log("data ", data);
        setShow(false)
        passOnSelectedFilterOptopns();
    }

    return (<div>
        <Modal className='filter-popup' show={show} fullscreen={fullscreen}
               onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='actions text-center filter-title'>
                    <button className={togglestate === "sortby" ? "active block-tabs-order" : "block-tabs-order"}
                            onClick={() => toggleTab('sortby')}>Sort
                    </button>
                    <button className={togglestate === "filter" ? "active block-tabs-order" : "block-tabs-order"}
                            onClick={() => toggleTab('filter')}>Filter
                    </button>
                </div>
                {togglestate === "sortby" ? <div className='sortby-content'>
                        <div className='flex flex-col max-w-max mx-auto'>
                            {sortOptions.map((option, optionIdx) => (
                                <div key={option.value}
                                     className="inline-flex items-center flex-row-reverse w-auto justify-center mb-2 gap-2">
                                    <input
                                        id={`filter-mobile-sortOrder-${optionIdx}`}
                                        name={`sortOrder`}
                                        defaultValue={option.value}
                                        type="radio"
                                        defaultChecked={(selectFlagsValue.indexOf(parseInt(option.value)) >= 0)}
                                        onChange={(e) => {
                                            handleSortOrderCheckChange(e);
                                        }}
                                        className="h-4 w-4"
                                    />
                                    <label
                                        htmlFor={`filter-mobile-sortOrder-${optionIdx}`}
                                        className="min-w-0 flex-1 font-normal text-sm text-black text-right"
                                    >
                                        {option.label}
                                    </label>
                                </div>))}
                        </div>
                    </div> :
                    <div className='filter-content'>
                        <div className='flex flex-col gap-3 '>
                            <button
                                className={filterToggle === "fulfillment" ? "active block-tabs-order" : "block-tabs-order"}
                                onClick={() => filterTab('fulfillment')}>Fulfillment
                            </button>
                            <button
                                className={filterToggle === "brand" ? "active block-tabs-order" : "block-tabs-order"}
                                onClick={() => filterTab('brand')}>Brand
                            </button>
                            <button
                                className={filterToggle === "price" ? "active block-tabs-order" : "block-tabs-order"}
                                onClick={() => filterTab('price')}>Price
                            </button>
                            <button
                                className={filterToggle === "offer" ? "active block-tabs-order" : "block-tabs-order"}
                                onClick={() => filterTab('offer')}>Offer
                            </button>
                        </div>
                        {filterToggle === "fulfillment" ? <div className='items'>
                                {fulfillmentOptions.map((option, optionIdx) => (
                                    <div key={option.value} className='item flex items-center w-auto justify-start mb-2 gap-2'>
                                        <input
                                            id={`filter-mobile-fulfillment-${optionIdx}`}
                                            name={`fulfillment[]`}
                                            defaultValue={option.value}
                                            type="checkbox"
                                            defaultChecked={(selectFulfilmentValue.indexOf(parseInt(option.value)) >= 0)}
                                            onChange={(e) => {
                                                handleFulfillmentCheckChange(e);
                                            }}
                                            className="h-4 w-4"
                                        />
                                        <label
                                            htmlFor={`filter-mobile-fulfillment-${optionIdx}`}
                                            className="text-black ml-0"
                                        >
                                            {option.label}
                                        </label>
                                    </div>))}
                            </div> :
                            filterToggle === "brand" ? <div className='items'>
                                    {brandOptions.map((brands, brandindex) => (
                                        <div className='item flex items-center w-auto justify-start mb-2 gap-2'
                                             key={brandindex}>
                                            <input
                                                id={`filter-mobile-brand-${brandindex}`}
                                                name={`brand[]`}
                                                defaultValue={brands.value}
                                                type="checkbox"
                                                defaultChecked={(selectBrandsValue.indexOf(parseInt(brands.value)) >= 0)}
                                                onChange={(e) => {
                                                    handleBrandsCheckChange(e);
                                                }}
                                                className="h-4 w-4"
                                            />
                                            <label
                                                htmlFor={`filter-mobile-brand-${brandindex}`}
                                                className="text-black ml-0"
                                            >
                                                {brands.label}
                                            </label>

                                        </div>))}
                                </div> :
                                filterToggle === "price" ? <div className='items'>
                                        <div className="item flex items-center w-auto justify-start mb-2 gap-2 space-y-4 pb-3">
                                            <MultiRangeSlider
                                                className='w-full'
                                                min={minPriceOption}
                                                max={maxPriceOption}
                                                step={5}
                                                ruler={false}
                                                labels={[minPriceRangeValue, maxPriceRangeValue]}
                                                minValue={minPriceRangeValue}
                                                maxValue={maxPriceRangeValue}
                                                onChange={(e) => {
                                                    handlePriceRangeChangeInput(e);
                                                }}
                                            />
                                        </div>
                                    </div> :
                                    filterToggle === "offer" ? <div className='items'>
                                            {offerOptions.map((option, optionIdx) => (
                                                <div key={option.value} className="item flex items-center w-auto justify-start mb-2 gap-2 space-y-4 pb-3">
                                                    <input
                                                        id={`filter-mobile-offers-${optionIdx}`}
                                                        name={`offers[]`}
                                                        defaultValue={option.value}
                                                        type="checkbox"
                                                        defaultChecked={(selectOffersValue.indexOf(parseInt(option.value)) >= 0)}
                                                        onChange={(e) => {
                                                            handleOffersCheckChange(e);
                                                        }}
                                                        className="h-4 w-4"
                                                    />
                                                    <label
                                                        htmlFor={`filter-mobile-offers-${optionIdx}`}
                                                        className="text-black ml-0"
                                                    >
                                                        {option.label}
                                                    </label>
                                                </div>))}
                                        </div> :
                                        <></>
                        }
                    </div>}
                <div className='flex justify-end'>
                    <button className='bg-black border-black mt-3' onClick={handleApply}>Apply</button>
                </div>

            </Modal.Body>
        </Modal>
    </div>)
}

export default MobileProductFilter
