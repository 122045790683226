
export const checkValidJson = (text = '') => {
    if ((text === undefined) || (text == null) || (typeof text !== 'string') || (text.trim() === '')) {
        return false;
    }
    try {
        JSON.parse(text.trim());
        return true;
    } catch (error) {
        return false;
    }
};

export const convertToTitleCaseWords = (text = '') => {
    if ((text === undefined) || (text == null) || (typeof text !== 'string') || (text.trim() === '')) {
        return text;
    }
    const words = text.toLowerCase().split(' ');

    const convertedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return convertedWords.join(' '); 
};
