import React, {useEffect, useState} from 'react'
import {Container} from 'react-bootstrap'
import './Blog.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfileDetailsThunk} from '../../redux/login';
import BlogDetails from './BlogDetails';

function Blog() {
    const dispatch = useDispatch();
    const {blogs} = useSelector((state) => state.login);

    useEffect(() => {
        window.scrollTo(0, 0);
            dispatch(getUserProfileDetailsThunk())
            return
        }, []
    );
    const [blog, SetBlog] = useState(null)
    const getDetails = (blog) => {
        SetBlog(blog)
    }
    return (
        <div>
            <Container>
                
                {blog ? <BlogDetails {...blog} /> :
                    <div className='flex flex-wrap'>
                        <h2 className='pt-4 pb-10 block w-full text-3xl font-thin text-center'>Blogs</h2>
                        <div className='blog-items flex flex-wrap gap-y-12'>
                            {blogs.map((details, index) => {
                                return (
                                    <>
                                        <div className='blog-item w-full flex gap-x-6 max-md:flex-wrap'>
                                            <div className='image w-1/3 max-md:w-full'>
                                                <img alt='image' src={details.image}
                                                     className='rounded-2xl object-cover object-right h-full'/>
                                            </div>
                                            <div className='w-2/3 max-md:w-full'>
                                                <div className='flex gap-2'>
                                                    <div className='update-date'>
                                                        <span>Updated on </span>
                                                        {details.created_date}
                                                        {details.created_time}
                                                    </div>
                                                </div>
                                                <div className='content'
                                                     dangerouslySetInnerHTML={{__html: details.blog_content.slice(0, 500)}}></div>

                                                <button
                                                    className='h-[50px] border-black font-light px-8 rounded-full'
                                                    onClick={() => getDetails(details)}>Read More
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>}

            </Container>
        </div>
    )
}

export default Blog
