import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { serialize } from 'object-to-formdata';
import {
    cancelOrderEndpoint,
    getProductDetailsUrl,
    precriptionItemRemoveEndpoint,
    getCartApi,
    getCartPrecriptionApi,
    basicAuthToken,
    BaseUrl,
    getHome,
    getOrderListData,
    getAddressEndpoint,
    getAddAddressEndpoint,
    getOrderDetailsbyId,
    getHandleDetailsApiUrl,
    getEmiratesEndpoint,
    getCategoryCollectionUrl,
    getAllCategoryProductsUrl,
    getDeleteAddressEndpoint,
    getAllCollectionProductsUrl,
    getAllFeaturedProductsUrl,
    getAllBrandProductsUrl,
    getAllSimilarProductsUrl,
    getAllOfferProductsUrl,
    getAllSearchProductsUrl,
    getAllProductsUrl,
    getBrandsUrl,
    getPriceRangeUrl,
    getOffersUrl,
    getFilterCollectionsUrl,
    getTagsUrl,
    getVariantProductDetailsUrl,
    getProductShareUrl,
    addUserProductReviewUrl,
    getEditAddressEndpoint,
    addToCartUrl,
    wishlistUrl,
    getUserLoginUrl,
    getUserRegisterUrl,
    getUserSendOtpUrl,
    getUserVerifyOtpUrl,
    getUserTokenUpdateUrl,
    getUserProfileUrl,
    getUserGetInterestsUrl,
    getUserUpdateInterestsUrl,
    getUserLogoutUrl,
    getWishlistUrl,
    getPrivacyPolicyUrl,
    getTermsconditionUrl,
    getRefundAndExchangeUrl,
    getFaqUrl,
    getAllCategorysUrl,
    addPrecriptionDataEndpoint,
    getPrecriptionStatusEndpoint,
    basicAuth,
    getPrescriptionDetailsEndpoint,
    getEditProfileUrl,
    getOrderDetailsUrl,
    getInterestsUrl,
    getUpdateInterestsUrl,
    getUserNotificationsUrl,
    getGoogleAutocompleteFullUrl,
    getGooglePlaceDetailsFullUrl,
    getGoogleGeocodeFullUrl,
    applyCoupenUrl,
    writeReviewUrl,
    placeOrderUrl,
    telrOrderCreationUrl,
    telrOrderResultUrl,
    postPayOrderCreationUrl,
    postPayOrderResultUrl,
    updateOrderStatusUrl,
    emailSubScribeUrl,
    getAllReturnReasonUrl,
    getSiteMapUrl,
    createOrderReturnUrl,
    getCMSPagesUrl,
    getAppIconUrl
} from './endpoint';
import { convertToTitleCaseWords } from './commonServices.js';

import {
    setStorageInitiatedValue, getStorageInitiatedValue, clearStorageInitiatedValue,
    setAuthToken, getAuthToken, clearAuthToken,
    setCustomerToken, getCustomerToken, clearCustomerToken,
    setIsLogin, getIsLogin, clearIsLogin,
    setUniqueId, getUniqueId, clearUniqueId,
    setUser, getUser, clearUser,
    setSelectedLocation, geSelectedLocation, clearSelectedLocation, getStorageInitialValue, setStorageInitialValue,
} from './../Constants/storedValues';
import { getUserProfileDetailsThunk, setLoggedInStatusThunk } from '../redux/login';
import { store } from "../redux/store"
if (getStorageInitialValue() === false) {
    setStorageInitialValue(true);
    setAuthToken(basicAuthToken);
    setCustomerToken("");
    setIsLogin(Boolean(0));
    setUniqueId(uuidv4());
    clearUser();
}

export const getHomeData = async () => {
    const response = await processApiCall(getHome(), { is_web: 1 }, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const getAppIconData = async () => {
    const response = await processApiCall(getAppIconUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
    return response;
}

export const getOrderList = async () => {
    const response = await processApiCall(getOrderListData(), {}, {}, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}
export const cancelOrderApiCall = async (paramData) => {
    var formData = serialize(paramData);
    const response = await processApiCall(cancelOrderEndpoint(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}
export const precriptionItemRemoveApiCall = async (paramData) => {
    var data = serialize(paramData)
    const response = await processApiCall(precriptionItemRemoveEndpoint(), {}, data,
        'POST', 'formdata', basicAuthToken, 'basic', getCustomerToken());
    return response;
}

export const addPrecriptionDataApicall = async (data) => {
    const formData = new FormData();
    formData.append('prescription_photo', data.prescription[0])
    formData.append('insurance_front_photo', data.insuranceIdfront[0])
    formData.append('emirates_id_front_photo', data.emirateIdFront[0])
    formData.append('emirates_id_back_photo', data.emirateIdBack[0])
    formData.append('emirate_master_id', data.emirate_master_id)
    formData.append('is_insured', data.is_insured)
    formData.append('notes', data.notes)
    const response = await processApiCall(addPrecriptionDataEndpoint(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const getPrecriptionStatusApicall = async () => {
    return await processApiCall(getPrecriptionStatusEndpoint(), {}, {}, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}

export const getPrescriptionDetailsApicall = async (id) => {
    var form_data = new FormData();
    form_data.append("prescription_id", id)
    return await processApiCall(getPrescriptionDetailsEndpoint(), {}, form_data, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}

export const getOrderViewDetails = async (orderId) => {
    var form_data = new FormData();
    form_data.append("order_id", orderId)
    const response = await processApiCall(getOrderDetailsbyId(), {}, form_data, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}
export const getAddress = async () => {
    return await processApiCall(getAddressEndpoint(), {}, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const addAddress = async (addressData) => {
    const formData = serialize(addressData);
    return await processApiCall(getAddAddressEndpoint(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getEmirates = async () => {
    return await processApiCall(getEmiratesEndpoint(), {}, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getHandleDetailsData = async (handleValue = '') => {
    var formData = new FormData();
    formData.append('handle', handleValue);
    return await processApiCall(getHandleDetailsApiUrl(), {}, formData, 'POST', 'formdata', getAuthToken());
}
export const getCart = async () => {
    const response = await processApiCall(getCartApi(), {}, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}
export const getCartPrecription = async () => {
    const response = await processApiCall(getCartPrecriptionApi(), {}, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const getCategoryCollection = async (handleValue = '', categoryId) => {
    var formData = new FormData();
    formData.append('handle', handleValue);
    // formData.append('category_id', categoryId);
    formData.append('is_web', 1);
    formData.append('is_dynamic', 1);
    return await processApiCall(getCategoryCollectionUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllCategoryProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllCategoryProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllCollectionProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllCollectionProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllFeaturedProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllFeaturedProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllBrandProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllBrandProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllSimilarProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllSimilarProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllOfferProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllOfferProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllSearchProducts = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllSearchProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getProductsSearchResults = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getFilterBrands = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getBrandsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getFilterPriceRange = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getPriceRangeUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getFilterOffers = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getOffersUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getFilterCollections = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getFilterCollectionsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getFilterTags = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getTagsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getProductDetails = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getProductDetailsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getVariantProductDetails = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getVariantProductDetailsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getProductShareUrlDetails = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getProductShareUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const addUserProductReview = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(addUserProductReviewUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}

export const deleteAddress = async (userID) => {

    var form_data = new FormData();
    form_data.append('address_id', userID);
    return await processApiCall(getDeleteAddressEndpoint(), {}, form_data, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const editAddress = async (editAddressFormData) => {
    const editAddressFormDataSerialized = serialize(editAddressFormData);
    return await processApiCall(getEditAddressEndpoint(), {}, editAddressFormDataSerialized, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken());
}

export const addToCart = async (paramData) => {
    var formData = serialize(paramData);
    const response = await processApiCall(addToCartUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const writeReview = async (paramData) => {
    var formData = serialize(paramData);
    const response = await processApiCall(writeReviewUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const toggleWishlist = async (paramData) => {
    var formData = serialize(paramData);
    const response = await processApiCall(wishlistUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
    return response;
}

export const processUserLoginApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserLoginUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const processUserRegistrationApi = async (requestData, customerToken) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserRegisterUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', customerToken);
}
export const processUserSendOtpApi = async (customerToken) => {
    return await processApiCall(getUserSendOtpUrl(), {}, {}, 'GET', 'urlencoded', getAuthToken(), 'basic', customerToken);
}
export const processUserVerifyOtpApi = async (requestData, customerToken) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserVerifyOtpUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', customerToken, getUniqueId());
}
export const processUserTokenUpdateApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserTokenUpdateUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken());
}
export const getUserProfileDetailsApi = async () => {
    return await processApiCall(getUserProfileUrl(), {}, {}, 'GET', 'urlencoded', getAuthToken(), 'basic', getCustomerToken());
}
export const getUserInterestsApi = async () => {
    return await processApiCall(getUserGetInterestsUrl(), {}, {}, 'GET', 'urlencoded', getAuthToken(), 'basic', getCustomerToken());
}
export const updateUserInterestsApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserUpdateInterestsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken());
}
export const processUserLogoutApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getUserLogoutUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken());
}
export const getWishlistProductAPI = async () => {
    return await processApiCall(getWishlistUrl(), {}, {}, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getCMSPagesAPI = async () => {
    return await processApiCall(getCMSPagesUrl(), {}, {}, 'POST', 'formdata', getAuthToken());
}
export const getPrivacyPolicyAPI = async () => {
    return await processApiCall(getPrivacyPolicyUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
}
export const getTermsconditionAPI = async () => {
    return await processApiCall(getTermsconditionUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
}
export const getRefundAndExchangeAPI = async () => {
    return await processApiCall(getRefundAndExchangeUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
}
export const getFaqAPI = async () => {
    return await processApiCall(getFaqUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
}

export const getAboutUsAPI = async () => {
    return await processApiCall(getFaqUrl(), {}, {}, 'GET', 'formdata', getAuthToken());
}
export const getAllProductsApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllProductsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getAllCategoryApi = async () => {
    return await processApiCall(getAllCategorysUrl(), {}, {}, 'GET', 'urlencoded', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const updateProfileData = async (bodyData) => {
    return await processApiCall(getEditProfileUrl(), {}, bodyData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getOrderDetailsApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getOrderDetailsUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const getInterests = async () => {
    return await processApiCall(getInterestsUrl(), {}, {}, 'GET', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const updateInterest = async (interestIDS) => {

    var requestData = {
        "interest_master_id": JSON.stringify(interestIDS)
    };
    return await processApiCall(getUpdateInterestsUrl(), {}, requestData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}

export const getUserNotificationsApi = async () => {

    return await processApiCall(getUserNotificationsUrl(), {}, {}, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const applyCoupenApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(applyCoupenUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}

export const getAutoSuggestionsFromGoogle = async (requestData) => {
    const response = await axios.get(getGoogleAutocompleteFullUrl(), { params: requestData });
    return response;
}
export const getPlaceDetailsFromGoogle = async (requestData) => {
    const response = await axios.get(getGooglePlaceDetailsFullUrl(), { params: requestData });
    return response;
}
export const getGeocodeDetailsFromGoogle = async (requestData) => {
    const response = await axios.get(getGoogleGeocodeFullUrl(), { params: requestData });
    return response;
}
export const placeOrderApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(placeOrderUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const telrOrderCreationApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(telrOrderCreationUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const telrOrderResultApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(telrOrderResultUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const postPayOrderCreationApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(postPayOrderCreationUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const postPayOrderResultApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(postPayOrderResultUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const updateOrderStatusApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(updateOrderStatusUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}
export const emailSubscribeApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(emailSubScribeUrl(), {}, formData, 'POST', 'formdata', getAuthToken());
}
export const getAllReturnReasonApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(getAllReturnReasonUrl(), {}, formData, 'POST', 'formdata', getAuthToken());
}

export const getSiteMap = async () => {
    const formData = new FormData();
    formData.append('xml_data', 0)
    const response = await processApiCall(getSiteMapUrl(), formData, {}, 'GET', 'formdata');
    return response;
}

export const createOrderRetunApi = async (requestData) => {
    var formData = serialize(requestData);
    return await processApiCall(createOrderReturnUrl(), {}, formData, 'POST', 'formdata', getAuthToken(), 'basic', getCustomerToken(), getUniqueId());
}



const availableHttpMethods = ['GET', 'POST', 'PUT', 'DELETE'];
const availableContentTypes = ['json', 'formdata', 'urlencoded'];
const availableTokenTypes = ['basic', 'bearer'];

const axiosClientInstance = axios.create({
    baseURL: BaseUrl,
    timeout: 6000
});

const cleanupApiUrl = (url = '') => {
    let cleanUrl = '';
    if ((url !== undefined) && (url != null) && (url.trim() !== '')) {
        /* cleanUrl = url.trim().replace(' ', '%20'); */
        cleanUrl = url.trim().split(' ').join('%20');
    }
    return cleanUrl;
}

export const processApiCall = async (url = '', queryParams = {}, data = {}, method = 'GET', contentType = 'json', apiToken = '', tokenType = 'basic', customerToken = '', webToken = '') => {
    if ((url === undefined) || (url == null) || (url.trim() === '')) {
        return null;
    }
    if (availableHttpMethods.indexOf(method.trim().toUpperCase()) < 0) {
        return null;
    }
    if (availableContentTypes.indexOf(contentType.trim().toLowerCase()) < 0) {
        return null;
    }
    if (availableTokenTypes.indexOf(tokenType.trim().toLowerCase()) < 0) {
        return null;
    }
    let qpClean = (queryParams !== undefined) ? queryParams : {};
    let dataClean = (data !== undefined) ? data : {};
    const customHttpHeaders = {};
    if ((data !== undefined) && (Object.keys(data).length > 0)) {
        if (contentType === 'json') {
            customHttpHeaders['Content-Type'] = 'application/json';
        } else if (contentType === 'formdata') {
            customHttpHeaders['Content-Type'] = 'multipart/form-data';
        } else if (contentType === 'urlencoded') {
            customHttpHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
        }
    }
    if ((apiToken !== undefined) && (apiToken != null) && (apiToken.trim() !== '')) {
        // customHttpHeaders.Authorization = convertToTitleCaseWords(tokenType.trim()) + ' ' + apiToken.trim();
        customHttpHeaders.Authorization = apiToken.trim();
    }
    if ((customerToken !== undefined) && (customerToken != null) && (customerToken.trim() !== '')) {
        customHttpHeaders['CustomerToken'] = customerToken.trim();
    }
    if ((webToken !== undefined) && (webToken != null) && (webToken.trim() !== '')) {
        customHttpHeaders['WebToken'] = webToken.trim();
    }
    let currentLocationDetails = geSelectedLocation();
    if (Object.keys(currentLocationDetails).length > 0) {
        let displayName = currentLocationDetails['formattedAddress'];
        customHttpHeaders['Latitude'] = currentLocationDetails['latitude'];
        customHttpHeaders['Longitude'] = currentLocationDetails['longitude'];
    }
    const urlClean = cleanupApiUrl(url);
    let returnData = {}
    if (method.trim().toUpperCase() === 'GET') {
        returnData = await axiosClientInstance.get(urlClean, {
            params: qpClean,
            headers: customHttpHeaders
        });
    } else if (method.trim().toUpperCase() === 'POST') {
        returnData = await axiosClientInstance.post(urlClean, dataClean, {
            params: qpClean,
            headers: customHttpHeaders
        });
    } else if (method.trim().toUpperCase() === 'PUT') {
        returnData = await axiosClientInstance.put(urlClean, dataClean, {
            params: qpClean,
            headers: customHttpHeaders
        });
    } else if (method.trim().toUpperCase() === 'DELETE') {
        returnData = await axiosClientInstance.delete(urlClean, dataClean, {
            headers: customHttpHeaders
        });
    }
    if (Object.keys(returnData).length > 0 && returnData.data.code === 3) {
        clearCustomerToken();
        setIsLogin(Boolean(0));
        setUniqueId(uuidv4());
        clearUser();
        store.dispatch(getUserProfileDetailsThunk());
        store.dispatch(setLoggedInStatusThunk());
        window.location.href = "/"
        return {}
        
    }
    return returnData

};
