import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {getPrescriptionDetails} from '../../redux/profile';
import {Container} from 'react-bootstrap';
import {addToCart} from '../../services/apiCalls';
import {toast, ToastContainer} from 'react-toastify';

function PrescriptionMoreDetails() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    let [qty, setQty] = useState(0);
    const {PrecriptionInfo} = useSelector((state) => state.profile);
    const {PrecriptionProductData} = useSelector((state) => state.profile);
    const params = useParams()
    const id = params.id;
    const [currentPresriptionId, setcurrentPresriptionId] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        setcurrentPresriptionId(id)
        dispatch(getPrescriptionDetails(id))
    }, []);

    const addToCartClick = async (e, product) => {
        e.preventDefault();
        console.log("product.product_id ",product.product_id);
        console.log("productData ", product);
        const data = {
            "product_id": product.product_id,
            "qty": product.qty,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "1",
            "flag": "2",
            "user_prescription_product_id": product.id
        }
        const addtoCart = await addToCart(data)
        if(addtoCart.data.code === 1){
            toast.success("Product Add to Cart Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }else{
            toast.error(addtoCart.data.msg,{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            
        }
        if (addtoCart.data.code === 1) {
            setQty(1)

        }
    };

    return (

        <Container className='bg-[#f8f8f8] p-4 rounded-[30px] mb-12 max-md:rounded-none'>
            <div className='flex justify-between'>
                <div className='w-full'>
                    {
                        console.log("PrecriptionInfo ", PrecriptionInfo)
                    }
                    {
                        (PrecriptionInfo !== undefined) ?
                            <div className='w-full'>
                                {
                                    PrecriptionInfo.map((prescriptionDetail, detailIndex) => (
                                            <div className='flex w-full justify-between items-center' key={detailIndex}>
                                                <div className='flex flex-col'>
                                                    <div className='font-bold text-2xl'>{prescriptionDetail.request_id}</div>
                                                    <div className='text-sm'>Requested
                                                        on {prescriptionDetail.created_date}</div>
                                                </div>
                                                <div className='flex'>
                                                    <span className='text-[25px] font-light'>Status : </span>
                                                    <span className='font-bold text-[25px] pl-1'> {prescriptionDetail.status}</span>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div> : <div></div>
                    }
                </div>
            </div>
            {
                console.log("PrecriptionProductData ", PrecriptionProductData)
            }
            {
                PrecriptionProductData.map((productData, productIndex) => (
                        <div key={productIndex}>
                            <div className='bg-white p-6 rounded-xl mt-6 max-md:p-4'>
                                <div className='w-full text-center'>
                                    <ul className='grid grid-cols-4 items-center max-md:flex max-md:flex-wrap max-md:relative'>
                                        <li className='font-bold border-r h-full flex items-center justify-center max-md:w-full max-md:justify-start max-md:text-xs max-md:font-normal'>{productData.product_name}</li>
                                        <li className='border-r h-full flex items-center justify-center max-md:absolute max-md:right-0 max-md:top-0 max-md:inline-flex max-md:h-auto max-md:bg-white max-md:border-0'>{productData.qty}</li>

                                        {
                                            (productData.status === "1") ?
                                                <>
                                                    {
                                                        (productData.status === "1") && (productData.insurance_flag === "1") ?

                                                            <li className='border-r flex items-center justify-center max-md:w-full max-md:border-r-0 max-md:text-xs'>{productData.copay_amount}
                                                                <br/> Covered Insurance</li>
                                                            :
                                                            <li className='border-r flex items-center justify-center max-md:w-full max-md:border-r-0 max-md:text-xs'>{productData.copay_amount} <br/> Co-Pay
                                                            </li>
                                                    }
                                                </> :
                                                <li className='border-r flex items-center justify-center max-md:w-full max-md:border-r-0 max-md:text-xs'>{productData.copay_amount} <br/> Insurance Rejected
                                                </li>

                                        }


                                        <li className='text-end flex items-center justify-end max-md:w-full max-md:justify-start max-md:pt-3 max-md:border-t max-md:border-black max-md:mt-2'>
                                            <button className='bg-black border-black font-normal max-md:px-4 max-md:ml-auto' onClick={(e) => {
                                                addToCartClick(e, productData)
                                            }}>Add To Cart
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                )

            }
            <button className='sr-only' onClick={goBack}>Back</button>
        </Container>

    )
}

export default PrescriptionMoreDetails
