import React, { useEffect } from 'react'
import { getUserNotifications } from '../../../redux/profile';
import { useDispatch, useSelector } from 'react-redux';

function NotificationMobileView() {
  
    const { getUserNotificationsData } = useSelector(state => state.profile);
    const dispatch = useDispatch();
  
    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(getUserNotifications());
    }, []);
    return (
      <div className='notifications-details'>
  
        <h2 className='text-3xl font-medium mb-3'>Notifications</h2>
  
        <div className='items'>
          {getUserNotificationsData.length !=0 ? getUserNotificationsData.map((data, index) => (
  
            <div className='item' key={index}>
              <h2>{data.text}</h2>
              <span>{data.value.noti_text}</span>
              <div>{data.created_date}</div>
            </div>
          )): <div>No Notifications</div>}
        </div>
      </div>
    );
  }

export default NotificationMobileView
