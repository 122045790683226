import React, { useEffect } from 'react'
import ProductListingPage from '../ProductListingPage/ProductListingPage'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllProduct } from '../../redux/product';

function Searchlist() {
  const { state } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);
  return (
    <>
      <ProductListingPage subCategory={{ handle: state, type: 'search' }} />
    </>
  )
}

export default Searchlist
