import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getFaq} from '../../redux/customerservices';
import {Container} from 'react-bootstrap';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './Faq.scss';

function Faq() {
    const dispatch = useDispatch();
    window.scrollTo(0, 0);
    const {Faq} = useSelector((state) => state.customerServices);


    useEffect(() => {
            dispatch(getFaq())
        }, []
    );

    return (
        <Container>
            <div>
                <h2 className='md:pt-8 md:pb-16 text-4xl text-center font-extralight max-md:mb-8'>FAQs</h2>
                {
                    Faq.map((details, index) => {
                        return (
                            <>
                                <div className='faq-accordion-wrapper' key={index}>
                                    {
                                        /*  <div>{details.title}</div>
                                    <div dangerouslySetInnerHTML={{__html: details.description}}/>*/}

                                    <Accordion allowZeroExpanded>
                                        <AccordionItem key={index}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {details.title}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div dangerouslySetInnerHTML={{__html: details.description}}/>
                                                {/*{details.description}*/}
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </>
                        );
                    })
                }

            </div>
        </Container>

    )
}

export default Faq
