import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {updateUserProfile} from '../../redux/login';
import {getUser} from '../../Constants/storedValues';
import {toast} from 'react-toastify';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsGenderFemale, BsGenderMale} from "react-icons/bs";

function EditProfile() {

    const [uploadProfilePicture, setuploadProfilePicture] = useState()
    const [customer, setCustomer] = useState()
    const [customerProfilePic, setcustomerProfilePic] = useState()
    const [dob, setDob] = useState()

    useEffect(() => {
        const user = getUser()
        setCustomer(user)
        setcustomerProfilePic(user.photoUrl)
        setDob(user.dob)
    }, [])

    const convertDateFormat = (inputDate) => {
        if (inputDate !== null && inputDate !== undefined) {
            const [day, month, year] = inputDate.split('-');
            return `${year}-${month}-${day}`;
        }

    };

    const user = getUser()
    const {register, handleSubmit, reset, formState: {errors}} = useForm({
        defaultValues: {
            photo_url: user ? user.photoUrl : null,
            f_name: user ? user.fName : "",
            l_name: user ? user.lName : "",
            email: user ? user.email : "",
            dob: user ? convertDateFormat(user.dob) : "",
            gender: user ? user.gender !== "" && user.gender !== undefined ? user.gender.toLowerCase() : "male" : "male",
        }, mode: "onChange"
    });

    const dispatch = useDispatch();


    function onSubmit(data) {
        const formData = {
            "f_name": data.f_name,
            "l_name": data.l_name,
            "email": data.email,
            "dob": data.dob,
            "photo_url": data.photo_url[0],
            "gender": data.gender,
        }
        dispatch(updateUserProfile(formData));
        toast.success("Profile updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    const uploadProfile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setuploadProfilePicture(e.target.files[0]);
        }
    }

    return (<Container fluid className='mob-editprofile'>
            <Container>
                <Row>
                    <Col>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex justify-center'>
                                <label htmlFor='file-upload-profile-pic'
                                       className='profile w-32 h-32 rounded-full overflow-hidden inline-block mx-auto relative'>
                                    <input
                                        type="file"
                                        id="file-upload-profile-pic"
                                        name="file_upload_profile_pic"
                                        className='opacity-0'
                                        {...register("photo_url")} onChange={(e) => {
                                        uploadProfile(e);
                                    }}/>
                                    <div
                                        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover w-full h-full'>
                                        {uploadProfilePicture ? (<img className='h-full w-full object-cover'
                                                                      src={URL.createObjectURL(uploadProfilePicture)}
                                                                      alt='image'/>) :
                                            <img className='h-full w-full object-cover' src={customerProfilePic}
                                                 alt='image'/>}
                                    </div>
                                </label>
                            </div>
                            <div className='fieldset grid grid-cols-1 gap-3'>
                                <div className='field'>
                                    <label className='sr-only'>FirstName : </label>
                                    <input
                                        type="text"
                                        id="update-firstname"
                                        name="updatefirstname"
                                        placeholder='FirstName'
                                        className='border-b w-full px-0'
                                        {...register("f_name")}
                                    />
                                </div>
                                <div className='field'>
                                    <label className='sr-only'>LastName : </label>
                                    <input
                                        type="text"
                                        id="update-lastname"
                                        name="updatelastname"
                                        placeholder='LastName'
                                        className='border-b px-0 w-full'
                                        {...register("l_name")}
                                    />
                                </div>
                                <div className='field'>
                                    <label className='sr-only'>Email : </label>
                                    <input
                                        type="email"
                                        id="update-email"
                                        name="updateemail"
                                        placeholder='E-mail'
                                        className='border-b px-0 w-full'
                                        {...register("email")}
                                    />
                                </div>
                                <div className='field'>
                                    <label className='sr-only'>Date of birth : </label>
                                    <input
                                        type="date"
                                        id="dob"
                                        name="dob"
                                        placeholder='DOB'
                                        className='border-b w-full h-[40px]'
                                        defaultValue={dob}
                                        {...register("dob")}
                                    />
                                </div>
                                <div className='field'>
                                    <div className='fieldset gender'>
                                        <div className='field'>
                                            <input type="radio" name="gender" id="male" value="male"
                                                   {...register("gender")}
                                            />
                                            <label htmlFor="male">
                                                <BsGenderMale size={14} className='stroke-1'/>
                                                <span>Male</span>
                                            </label>
                                        </div>
                                        <div className='field'>
                                            <input type="radio" name="gender" id="female" value="female"
                                                   {...register("gender")}
                                            />
                                            <label htmlFor="female">
                                                <BsGenderFemale size={14} className='stroke-1'/>
                                                <span>Female</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input
                                className='h-12 bg-[#41BDDE] rounded-full px-4 mt-16 text-base text-white min-w-[160px] w-full font-medium'
                                type="Submit" value={"Update"}/>
                        </form>
                    </Col>
                </Row>
            </Container>
        </Container>)
}

export default EditProfile
