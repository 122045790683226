import React, { useEffect } from 'react'

function TawkToChat() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://embed.tawk.to/6489b8d894cf5d49dc5da74f/1h2svk3ij';
        script.async = true;
        document.head.appendChild(script);
    
        return () => {
          document.head.removeChild(script);
        };
      }, []);
    
      return (
        <div>
          <div id="tawkto-chat-container" />
        </div>
      );
}

export default TawkToChat
