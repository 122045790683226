import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearPaymentDetails, getPaymentDetails } from '../../Constants/storedValues';
import { cancelOrderApiCall, postPayOrderResultApi, updateOrderStatusApi } from '../../services/apiCalls';
import MainLoader from '../Common/Loaders/MainLoader'

function PostpaymentScreen() {
    const navigate = useNavigate();
    const paymentStatus = async () => {
        let orderRef = getPaymentDetails();
        if (Object.keys(orderRef).length > 0) {
            let processedOrderId = orderRef['orderRef'];
            let cartId = orderRef['paymentCartId'];
            let orderId = orderRef['orderId'];
            const data = {
                "order_id": processedOrderId
            }
            const orderStatus = await postPayOrderResultApi(data)
            if (orderStatus.data.code === 1) {
                clearPaymentDetails()
                const data = {
                    "order_id": orderId,
                    "transaction_id": orderStatus.data.transaction_ref,
                    "cart_id": cartId
                }
                updateOrderStatus(data)
            } else {
                cancelOrderStatus(orderId)
            }
        }
    };

    const updateOrderStatus = async (data = {}) => {
        const updateOrder = await updateOrderStatusApi(data)
        if (updateOrder.data.code === 1) {
            navigate('/orderdetails', { state: { orderId: data.order_id } });
        }else{
            toast.error(updateOrder.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    }

    const cancelOrderStatus = async (orderId) => {
        const reqData = {
            "order_id": orderId,
            "flag": "1",
            "from": "payment"
        }
        await cancelOrderApiCall(reqData)
        navigate('/cartPage');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        paymentStatus();
        return
    }, []);
    return (
        <div>
            <MainLoader />
        </div>
    )
}

export default PostpaymentScreen
