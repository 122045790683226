import React, { useState } from 'react'

import apple_store from '../../assets/images/icons/apple_store.png'
import play_store from '../../assets/images/icons/play_store.png'
import icon_call from '../../assets/images/icons/icon_call.png'
import app_logo from '../../assets/images/app_logo.png'
import './Footer.scss';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import fb from '../../assets/images/icons/fb.png'
import ig from '../../assets/images/icons/ig.png'
import yt from '../../assets/images/icons/yt.png'
import tiktok from '../../assets/images/icons/tiktok.png'
import linkedin from '../../assets/images/icons/linkedin.png'
import { emailSubscribeApi } from '../../services/apiCalls'
import { toast } from 'react-toastify';

function Footer() {

    const { allCategoryList } = useSelector((state) => state.category);
    const { cmsPages } = useSelector((state) => state.customerServices);
    const { socialIcons } = useSelector((state) => state.home);

    let [email, setEmail] = useState("");

    const emailSubscribeClick = async () => {
        const data = {
            "email": email
        }
        const subScribed = await emailSubscribeApi(data)
        if (subScribed.data.code === 1) {
            toast.success("Subscribed succesfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setEmail("")
        } else {
            toast.error(subScribed.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <div className="Footer">
            <div className='container-fluid footer_top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6'>
                            <div className='newsletter'>
                                <div className='title'>
                                    <strong>Let’s stay in touch</strong>
                                    <span className='subtitle'>Subscribe For The Latest Discount & Trends</span>
                                </div>
                                <form>
                                    <div className="mb-3 input_field">
                                        <input type="email" placeholder='Enter your email address'
                                            className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" required onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} />
                                    </div>
                                    <button className="btn btn-primary whitespace-nowrap" onClick={emailSubscribeClick}>Sign Up</button>
                                </form>
                                <div className='newsletter_message'>
                                    <span>You can unsubscribe at any time. See our privacy policy for more info on how we use your data.</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6'>
                            <div className='download_app align-right'>
                                <div className='title'>
                                    <strong>Download App</strong>
                                </div>
                                <div className='desc'>For Exclusive Offers</div>
                                <ul>
                                    <li>
                                        <Link target={"_blank"} className='flex px-4 border-1 border-[#01020240] py-2 rounded-full items-center justify-start gap-2'
                                            to="https://apps.apple.com/us/app/docib-pharmacy/id1625636039">
                                            <img
                                                alt=""
                                                src={apple_store}
                                                width="36"
                                                height="auto"
                                                className="d-inline-block align-end"
                                            />
                                            <div className='text-start font-medium'>
                                                <div className='text-xs font-normal'>Download on the </div>
                                                App Store
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target={"_blank"} className='flex px-4 border-1 border-[#01020240] py-2 rounded-full items-center justify-start gap-2'
                                            to="https://play.google.com/store/apps/details?id=com.docibpharmacy.app">
                                            <img
                                                alt=""
                                                src={play_store}
                                                width="36"
                                                height="auto"
                                                className="d-inline-block align-end"
                                            />
                                            <div className='text-start font-medium'>
                                                <div className='text-xs font-normal'>Get it on</div>
                                                Google play
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid footer_middle'>
                <div className='container'>
                    <ul>
                        <li className='footer_item_1 col-md-3'>
                            <ul>
                                <li>
                                    <img
                                        alt=""
                                        src={app_logo}
                                        width="77"
                                        height="auto"
                                        className="d-inline-block align-end"
                                    />


                                </li>
                                <li>Opal Tower - Office# 1703 17th Floor, Marasi Dr Business Bay, Dubai.</li>
                                <li>
                                    <span className='image'>
                                        <img
                                            alt=""
                                            src={icon_call}
                                            width="14"
                                            height="auto"
                                            className="d-inline-block align-end"
                                        />
                                    </span>

                                    <Link to={"tel:80036242"}> <strong>80036242</strong></Link>


                                </li>
                            </ul>
                        </li>
                        <li className='footer_item_2 col-md-3'>
                            <h6 className='pb-2'><b>Customer Services</b></h6>
                            <ul>
                                {/* <li>
                                    <Link to={"/termscondition"}>Terms of Service</Link>
                                </li>
                                <li>
                                    <Link to={"/privacypolicy"}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to={"/refundandexchange"}>Refund & Exchange Policy</Link>
                                </li>
                                <li>
                                    <a href='#'>Contact us</a>
                                </li> */}
                                {
                                    cmsPages.map((pages, index) => (
                                        <li><Link to={pages.handle}>{pages.title}</Link></li>
                                    ))
                                }
                                <li>
                                    <Link to={'/faqs'}>FAQs</Link>
                                </li>
                                <li>
                                    <Link to={'/blogs'}>Our Blogs</Link>
                                </li>
                            </ul>
                        </li>
                        <li className='footer_item_2 col-md-3'>
                            <h6 className='pb-2'><b>Top Product Categories</b></h6>
                            <ul>
                                <li>
                                    <ul>
                                        {allCategoryList.map((element, index) => {
                                            if (index < 5) {
                                                return (
                                                    <li key={index}>
                                                        <Link to={element.handle}>
                                                            <div>{element.name}</div>
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className='footer_item_2 col-md-3'>
                            <h6 className='pb-2'><b>My Account</b></h6>
                            <ul>

                                <li>
                                    <Link to={'/cartPage'}>View Cart</Link>
                                </li>
                                <li>
                                    <Link to={'/myWishlist'}> My Wishlist </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='container-fluid footer_bottom navbar'>
                <div className='container'>
                    <div className='flex justify-center gap-3 w-full'>
                        {
                            socialIcons.map((social, index) => (
                                <Link target={"_blank"} to={social.link} key={index}>
                                    <img className='w-6 h-6' src={social.image} alt="" />
                                </Link>
                            ))
                        }

                        {/* <Link target={"_blank"} to="https://www.instagram.com/docibpharmacy/">
                            <img className='w-6 h-6' src={ig} alt="" />
                        </Link>


                        <Link target={"_blank"} to="https://www.youtube.com/@docibhealthcare7751">
                            <img className='w-6 h-6' src={yt} alt="" />
                        </Link>
                        <Link target={"_blank"} to="https://www.tiktok.com/@docibhealthcare">
                            <img className='w-6 h-6' src={tiktok} alt="" />
                        </Link>
                        <Link target={"_blank"} to="https://www.linkedin.com/company/docib-healthcare-management">
                            <img className='w-6 h-6' src={linkedin} alt="" />
                        </Link> */}

                    </div>
                </div>
            </div>
            <span className=' container justify-center pt-1 flex text-black font-poppins font-light text-xs	 pt-3 pb-3'>© 2024 DOCIB Pharmacy, All rights reserved.</span>
        </div>
    );
}

export default Footer;
