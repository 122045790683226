import React, { useEffect, useState } from 'react'
import CustomerProfile from './Pages/Customer/CustomerProfile'
import MyOrderDetail from './Pages/Customer/MyOrderDetail'
import CartPage from './Pages/OrderFlow/CartPage'
import Checkout from './Pages/OrderFlow/Checkout/Checkout'
import UploadPage from './Pages/UploadPage/UploadPage'
import Faq from './Components/CustomerServices/Faq'
import Blog from './Components/CustomerServices/Blog'
import HandlePage from './Components/HandlePage/HandlePage'
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import { Route, Routes, useLocation } from 'react-router-dom'
import MyOrder from './Pages/Customer/MyOrder'
import Address from './Pages/Customer/Address'
import Notifications from './Pages/Customer/Notifications'
import AddNewAddress from './Pages/Customer/AddNewAddress'
import UpdateAddress from './Pages/Customer/UpdateAddress'
import MyInterests from './Pages/Customer/MyInterests'
import Home from './Components/Home/Home'
import PrescriptionMoreDetails from './Pages/UploadPage/PrescriptionMoreDetails';
import Profile from './Pages/Customer/Profile'
import Wishlist from './Components/Wishlist/Wishlist'
import ViewOrder from './Components/OrderComplete/ViewOrder'
import OrderComplete from './Components/OrderComplete/Ordercomplet'
import TelrPaymentScreen from './Components/OrderComplete/TelrPaymentScreen'
import PostpaymentScreen from './Components/OrderComplete/PostpaymentScreen'
import Searchlist from './Components/Search/Searchlist'
import EditProfile from './Pages/Customer/EditProfile'
import CategoryListingPage from './Components/CategoryListing/CategoryListingPage'
import MyorderMobileview from './Pages/Customer/MobileView/MyorderMobileview'
import MyaddressMobileview from './Pages/Customer/MobileView/MyaddressMobileview'
import MyinterestMobileView from './Pages/Customer/MobileView/MyinterestMobileView'
import NotificationMobileView from './Pages/Customer/MobileView/NotificationMobileView'
import AddNewAddressMobileView from './Pages/Customer/MobileView/AddNewAddressMobileView'
import UpdateAddressMobileView from './Pages/Customer/MobileView/UpdateAddressMobileView'
import TawkToChat from './Components/Common/Chat/TawkToChat'
import SearchHitResult from './Components/Header/SearchHitResult'
import algoliasearch from 'algoliasearch/lite';
import { Highlight, Hits, InstantSearch, useInstantSearch } from 'react-instantsearch';
import { useHits } from 'react-instantsearch'
import { algoliaAppId, algoliaSearchApiKey, algoliaSearchMainIndex } from "./Constants/constant";

function App() {
  const client = algoliasearch(algoliaAppId, algoliaSearchApiKey);
  const indexName = algoliaSearchMainIndex;
  const location = useLocation();
  const [paramContain, setParamContain] = useState(false);
  useEffect(() => {
    if (location.search.toLowerCase().includes("q=")) {
      setParamContain(true)
    } else {
      setParamContain(false)
    }
  }, [location]);

  return (
    <>
      <InstantSearch indexName={indexName} searchClient={client}>
        <Header />
        {
          paramContain ? <SearchHitResult /> :
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/myProfile/" element={<CustomerProfile />} >
                <Route path="" element={<Profile />} />

                <Route path="my-orders" element={<MyOrder />} />
                <Route path="my-address" element={<Address />} />
                <Route path="my-interests" element={<MyInterests />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="add-address" element={<AddNewAddress />} />
                <Route path="edit-address" element={<UpdateAddress />} />
                <Route path="order/view/:id" element={<MyOrderDetail />} />
              </Route>
              <Route path="ordersStatus" element={<MyorderMobileview />} />
              <Route path='my-address' element={<MyaddressMobileview />} />
              <Route path='my-interests' element={<MyinterestMobileView />} />
              <Route path='notifications' element={<NotificationMobileView />} />
              <Route path='editprofile' element={<EditProfile />} />
              <Route path='add-address' element={<AddNewAddressMobileView />} />
              <Route path='edit-address' element={<UpdateAddressMobileView />} />

              <Route path="/order/view/:id" element={<MyOrderDetail />} />
              <Route path="/cartPage" element={<CartPage />} />
              <Route path="/requestDetail/:id" element={<PrescriptionMoreDetails />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/uploadPage" element={<UploadPage />} />
              <Route path='/myWishlist' element={<Wishlist />} />
              <Route path='/faqs' element={<Faq />} />
              <Route path='/blogs' element={<Blog />} />
              <Route path='/orderdetails' element={<OrderComplete />} />
              <Route path='/vieworder' element={<ViewOrder />} />
              <Route path='/telrPaymentScreen' element={<TelrPaymentScreen />} />
              <Route path='/postPayPaymentScreen' element={<PostpaymentScreen />} />
              <Route path='/search' element={<Searchlist />} />
              <Route path='/categoryList' element={<CategoryListingPage />} />

              <Route path="*" element={<HandlePage />} />
            </Routes>
        }
        <TawkToChat />
        <Footer />
      </InstantSearch>
    </>
  )
}

export default App

