import React from 'react'
import {Spinner} from "react-activity";
import "react-activity/dist/library.css";
import './MainLoader.scss';

function MainLoader() {
    return (
        <>
            {/*<Spinner size={50}/>*/}
            <div className='loading-wrapper'>
                <div className="loading">
                    <div className="loader"></div>
                </div>
            </div>
        </>
    )
}

export default MainLoader
