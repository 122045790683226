import React, { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { Button, Container, Modal } from "react-bootstrap";
import { getAddressData, getEmiratesData, setAddressData } from '../../../redux/profile';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getCartPage, getCartPagePrecription } from '../../../redux/cartRedux';
import {
    addAddress,
    applyCoupenApi,
    placeOrderApi,
    postPayOrderCreationApi,
    telrOrderCreationApi
} from '../../../services/apiCalls';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { clearPaymentDetails, getUser, setPaymentDetails } from '../../../Constants/storedValues';
import { toast, ToastContainer } from 'react-toastify';
import { BiChevronLeft } from 'react-icons/bi';
import { Radio } from "@material-tailwind/react";
import { Avatar, Card, CardBody, Chip, Typography } from "@material-tailwind/react";
import CartProduct from '../../../Components/Product/CartProduct';
import orderReturn from "../../../assets/images/icons/order-return.png";
import telrPayment from "../../../assets/images/icons/telr-payment.png";
import postPayPayment from "../../../assets/images/icons/postpay-payment.png";
import cardPayment from "../../../assets/images/icons/card-payment.png";
import cashPayment from "../../../assets/images/icons/cash-payment.png";
import PaymentLoader from '../../../Components/Common/Loaders/PaymentLoader';

function Checkout() {
    const user = getUser();
    const TABLE_HEAD = ["Item", "Description", "Price", "Quantity", "Total"];
    const { cartpageBilling } = useSelector((state) => state.cart);
    const { cartpageItems, deliverySlot, cartId } = useSelector((state) => state.cart);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            mobile: user.mobile,
        },
        mode: "onChange"
    });
    const [enabled, setEnabled] = useState(false);
    const [editable, setEditable] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [coupen, setCoupen] = useState("");
    const dispatch = useDispatch();
    const { addressList } = useSelector((state) => state.profile);
    const { getEmiratesList } = useSelector(state => state.profile);
    const [selectedPayment, setSelectedPayment] = useState(3);
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAddressData())
        dispatch(getEmiratesData())
        dispatch(getCartPage())
        return
    }, []);

    useEffect(() => {
        if (addressList.length > 1) {
            setSelectedAddress(addressList[0])
            setEditable(false)
            setShowForm(false)
        } else {
            setSelectedAddress({});
            setEditable(true)
            setShowForm(true)
        }
    }, [addressList]);


    const addressChange = (id) => {
        if (id !== "new") {
            addressList.map((address) => {
                if (address.id === parseInt(id)) {
                    setEditable(false)
                    setSelectedAddress(address);
                    setShowForm(false)
                }
            })
        } else {
            setEditable(true)
            setSelectedAddress({});
        }

    }

    const onSubmit = async (data) => {
        try {
            const formattedData = { ...data };
            if (data.is_default === true) {
                formattedData.is_default = 1;
            } else if (data.is_default === false) {
                formattedData.is_default = 0;

            }
            const addAddressData = await addAddress(formattedData);
            if (addAddressData.data.code === 1) {
                toast.success("Address added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                dispatch(getAddressData())
                setEditable(false)
                setShowForm(false)
            } else {
                toast.error(addAddressData.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    };

    const onApplyCoupenClick = async () => {
        const data = {
            'coupon_code': coupen,
            'flag': "1",
        }
        const applyCoupen = await applyCoupenApi(data);
        if (applyCoupen.data.code === 1) {
            dispatch(getCartPage())
            toast.success("Coupon applied succesfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            toast.error(applyCoupen.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const selectedPaymentMethod = async () => {
        var tomorrowDate = new Date(+new Date() + 86400000);
        const inputDateString = deliverySlot !== undefined ? deliverySlot.date_show : tomorrowDate
        var inputDate = new Date(inputDateString);

        // Step 2: Format the Date object into the desired output format
        var year = inputDate.getFullYear();
        var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        var day = String(inputDate.getDate()).padStart(2, '0');
        var outputDateString = year + '-' + month + '-' + day;
        if (selectedAddress.id !== "new") {
            window.dataLayer.push(
                {
                    'event': 'begin_checkout',
                    'ecommerce': {
                        "currency": "AED",
                        "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                        "value": cartpageBilling.total_payable,
                        "items": cartpageItems,
                    }
                }
            );
            window.dataLayer.push(
                {
                    'event': 'add_shipping_info',
                    'ecommerce': {
                        "currency": "AED",
                        "value": cartpageBilling.total_payable,
                        "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                        "shipping_tier": "Ground",
                        "items": cartpageItems,
                    }
                }
            );
            window.dataLayer.push(
                {
                    'event': 'add_payment_info',
                    'ecommerce': {
                        "currency": "AED",
                        "value": cartpageBilling.total_payable,
                        "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                        "payment_type": selectedPayment === 1
                            ? "cash"
                            : selectedPayment === 2
                                ? "card"
                                : selectedPayment === 3
                                    ? "telr"
                                    : "postpay",
                        "items": cartpageItems,
                    }
                }
            );
            setPaymentLoader(true)
            const reqData = {
                "flag": state.flag,
                "address_id": selectedAddress.id,
                "slot_id": deliverySlot !== undefined ? deliverySlot.id : "",
                "delivery_date": outputDateString,
                "payment_type": selectedPayment,
                "doorstep_delivery": "1",
                "is_web": 1
            }
            const placeOrder = await placeOrderApi(reqData)
            if (placeOrder.data.code === 1) {

                dispatch(getCartPage())
                dispatch(getCartPagePrecription())
                const baseURL = window.location.origin
                const cancelURL = baseURL + "/cancel"
                const declineURL = baseURL + "/decline"
                setPaymentLoader(false)
                switch (selectedPayment) {
                    case 1:
                        navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                        break;
                    case 2:
                        navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                        break;
                    case 3:
                        console.log("telr api call");
                        const telrRedirectURL = baseURL + "/telrPaymentScreen"
                        const telrData = {
                            "order_id": placeOrder.data.order_id,
                            "amount": cartpageBilling.total_payable,
                            "return_auth_url": telrRedirectURL,
                            "return_can_url": cancelURL,
                            "return_decl_url": declineURL,
                        }
                        const telrOrder = await telrOrderCreationApi(telrData)
                        if (telrOrder.data.code === 1) {
                            const orderRef = {
                                "orderId": placeOrder.data.order_id,
                                "paymentCartId": cartId,
                                "orderRef": telrOrder.data.order_ref
                            }
                            clearPaymentDetails();
                            setPaymentDetails(orderRef);
                            window.location.replace(telrOrder.data.order_url)
                        } else {
                            toast.error(telrOrder.data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        break;
                    case 4:
                        const redirectURL = baseURL + "/postPayPaymentScreen"
                        const data = {
                            "order_id": placeOrder.data.order_id,
                            "amount": cartpageBilling.total_payable,
                            "return_auth_url": redirectURL,
                            "return_can_url": cancelURL,
                            "return_decl_url": declineURL,
                        }
                        const postPayOrder = await postPayOrderCreationApi(data)
                        if (postPayOrder.data.code === 1) {
                            const orderRef = {
                                "orderId": placeOrder.data.order_id,
                                "paymentCartId": cartId,
                                "orderRef": postPayOrder.data.order_ref
                            }
                            clearPaymentDetails();
                            setPaymentDetails(orderRef);
                            window.location.replace(postPayOrder.data.order_url)
                        } else {
                            toast.error(postPayOrder.data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        break;
                    case 5:
                        navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                        break;
                    default:
                        break;
                }
            } else {
                setPaymentLoader(false)
                toast.error(placeOrder.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        } else {
            toast.error("Add Delivery Address", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }

    }

    const handlePaymentChange = (e) => {
        let intValue = parseInt(e.target.value)
        setSelectedPayment(intValue);

    }

    return (
        <>
            {
                paymentLoader && <PaymentLoader />
            }
            <Container>
                <div className='flex pb-6'>
                    <div className='md:w-7/12'>
                        <div className="isolate bg-white pr-5">
                            {selectedAddress.id !== "new" &&
                                <div className=' w-full items-center justify-betwee rounded-[15px] mb-3'>
                                    <div className='flex justify-between w-full bg-btn-color rounded-full p-2 px-3'>
                                        <h1 className='text-lg font-light leading-normal text-white max-md:text-base max-md:font-medium'>Delivery
                                            to</h1>
                                        <p className='max-md:text-sm max-md:font-medium text-white' onClick={() => setShowForm(!showForm)}>Change</p>
                                    </div>
                                    <div
                                        className='flex flex-col gap-1 max-md:px-0 max-md:py-3 border-t-1 text-xs font-normal p-3'>
                                        <p className='text-sm'><label className='w-full max-w-[60px]'>Name
                                            : </label>{selectedAddress.f_name} {selectedAddress.l_name}</p>
                                        <p><label className='w-full max-w-[60px]'>Address
                                            :</label>{selectedAddress.full_address}</p>
                                        <p><label className='w-full max-w-[60px]'>City
                                            :</label> {selectedAddress.city},{selectedAddress.emirate_name}</p>
                                        <p><label className='w-full max-w-[60px]'>Phone :</label>{selectedAddress.mobile}
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                showForm &&
                                <div>
                                    <div className="flex justify-between max-w-full text-center">
                                        <p className="inline-block text-[20px] font-light leading-8 text-black">
                                            Shipping Address
                                        </p>
                                    </div>

                                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                        <div className='sm:col-span-2'>
                                            <div className="relative my-2">
                                                <select
                                                    id="country"
                                                    name="country"
                                                    autoComplete="country-name"
                                                    // defaultValue={"select address"}
                                                    placeholder='Select Address'
                                                    className='appearance-none block w-full h-12 rounded-full border-0 px-7 text-gray-900 sm:text-sm sm:leading-6 bg-[#F8F8F8]'
                                                    onChange={(e) => {
                                                        addressChange(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        addressList.map((address, index) => {
                                                            if (address.id !== "new") {
                                                                return (
                                                                    <option key={index}
                                                                        value={address.id}>{address.f_name} {address.l_name}</option>
                                                                );
                                                            } else {
                                                                return (
                                                                    <option value={address.id}><span
                                                                        className='text-2xl'>+</span> Add New Address</option>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        editable ?
                                            <>
                                                <form className="mx-auto max-w-full mt-[.625rem]"
                                                    onSubmit={handleSubmit(onSubmit)}>
                                                    <div
                                                        className="relative flex items-center justify-between overflow-hidden py-6">
                                                        <div className='flex gap-3'>
                                                            <div className='flex gap-2 cursor-pointer'>
                                                                <input defaultChecked type="radio"
                                                                    className="peer/office text-black"
                                                                    id='home'
                                                                    value={1}  {...register("address_type", { required: true })} />
                                                                <label htmlFor="home"
                                                                    className="peer-checked/home:text-black cursor-pointer">Home</label>
                                                            </div>
                                                            <div className='flex gap-2 cursor-pointer'>
                                                                <input type="radio" id='office'
                                                                    value={2}  {...register("address_type", { required: true })} />
                                                                <label htmlFor="office"
                                                                    className="peer-checked/office:text-black cursor-pointer">Office</label>
                                                            </div>
                                                            <div className='flex gap-2 cursor-pointer'>
                                                                <input type="radio" className="peer/office text-black"
                                                                    id='other'
                                                                    value={3}  {...register("address_type", { required: true })} />
                                                                <label htmlFor="other"
                                                                    className="peer-checked/other:text-black cursor-pointer">Other</label>
                                                            </div>
                                                        </div>
                                                        <div className="flex py-1">
                                                            <label
                                                                className="inline-flex relative items-center mr-5 cursor-pointer">
                                                                <input
                                                                    {...register("is_default")}
                                                                    type="checkbox"
                                                                    className="sr-only peer"
                                                                    checked={enabled}
                                                                    readOnly
                                                                />
                                                                <div
                                                                    onClick={() => {
                                                                        setEnabled(!enabled);
                                                                    }}
                                                                    className="w-11 h-6 outline outline-1 outline-black border-black rounded-full  peer-focus:ring-black-300  peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-black after:border-black after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-400"
                                                                ></div>
                                                                <span className="ml-2 text-sm font-medium text-gray-900">
                                                                    Set as default
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='grid gap-[.625rem] grid-cols-2'>
                                                        <div className="relative">
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                id="fname"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.f_name ? 'error' : ''}`}
                                                                placeholder={`First Name ${errors.f_name ? 'Required' : ''}`}
                                                                {...register("f_name", { required: true })}
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <input
                                                                type="text"
                                                                name="lname"
                                                                id="lname"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.l_name ? 'error' : ''}`}
                                                                placeholder={`Last Name ${errors.l_name ? 'Required' : ''}`}
                                                                {...register("l_name", { required: true })}
                                                            />
                                                        </div>
                                                        <div className="relative col-span-2">
                                                            <input
                                                                type="text"
                                                                name="buildingname"
                                                                id="buildingname"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.building_name ? 'error' : ''}`}
                                                                placeholder={`Address ${errors.building_name ? 'Required' : ''}`}
                                                                {...register("building_name", { required: true })}
                                                            />
                                                        </div>
                                                        <div className="relative col-span-2">
                                                            <input
                                                                type="text"
                                                                name="fulladdress"
                                                                id="fulladdress"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.full_address ? 'error' : ''}`}
                                                                placeholder={`Apartment ${errors.full_address ? 'Required' : ''}`}
                                                                {...register("full_address", { required: true })}
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <input
                                                                type="text"
                                                                name="city"
                                                                id="city"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.city ? 'error' : ''}`}
                                                                placeholder={`City ${errors.city ? 'Required' : ''}`}
                                                                {...register("city", { required: true })}
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <select
                                                                id="country"
                                                                name="country"
                                                                autoComplete="country-name"
                                                                className="appearance-none block w-full h-12 rounded-full border-0 px-7 text-gray-900 sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                                {...register("emirate_master_id")}
                                                            >
                                                                <option value="-1">Select Emirate</option>
                                                                {
                                                                    getEmiratesList.map((emirates, index) => {
                                                                        return (
                                                                            <option key={index}
                                                                                value={emirates.id}>{emirates.name}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="relative col-span-2 flex gap-2">
                                                            <div
                                                                className="pointer-events-none flex items-center h-12 rounded-full border-0 px-7 text-black font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]">
                                                                <span className="text-black sm:text-sm">+971</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="mobile"
                                                                id="mobile"
                                                                className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.mobile ? 'error' : ''}`}
                                                                placeholder={`Contact ${errors.mobile ? 'Required' : ''}`}
                                                                {...register("mobile", { required: true })}
                                                            />
                                                        </div>
                                                        <div className='flex col-span-2 gap-4 mt-3'>
                                                            <div className='w-1/3'>
                                                                <button
                                                                    className="rounded-full w-full font-light text-base bg-transparent text-black flex items-center justify-center gap-x-2 px-3"
                                                                    onClick={goBack}>
                                                                    <BiChevronLeft className='text-[#41BDDE] text-3xl' />
                                                                    Return to cart
                                                                </button>
                                                            </div>
                                                            <div className='w-2/3'>
                                                                <input type="submit"
                                                                    className="rounded-full font-light text-base w-full bg-[#41BDDE] h-[50px] text-white"
                                                                    value={"Save Address"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </> :
                                            <>
                                                <div className='grid gap-[.625rem] grid-cols-2'>
                                                    <p className='hidden'>{selectedAddress.f_name !== undefined ? selectedAddress.f_name : ""}</p>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={false}
                                                            enabled={true}
                                                            value={selectedAddress.f_name !== undefined ? selectedAddress.f_name : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="First Name"
                                                        />
                                                    </div>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.l_name !== undefined ? selectedAddress.l_name : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="Last Name"
                                                        />
                                                    </div>
                                                    <div className="relative col-span-2">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.building_name !== undefined ? selectedAddress.building_name : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="Address"
                                                        />
                                                    </div>
                                                    <div className="relative col-span-2">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.full_address !== undefined ? selectedAddress.full_address : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="Apartment"
                                                        />
                                                    </div>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.city !== undefined ? selectedAddress.city : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="City"
                                                        />
                                                    </div>
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.emirate_name !== undefined ? selectedAddress.emirate_name : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="Emirates"
                                                        />
                                                    </div>
                                                    <div className="relative col-span-2 flex gap-2">
                                                        <div
                                                            className="pointer-events-none flex items-center h-12 rounded-full border-0 px-7 text-black font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]">
                                                            <span className="text-black sm:text-sm">+971</span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            disabled={editable ? true : false}
                                                            value={selectedAddress.mobile !== undefined ? selectedAddress.mobile : ""}
                                                            className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                            placeholder="Contact"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            }

                            <Card className="h-full w-full">
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head) => (
                                                    <th
                                                        key={head}
                                                        className="border-none border-blue-gray-100 bg-blue-gray-50/50 p-2 text-center"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartpageItems.map(
                                                (products, index) => {
                                                    const isLast = index === cartpageItems.length - 1;
                                                    const classes = isLast
                                                        ? "p-2"
                                                        : "p-2 border-none border-blue-gray-50";

                                                    return (
                                                        <CartProduct key={index} product={products} classes={classes} />
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className='md:w-5/12'>
                        <div className="w-full md:p-6 md:pt-4 bg-gray-100 rounded-[30px] mb-3">
                            <label className='mb-2 text-lg font-medium'>Got a Promo/Voucher Code?</label>
                            <div className='flex gap-2 justify-between'>
                                <input type='text' placeholder='Enter Promo/Voucher code'
                                    className='rounded-full px-4'
                                    defaultValue={coupen}
                                    onChange={(e) => {
                                        setCoupen(e.target.value)
                                    }}
                                />
                                <button onClick={onApplyCoupenClick} className='bg-btn-color text-black font-light'>
                                    Apply
                                </button>
                            </div>
                        </div>

                        <div className="w-full md:p-4 md:pt-2 bg-gray-100 rounded-[15px] mb-3 flex gap-2">
                            <div className='image'>
                                <img src={orderReturn} alt={"orderReturn"} height={40} width={40} />
                            </div>
                            <div className='content'>
                                <label className='mb-2 text-base font-medium'>Return Policy</label>
                                <p className='text-xs'>Orders once placed can't be returned or exchanged <Link to='/refund-and-exchange-policy'
                                    className='text-btn-color'>learn
                                    more</Link></p>
                            </div>
                        </div>

                        <div className="w-full md:p-6 md:pt-4 bg-gray-100 rounded-[30px] mb-3">
                            <div className='mb-2 text-lg font-medium text-btn-color text-center'>Order Summary</div>
                            <li className='flex items-center justify-between mb-2'>
                                <span className='text-base'>Order Total</span>
                                <span className='text-base'>AED {cartpageBilling.items_total}</span>
                            </li>
                            {
                                cartpageBilling.hasOwnProperty("total_offer_discount") &&
                                <li className='flex items-center justify-between mb-2'>
                                    <span className='text-base'>Items Discount</span>
                                    <span className='text-base'>-AED {cartpageBilling.total_offer_discount}</span>
                                </li>
                            }
                            {
                                cartpageBilling.hasOwnProperty("total_coupon_discount") &&
                                <li className='flex items-center justify-between mb-2'>
                                    <span className='text-base'>Coupon Discount</span>
                                    <span className='text-base'>-AED {cartpageBilling.total_coupon_discount}</span>
                                </li>
                            }
                            {
                                cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 &&
                                <li className='flex items-center justify-between mb-2'>
                                    <span className='text-base'>Estimated VAT%</span>
                                    <span className='text-base'>AED {cartpageBilling.vat_amount}</span>
                                </li>
                            }
                            <li className='flex items-center justify-between mb-2'>
                                <span className='text-base'>Shipping</span>
                                <span className='text-base'>{cartpageBilling.shipping_charge > 0 ? `AED ${cartpageBilling.shipping_charge}` : "FREE"}</span>
                            </li>
                            <li className='flex items-center justify-between mb-2'>
                                <span className='text-base font-semibold'>Total Amount<span className='font-light'>(Inclusive VAT)</span></span>
                                <span className='text-base'>AED {cartpageBilling.total_payable}</span>
                            </li>
                        </div>

                        <div className="w-full md:p-6 md:pt-4 bg-gray-100 rounded-[30px] select-payment-mode">
                            <div className='mb-2 text-lg font-medium text-btn-color text-center'>Select Payment Mode
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="Credit-Debit-card"
                                        name="payment-method"
                                        type="radio"
                                        className="inpu-radio"
                                        onChange={(e) => {
                                            handlePaymentChange(e)
                                        }}
                                        defaultChecked
                                        value={3}
                                    />
                                    <label htmlFor="Credit-Debit-card"
                                        className="flex text-base font-normal leading-6 text-gray-900 gap-2">
                                        <img className='object-contain' src={telrPayment} alt="telrPayment" height={30} width={30} />
                                        Credit/Debit card
                                    </label>
                                </div>

                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="Postpay"
                                        name="payment-method"
                                        type="radio"
                                        className="inpu-radio"
                                        onChange={(e) => {
                                            handlePaymentChange(e)
                                        }}
                                        value={4}
                                    />
                                    <label htmlFor="Postpay"
                                        className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                        <img className='object-contain' src={postPayPayment} alt="postPayPayment" height={30} width={30} />
                                        Postpay
                                    </label>
                                </div>
                                {/* <div className="flex items-center gap-x-3">
                                    <input
                                        id="Tabby"
                                        name="payment-method"
                                        type="radio"
                                        className="inpu-radio"
                                        onChange={(e) => {
                                            handlePaymentChange(e)
                                        }}
                                        value={3}
                                    />
                                    <label htmlFor="Tabby"
                                        className="block text-base font-normal leading-6 text-gray-900">
                                        Tabby
                                    </label>
                                </div> */}
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="card"
                                        name="payment-method"
                                        type="radio"
                                        className="inpu-radio"
                                        onChange={(e) => {
                                            handlePaymentChange(e)
                                        }}
                                        value={2}
                                    />
                                    <label htmlFor="card"
                                        className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                        <img className='object-contain' src={cardPayment} alt="cardPayment" height={30} width={30} />
                                        Card On Delivery
                                    </label>
                                </div>
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="cash"
                                        name="payment-method"
                                        type="radio"
                                        className="inpu-radio"
                                        onChange={(e) => {
                                            handlePaymentChange(e)
                                        }}
                                        value={1}
                                    />
                                    <label htmlFor="cash"
                                        className="flex gap-2 text-base font-normal leading-6 text-gray-900">
                                        <img className='object-contain' src={cashPayment} alt="cashPayment" height={30} width={30} />
                                        Cash On Delivery
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:p-6 md:pt-4 rounded-[30px]">
                            <button className="rounded-full font-light text-base w-full"
                                onClick={selectedPaymentMethod}>Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </>)
}

export default Checkout
