import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {BsGenderFemale, BsGenderMale} from 'react-icons/bs';
import {setUserBMIStatus, setUserBMI, geUserBMI, clearUserBMI} from "./../../../Constants/storedValues";
import icon_retry from '../../../assets/images/icons/icon_retry.png'
import icon_share from '../../../assets/images/icons/icon_share.png'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './BMI.scss';

function BMI(props) {
    const [gender, setGender] = useState("");
    const [fullscreen, setFullscreen] = useState(true);
    const [showBMIModal, setShowBMIModal] = useState(true);
    const [formData, setFormData] = useState({height: 10.0, weight: 1.0, age: 1});
    const [myBMI, setMyBMI] = useState(null);
    const [status, setStatus] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    const handleCloseDeleteModal = () => {
        setShowBMIModal(false);
        props.onDataReceived(false);
    };


    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === 'gender') {
            setGender(value);
        }
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const calculateProgress = (bmi) => {
        if (bmi < 18.5) {
            return "bmi-25";
        } else if (bmi >= 18.5 && bmi <= 24.9) {
            return "bmi-50";
        } else if (bmi >= 25.0 && bmi <= 29.9) {
            return "bmi-75";
        } else {
            return "bmi-100";
        }
    };


    const handleStatus = (BMI) => {

        var StatusValue = '';

        if (BMI < 18.5) {

            setStatus("Under Weight");
            StatusValue = 'Under Weight'
        } else if (BMI >= 18.5 && BMI <= 24.9) {

            setStatus("Normal");
            StatusValue = 'Normal'

        } else if (BMI >= 25.0 && BMI <= 29.9) {

            setStatus("Over Weight")
            StatusValue = 'Over Weight'

        } else if (BMI >= 30.0) {

            setStatus("Obese")
            StatusValue = 'Obese'

        }
        return StatusValue;


    }


    const handleBMISubmit = (event) => {
        var tempStatusData = ''
        event.preventDefault();


        const heightInCM = formData.height;
        const weightInKg = formData.weight;
        const heightInMeter = heightInCM / 100;
        const BMI = (weightInKg / (heightInMeter * heightInMeter)).toFixed(1);


        setMyBMI(BMI);
        tempStatusData = handleStatus(BMI);


        handleStoreBMI(tempStatusData, BMI);


    };

    const handleReload = () => {
        setMyBMI(null);
    }

    const handleShare = () => {
        var tempStatusData = ''
        const options = {
            day: "numeric",
            month: "short",
            year: "numeric",
        };

        const date = new Date();
        const formattedDate = date.toLocaleDateString("en-US", options);

        const shareContent = "BMI is " + myBMI + " this falls under the " + handleStatus(myBMI) + " category as on " + formattedDate;


        handleCopyClick(shareContent);
    }

    const handleCopyClick = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
            setIsCopied(true);
            toast.success("BMI Copied", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } catch (err) {

        }
    };
    const handleStoreBMI = (tempStatusData, BMI) => {
        setUserBMIStatus(tempStatusData);
        setUserBMI(BMI);

    }


    return (
        <div>

            <Modal
                className="bmi-popup"
                show={showBMIModal}
                fullscreen={fullscreen}
                onHide={handleCloseDeleteModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>BMI Check</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>

                        {!myBMI ? <div>
                            <h2>BMI Check</h2>

                            <form>


                                <div className='gender'>
                                    <div className='field'>
                                        <input checked={gender === 'Male'} type="radio" id="male" name="gender"
                                               value="Male"
                                               onChange={handleChange}/>
                                        <label htmlFor="male">
                                            <BsGenderMale size={14} className='stroke-1'/>
                                            <span>Male</span>
                                        </label>
                                    </div>
                                    <div className='field'>
                                        <input checked={gender === 'Female'} type="radio" id="female" value="Female"
                                               name="gender"
                                               onChange={handleChange}/>
                                        <label htmlFor="female">
                                            <BsGenderFemale size={14} className='stroke-1'/>
                                            <span>Female</span>
                                        </label>
                                    </div>
                                </div>
                                <div className='fieldset'>
                                    <div className='field'>
                                        <label htmlFor="height">Height</label>
                                        <span>{formData.height} CM</span>
                                        <input required={true} type="range" id="height" name="height" min="10" max="250"
                                               step="0.1" value={formData.height} onChange={handleChange}/>
                                    </div>
                                    <div className='field'>
                                        <label htmlFor="weight">Weight</label>
                                        <span>{formData.weight} KG</span>
                                        <input required={true} type="range" id="weight" name="weight" min="1.0"
                                               max="250.0"
                                               step="0.1" value={formData.weight} onChange={handleChange}/>
                                    </div>
                                    <div className='field'>

                                        <label htmlFor="age">Age</label>
                                        <span>{formData.age}</span>
                                        <input required={true} type="range" id="age" name="age" min="1" max="120"
                                               value={formData.age} onChange={handleChange}/>
                                    </div>
                                </div>

                                <button className='btn' type="submit" onClick={handleBMISubmit}>Calculate</button>
                            </form>
                        </div> : <div className='bmi-result'>

                            <h2>Your Result</h2>

                            <div className='bmi-result-details'>
                                <span
                                    className='text-2xl font-medium text-[#41BDDE] block text-center pb-8'>Your BMI Is</span>
                                <div className='block text-center pb-10'>
                                    <div className='text-5xl font-bold text-black '>{myBMI}</div>
                                    <div className='font-bold'>kg/m&sup2;</div>
                                </div>
                                <div className='block text-center bmi-status'>
                                    <div className={'bmi-progress' + calculateProgress(myBMI)}></div>
                                    <p className='font-bold'>Your weight is</p>
                                    <p className='status'>
                                        <span className={status}>{status} </span>
                                    </p>
                                </div>

                            </div>
                            <div className='flex justify-center pt-6'>
                                <button
                                    className='bg-transparent border-0 h-auto p-0'
                                    onClick={handleShare}>
                                    <img
                                        src={icon_share}
                                        alt='icon_share'
                                        width={100}
                                    />
                                    <span className='sr-only'>Share</span>
                                </button>
                                <button
                                    className='bg-transparent border-0 h-auto p-0'
                                    onClick={handleReload}>
                                    <img
                                        src={icon_retry}
                                        alt='icon_retry'
                                        width={100}
                                    />
                                    <span className='sr-only'>Reload</span>
                                </button>
                            </div>
                        </div>

                        }

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BMI;
