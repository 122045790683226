import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./home"
import profileReducer from './profile'
import handleSlugReducer from "./handleSlug";
import cartReducer from "./cartRedux"
import categoryReducer from "./category";
import subCategoryReducer from "./subCategory";
import productReducer from "./product";
import loginReducer from './login';
import wishlistReducer from './Wishlist';
import customerServiceReducer from './customerservices';
import orderdetailsReducer from './order';
import locationReducer from './location';


export const store = configureStore({
    reducer: {
        home: homeReducer,
        profile: profileReducer,
        handleSlug: handleSlugReducer,
        cart: cartReducer,
        category: categoryReducer,
        subCategory: subCategoryReducer,
        product: productReducer,
        login: loginReducer,
        wishlist: wishlistReducer,
        customerServices: customerServiceReducer,
        order: orderdetailsReducer,
        location: locationReducer,
    }
})