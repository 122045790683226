import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchBox } from 'react-instantsearch';

function CustomSearchBox() {
    const navigate = useNavigate();
    const location = useLocation();
    const { query, refine } = useSearchBox();
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef(null);


    function setQuery(newQuery) {
        setInputValue(newQuery);

        refine(newQuery);
        if (newQuery.toString().length !== 0) {
            navigate(`?q=${newQuery.toString()}`);
        } else {
            navigate(location.pathname);
        }
    }

    return (
        <form
            className='d-flex search-wrapper'
            action=""
            role="search"
            noValidate
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }}
            onReset={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setQuery('');

                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }}
        >
            <div className='search-input visible w-full'>
                <input
                    className='form-control me-2'
                    ref={inputRef}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Search for products"
                    spellCheck={false}
                    maxLength={512}
                    type="search"
                    value={inputValue}
                    onChange={(event) => {
                        setQuery(event.currentTarget.value);
                    }}
                    autoFocus
                />
            </div>
        </form>
    );
}


export default CustomSearchBox