import CategoryListing from '../Common/CategoryListing/CategoryListing';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {getHome} from '../../redux/home';

import './Home.scss';
import React, {useEffect} from 'react';
import BannerSlider from '../Common/BannerSlider/BannerSlider';
import UserEngagements from '../Common/UserEngagements/UserEngagements';
import SingleBanner from '../Common/SingleBanner/SingleBanner';
import DualBanner from '../Common/DualBanner/DualBanner';
import TopOffers from '../Common/TopOffers/TopOffers';
import FeaturedListPromotionalBanner from '../Common/FeaturedListPromotionalBanner/FeaturedListPromotionalBanner';

import '../../index.scss'
import FeaturedList from '../Common/FeaturedList/FeaturedList';
import MainLoader from '../Common/Loaders/MainLoader';
import PharmacyDeals from '../Common/PharmacyDeals/PharmacyDeals';
import {Container} from "react-bootstrap";
import icon_arrow_down from "../Header/images/icon_arrow_down.png";
import {setLocationPopupStatusThunk} from "../../redux/location";
import {getUser} from '../../Constants/storedValues';
import {Link} from "react-router-dom";
import Searchicon from "../Header/images/ic_search.png";
import Brands from '../Common/Brands/Brands';


function Home() {
    const dispatch = useDispatch();
    const {dashboardList, loader} = useSelector((state) => state.home);
    const {cartproductCount, cartpageBilling} = useSelector((state) => state.cart);
    const user = getUser();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getHome())
        window.dataLayer.push(
            {
                'event': 'mpCustomerSession',
                'cart': cartproductCount !== undefined && cartproductCount !== 0 ? {
                    "currencyCode": "AED",
                    "itemCount": cartproductCount !== undefined ? cartproductCount : 0,
                    "vat": cartpageBilling.vat_amount !== undefined ? cartpageBilling.vat_amount : 0,
                    "total": cartpageBilling.total_payable !== undefined ? cartpageBilling.total_payable : 0,
                    "coupenDiscount": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                } : {"hasItem": false},
                "customer": user != null
                    ? {
                        "isLoggedIn": true,
                        "firstName": user.fName,
                        "LastName": user.lName,
                        "dob": user.dob,
                        "gender": user.gender
                    }
                    : {"isLoggedIn": false}
            }
        );
    }, []);
    return (
        <>
            {
                loader ? <MainLoader size={50}/> :
                    <div className='Home flex flex-wrap gap-y-8'>
                        {dashboardList.map((element, index) => {
                            if (element.hasOwnProperty("whats_trending") && element.whats_trending.length > 0) {
                                return (
                                    <BannerSlider sliderData={element.whats_trending} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("category") && element.category.length > 0) {
                                return (
                                    <CategoryListing categoryData={element.category} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("user_engagement") && element.user_engagement.length > 0) {
                                return (
                                    <UserEngagements userEngagementData={element.user_engagement} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("online_pharmacy_deals") && element.online_pharmacy_deals.length > 0) {
                                return (
                                    <PharmacyDeals deals={element.online_pharmacy_deals[0]} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("promotional_banner") && element.promotional_banner.length > 0) {
                                return (
                                    <SingleBanner bannerData={element.promotional_banner} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("featured_list_promotional_banner") && element.featured_list_promotional_banner.length > 0) {
                                return (
                                    <FeaturedListPromotionalBanner data={element.featured_list_promotional_banner}
                                                                   key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("featured_list") && element.featured_list.length > 0) {
                                return (
                                    <FeaturedList featuredListData={element.featured_list} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("collections_group") && element.collections_group.length > 0) {
                                return (
                                    <TopOffers collectionData={element.collections_group} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("brands") && element.brands.length > 0) {
                                return (
                                    <Brands collectionData={element.brands} key={index}/>
                                );
                            }
                            if (element.hasOwnProperty("dual_banners") && element.dual_banners.length > 0) {
                                if (element.dual_banners.length > 1) {
                                    return (
                                        <DualBanner dualBannerData={element.dual_banners} key={index}/>
                                    );
                                }
                                if (element.dual_banners.length == 1) {
                                    return (
                                        <SingleBanner bannerData={element.dual_banners} key={index}/>
                                    );
                                } else {
                                    return
                                }
                            }
                        })}
                        {/* <PharmacyForYou /> */}
                    </div>
            }
        </>
    );
}


export default Home;
