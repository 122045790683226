import React from 'react'
import {
    Highlight,
    Hits,
    InstantSearch,
    Pagination,
    RefinementList,
    SortBy,
    Stats,
    useHits,
    useInstantSearch
} from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import {Link} from 'react-router-dom';
import Product from '../Product/Product';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductFilter from "../ProductListingPage/ProductFilter/ProductFilter";
import MobileProductFilter from "../ProductListingPage/ProductFilter/MobileProductFilter";
import {Spinner} from "react-activity";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/20/solid";
import MultiRangeSlider from "multi-range-slider-react";
import { algoliaSearchMainIndex } from "./../../Constants/constant";

function SearchHitResult() {
    const {hits} = useHits();
    const isTab = window.innerWidth <= 991;
    return (
        <Container fluid>
            <Container>
                <Row>
                    <Col xs={12} className='flex items-center justify-between py-3 max-md:px-0'>
                        <Stats className='text-center font-medium text-base max-md:text-xs uppercase'/>
                        <div className='flex justify-between'>
                            <SortBy
                                items={[
                                    {value: algoliaSearchMainIndex + '_price_desc', label: 'Highest Price'},
                                    {value: algoliaSearchMainIndex + '_price_asc', label: 'Lowest Price'},
                                    {value: algoliaSearchMainIndex + '_discount_desc', label: 'Discount'},
                                ]}/>
                        </div>
                    </Col>
                    <Col
                        xs={12}
                        md={3}
                        lg={3}
                        xxl={2}
                        className="filter_main_wrapper max-md:px-0"
                    >
                        <div className='sidebar'>
                            {/*<h1>Categories</h1>*/}
                            {/*<RefinementList attribute='categories'/>*/}
                            {/*<h1>Brand</h1>*/}
                            {/*<RefinementList attribute='brand'/>*/}

                            <form className="mt-0 max-md:mb-6">
                                <Disclosure as="div"
                                            className="border-t first-of-type:border-t-0 border-gray-200 block"
                                            defaultOpen={isTab ? false : true}>
                                    {({open}) => (
                                        <>
                                            <h3 className="flow-root">
                                                <Disclosure.Button
                                                    className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                                    <span className="font-medium text-black">Categories</span>
                                                    <span className="ml-6 flex items-center">
                                    {open ? (
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true"/>
                                    ) : (
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true"/>
                                    )}
                                </span>
                                                </Disclosure.Button>
                                            </h3>

                                            <Disclosure.Panel className="pt-0">
                                                <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                                    <RefinementList attribute='categories'/>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure as="div"
                                            className="border-t first-of-type:border-t-0 border-gray-200 block"
                                            defaultOpen={isTab ? false : true}>
                                    {({open}) => (
                                        <>
                                            <h3 className="flow-root">
                                                <Disclosure.Button
                                                    className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                                    <span className="font-medium text-black">Brand</span>
                                                    <span className="ml-6 flex items-center">
                                    {open ? (
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true"/>
                                    ) : (
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true"/>
                                    )}
                                </span>
                                                </Disclosure.Button>
                                            </h3>

                                            <Disclosure.Panel className="pt-0">
                                                <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                                    <RefinementList attribute='brand'/>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </form>
                        </div>
                    </Col>
                    <Col
                        xs={12}
                        md={9}
                        lg={9}
                        xxl={10}
                        className="product_grid lg:pl-12 max-md:px-0"
                    >
                        <Row className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 mx-0 gap-y-4">
                            {hits.map((product, index) => {
                                return (
                                    <Col xs={12} className='px-0'>
                                        <Product productData={product}/>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='flex justify-center'>
                        <Pagination padding={2} showLast/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default SearchHitResult
