import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAppIconData, getHomeData, getSiteMap } from "../services/apiCalls";
import logo from "../Components/Header/images/logo.svg";

export const getHome = createAsyncThunk("Home/getHomeData", async () => {
    const res = await getHomeData();
    // await getSiteMap();
    return res.data;
})

export const getAppIcon = createAsyncThunk("Home/getAppIcon", async () => {
    const res = await getAppIconData();
    return res.data;
})

const INITIAL_STATE = {
    dashboardList: [],
    masterCategories: [],
    appIcon: logo,
    socialIcons:[],
    announcementNote: [],
    loader: false,
}

const homeSlice = createSlice({
    name: "home",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHome.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getHome.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.dashboardList = action.payload.home_data_list;
                    state.dashboardList.map((element)=>{
                        if(element.hasOwnProperty("category")){
                            state.masterCategories = element.category
                        }
                    })
                }else{
                    state.dashboardList = []
                    state.masterCategories = []
                }
                state.loader = false;
            })
            .addCase(getHome.rejected, (state, action) => {
                state.loader = false;
                console.log("home error api issue : ", action)
            })
            .addCase(getAppIcon.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getAppIcon.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.appIcon = action.payload.result[0].app_icon
                    state.socialIcons = action.payload.social_media_icons
                    state.announcementNote = action.payload.announcement_notes
                }else{
                    state.appIcon = logo;
                    state.socialIcons = [];
                    state.announcementNote = [];
                }
                state.loader = false;
            })
            .addCase(getAppIcon.rejected, (state, action) => {
                state.loader = false;
                console.log("home error api issue : ", action)
            })
    }
})

export default homeSlice.reducer;