import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getCartPage} from '../../redux/cartRedux';
import {addToCart} from '../../services/apiCalls';
import {toast} from 'react-toastify';
import {Spinner} from 'react-activity';
import {Avatar, Card, CardBody, Chip, Typography} from "@material-tailwind/react";

const isTab = window.innerWidth <= 991;

function CartProduct({product, classes}) {
    const dispatch = useDispatch();
    const {cartpageBilling, cartpageItems} = useSelector((state) => state.cart);
    const [addLoader, setAddLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const increamentCartAction = async (cartProduct) => {
        setAddLoader(true)
        const data = {
            "product_id": cartProduct.product_id,
            "qty": 1,
            "mrp": cartProduct.mrp,
            "selling_price": cartProduct.selling_price,
            "action": "1",
            "flag": "1"
        }
        const addtocartData = await addToCart(data)
        if (addtocartData.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage())
        } else {
            setAddLoader(false)
            toast.error(addtocartData.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    };

    const decreamentCartAction = async (cartProduct) => {
        setRemoveLoader(true)
        const data = {
            "product_id": cartProduct.product_id,
            "qty": 1,
            "mrp": cartProduct.mrp,
            "selling_price": cartProduct.selling_price,
            "action": "2",
            "flag": "1"
        }
        const addtocartData = await addToCart(data)
        if (addtocartData.data.code === 1) {
            if (cartProduct.qty === "1") {
                toast.error("Product Removed from Cart", {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000,

                });
                window.dataLayer.push(
                    {
                        'event': 'remove_from_cart',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                            "items": cartpageItems,
                        }
                    }
                );
            }
            setRemoveLoader(false)
            dispatch(getCartPage())
        } else {
            setRemoveLoader(false)
            toast.error(addtocartData.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    };
    return (<>
        {isTab ?
            (
                <li className="mob flex items-center max-md:items-start max-lg:mb-4 last-of-type:mb-0 p-2 bg-gray-100 rounded-[15px]">
                    <div className="w-full max-w-[60px]">
                        {
                            product.product_images.map((image, index) => (
                                <img
                                    src={image.file}
                                    alt className="w-full h-full object-center rounded-[10px] object-cover"
                                    key={index}
                                />
                            ))
                        }
                    </div>
                    <div className="max-lg:px-3">
                        <div className="flex items-center justify-between w-full pt-1">
                            <p className="text-[20px] font-light leading-none text-gray-800 max-md:text-xs">{product.name}</p>
                        </div>
                        <div
                            className="flex items-center justify-start pt-10 pr-6 mt-auto max-md:pt-2 max-md:justify-start gap-x-2">
                            <p className="text-[25px] font-bold leading-none text-gray-800 max-md:text-xs">AED {product.selling_price}</p>
                            {parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                                <p className='cutoff-mrp max-md:text-xs text-xl line-through text-gray-400'>AED {product.mrp}</p>}
                        </div>
                    </div>
                    <div className='flex flex-col-reverse border-none rounded-md max-md:items-center ml-auto gap-1'>
                        <span className='font-light mr-3 sr-only'>Quantity</span>
                        {
                            removeLoader ? <Spinner/> : <button
                                className='rounded-full w-6 h-6 bg-transparent border-black p-0 text-base text-black'
                                onClick={(e) => {
                                    decreamentCartAction(product)
                                }}>-
                            </button>
                        }
                        <p className='inline-flex items-center justify-center w-6 h-6 bg-white rounded-full text-center'>{product.qty}</p>
                        {
                            addLoader ? <Spinner/> : <button
                                className='rounded-full w-6 h-6 bg-transparent border-black p-0 text-base text-black'
                                onClick={(e) => {
                                    increamentCartAction(product)
                                }}>+
                            </button>
                        }
                    </div>
                </li>
            ) :
            (
                // <li className="desktop max-md:hidden flex items-center pt-6 max-md:items-start max-md:pt-0">
                //     <div className="w-1/6">
                //         {
                //             product.product_images.map((image, index) => (
                //                 <img
                //                     src={image.file}
                //                     key={index}
                //                     alt className="w-full h-full object-center rounded-[30px] object-cover"
                //                 />
                //             ))
                //         }
                //     </div>
                //     <div className="md:pl-6 md:w-5/6 h-full">
                //         <div className="flex items-center justify-between w-full pt-1">
                //             <p className="text-[20px] font-light leading-none text-gray-800 max-md:text-xs">{product.name}</p>
                //         </div>
                //         <div
                //             className="flex items-center justify-start pt-10 pr-6 mt-auto max-md:pt-2 max-md:justify-start gap-x-2">
                //             <p className="text-[25px] font-bold leading-none text-gray-800 max-md:text-xs">AED {product.selling_price}</p>
                //             {parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                //                 <p className='cutoff-mrp max-md:text-xs text-xl line-through text-gray-400'>AED {product.mrp}</p>}
                //         </div>
                //     </div>
                //     <div className='flex max-md:flex-col-reverse max-md:items-center'>
                //         <span className='font-light mr-3'>Quantity</span>
                //         {
                //             removeLoader ? <Spinner /> : <button
                //                 className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                //                 onClick={(e) => {
                //                     decreamentCartAction(product)
                //                 }}>-
                //             </button>
                //         }
                //         <p className='inline-flex items-center justify-center w-9 h-7 text-center'>{product.qty}</p>
                //         {
                //             addLoader ? <Spinner /> : <button
                //                 className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                //                 onClick={(e) => {
                //                     increamentCartAction(product)
                //                 }}>+
                //             </button>
                //         }
                //     </div>
                // </li>
                <tr>
                    <td className='p-2 border-none border-blue-gray-50 max-w-[80px] text-center'>
                        <div className="inline-block items-center gap-3 max-w-[80px] text-center">
                            {
                                product.product_images.map((image, index) => (
                                        <img
                                            src={image.file}
                                            alt
                                            className="w-full h-full object-center rounded-[30px] object-cover"
                                            key={index}
                                        />
                                    )
                                )
                            }
                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                >
                                    {product.name}
                                </Typography>
                            </div>
                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex flex-col justify-center text-center">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                            >
                                AED {product.selling_price}
                            </Typography>
                            {
                                parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-50 line-through"
                                >
                                    AED {product.mrp}
                                </Typography>
                            }
                        </div>
                    </td>
                    <td className={classes}>
                        <div className='flex max-md:flex-col-reverse justify-center items-center'>
                            <span className='font-light mr-3 sr-only'>Quantity</span>
                            {
                                removeLoader ? <Spinner/> : <button
                                    className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                                    onClick={(e) => {
                                        decreamentCartAction(product)
                                    }}>-
                                </button>
                            }
                            <p className='inline-flex items-center justify-center w-9 h-7 text-center'>{product.qty}</p>
                            {
                                addLoader ? <Spinner/> : <button
                                    className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                                    onClick={(e) => {
                                        increamentCartAction(product)
                                    }}>+
                                </button>
                            }
                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex flex-col justify-center items-center">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                            >
                                AED {parseFloat(product.selling_price.replace(",", "") * product.qty)}
                            </Typography>
                            {
                                parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-50 line-through"
                                >
                                    AED {parseFloat(product.mrp.replace(",", "") * product.qty)}
                                </Typography>
                            }
                        </div>
                    </td>
                </tr>
            )}
    </>)
}

export default CartProduct