import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {getMyOrder} from '../../../redux/profile';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";

function MyorderMobileview() {
    const navigate = useNavigate();
    const [togglestate, settogglestate] = useState("delivered");
    const dispatch = useDispatch();
    const {activeorders, completeOrders} = useSelector((state) => state.profile);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMyOrder())
    }, []);

    const proceedAction = (e,id) => {
        console.log("view order clicked", id)
        e.preventDefault()
        navigate('/vieworder', {state: {orderId: id}});
    }

    return (<Container fluid>
        <Container>
            <Row>
                <div className='my-order-details'>
                    <div className='ordered-items'>
                        <div className='mb-2'>Active Orders</div>
                        {activeorders.map((data, index) => (<div className='ordered-item' key={index}>
                            <Link className='p-3 border rounded-lg flex justify-between'
                                  to="" onClick={(e) => {
                                proceedAction(e,data.id)
                            }}>
                                <div className=''>
                                    <p className='font-bold'>{data.order_items_count} items</p>
                                    <p className='date text-gray-400 text-sm'>{data.order_date}</p>
                                </div>
                                <div className='font-bold'>AED {data.order_amount}</div>
                            </Link>
                        </div>))}
                        <div className='mb-2'>Complete Orders</div>
                        {completeOrders.map((ordercompleted, indexkey) => (<div className='ordered-item' key={indexkey}>
                            {<Link className='p-3 border rounded-lg flex justify-between'
                                   to="" onClick={(e) => {
                                proceedAction(e,ordercompleted.id)
                            }}>
                                <div className=''>
                                    <p className='font-bold'>{ordercompleted.order_items_count} items</p>
                                    <p className='date text-gray-400 text-sm'>{ordercompleted.order_date}</p>
                                </div>
                                <div className='font-bold'>AED {ordercompleted.order_amount}</div>
                            </Link>}
                        </div>))}
                    </div>
                </div>
            </Row>
        </Container>
    </Container>)
}


export default MyorderMobileview

