export const customerToken = "A11LKkscD6zF6CV0MZTFVQ==";
export const basicAuth = "Basic UExLVC0sOV9kNjNZR1lJYzg3KF41OlBMS1JuNzJeOFlLcVJpcDh2XmEjfA==";
export const basicAuthToken = "UExLVC0sOV9kNjNZR1lJYzg3KF41OlBMS1JuNzJeOFlLcVJpcDh2XmEjfA==";
export const authToken = "+wrfpMy75CKPNbb4dpwIqQ==";

export const googleApiKey = "AIzaSyDq19Qdt6LnCab7253xy927MsD1zsjx5fA";
export const mobileOtpResendSeconds = 30;

export const algoliaAppId = "PE4EY62JRF";
export const algoliaSearchApiKey = "f7fcd4ccc6e4fe9a329e2f465cdcad95";
export const algoliaSearchMainIndex = "docib_algolia_live_products";
