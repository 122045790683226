import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCMSPagesAPI, getFaqAPI } from "../services/apiCalls";


export const getcmspages = createAsyncThunk("cms/getCmspages", async () => {
    const res = await getCMSPagesAPI();
    return res.data;
})
export const getFaq = createAsyncThunk("Faq/getFaq", async () => {
    const res = await getFaqAPI();
    return res.data;
})

const INITIAL_STATE = {
    cmsPages: [],
    Faq :[],
}

const customerservicesSlice = createSlice({
    name: "Privacy",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getcmspages.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getcmspages.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.cmsPages = action.payload.result;

                }
                else {
                    state.cmsPages = []
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getcmspages.rejected, (state, action) => {
                state.loader = false;
                state.cmsPages = []
                console.log("error api issue")
            })
            .addCase(getFaq.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getFaq.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.Faq = action.payload.result;
                    console.log(action.payload.result);
                    
                    
                }
                else{
                    state.Faq = []
                }
                state.loader = false;
                
            })
            .addCase(getFaq.rejected, (state, action) => {
                state.loader = false;
                state.Faq = []
                console.log("error api issue")
            })

    }
})

export default customerservicesSlice.reducer;