const isBrowser = (typeof window !== 'undefined') ? true : false;

const storageType = (type = 'local') => {
    return ((type.toLowerCase() === 'local') || type.toLowerCase() === 'session') ? type.toLowerCase() + 'Storage' : 'localStorage';
};

export const getStoredItem = (key = '', type = 'local') => {
    return (isBrowser && (key != null) && (key !== undefined) && (key.trim() !== '')) ? window[storageType(type)][key] : '';
};

export const setStoredItem = (key = '', value = '', type = 'local') => {
    if (isBrowser && (key != null) && (key !== undefined) && (key.trim() !== '')) {
        window[storageType(type)].setItem(key, value);
        return true;
    }
    return false;
};

export const removeStoredItem = (key = '', type = 'local') => {
    if (isBrowser && (key != null) && (key !== undefined) && (key.trim() !== '')) {
        window[storageType(type)].removeItem(key);
        return true;
    }
};
