import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './DualBanner.scss';
import { Link } from 'react-router-dom';

function DualBanner({ dualBannerData }) {
    return (
        <Container fluid className='duelBanner'>
            <Container>
                <Row className='gap-3'>
                    {dualBannerData.map((element, index) => {
                        return (
                            <Col key={index}>
                                <Link to={element.redirect_url}>
                                    <img src={element.image} alt='' /></Link>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    )
}

export default DualBanner
