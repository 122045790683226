import { faHome, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Nav, NavItem } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import './BottomNavigation.scss';
import home from '../../../assets/images/icons/ic_home_white.png';
import category from '../../../assets/images/icons/ic_category_white.png';
import upload from '../../../assets/images/icons/ic_document_white.png';
import cart from '../../../assets/images/icons/ic_cart_white.png';
import Profile from '../../../assets/images/icons/ic_profile_white.png';
import { useDispatch, useSelector, useStore } from 'react-redux'
import { getCartPage, getCartPagePrecription } from '../../../redux/cartRedux'
import { addToCart, placeOrderApi, postPayOrderCreationApi, telrOrderCreationApi } from '../../../services/apiCalls'
import { clearPaymentDetails, setPaymentDetails } from '../../../Constants/storedValues'
import { toast } from 'react-toastify';
import { setLoginPopupStatusThunk } from '../../../redux/login'
import { Spinner } from 'react-activity'

function BottomNavigation() {
    const [addLoader, setAddLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const store = useStore();
    const {
        cartpageItems,
        cartpageBilling,
        cartproductCount
    } = useSelector((state) => state.cart);
    const { detailsPageView, productDetailsData } = useSelector((state) => state.product);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoginCheck = (e, pageName = "") => {
        e.preventDefault();
        if (pageName === "/myProfile" || pageName === "/uploadPage") {
            if (store.getState().login.isUserLoggedIn === false) {
                dispatch(setLoginPopupStatusThunk(true));
            } else {
                navigate(pageName);
            }
        } else {
            navigate(pageName);
        }
    };

    let [qty, setQty] = useState(0);
    useEffect(() => {
        if (productDetailsData.length !== 0) {
            setQty(parseInt(productDetailsData[0].qty, 10))
        } else {
            setQty(0)
        }
    }, [productDetailsData]);
    const addToCartClick = async (product) => {
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "1",
            "flag": "1"
        }
        const addtoCart = await addToCart(data)
        if (addtoCart.data.code === 1) {
            dispatch(getCartPage());
            setQty(1)
            toast.success("Product Added to Cart", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,

            });
            window.dataLayer.push(
                {
                    'event': 'add_to_cart',
                    'ecommerce': {
                        "currency": "AED",
                        "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                        "items": cartpageItems,
                    }
                }
            );
        } else {
            toast.error(addtoCart.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const increamentCartAction = async (product) => {
        setAddLoader(true)
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "1",
            "flag": "1"
        }
        const qtyIncrement = await addToCart(data)
        qty = qty + 1
        if (qtyIncrement.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage());
            setQty(qty)
        } else {
            setAddLoader(false)
            toast.error(qtyIncrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const decreamentCartAction = async (product) => {
        setRemoveLoader(true)
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "2",
            "flag": "1"
        }
        const qtyDecrement = await addToCart(data)
        qty = qty - 1
        if (qtyDecrement.data.code === 1) {
            setRemoveLoader(false)
            dispatch(getCartPage());
            setQty(qty)
            if (qty === 0) {
                window.dataLayer.push(
                    {
                        'event': 'remove_from_cart',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                            "items": cartpageItems,
                        }
                    }
                );
            }
        } else {
            setRemoveLoader(false)
            toast.error(qtyDecrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const tabs = [{
        route: "/",
        icon: home,
        label: "Home"
    }, {
        route: "/categoryList",
        icon: category,
        label: "category"
    }, {
        route: "/uploadPage",
        icon: upload,
        label: "prescription"
    }, {
        route: "/cartPage",
        icon: cart,
        label: "cart"
    }, {
        route: "/myProfile",
        icon: Profile,
        label: "My profile"
    }]
    return (

        <div>
            <nav role="navigation"
                className="fixed-bottom d-block d-md-none bottom-tab-nav w-full max-w-[90%] left-1/2 bottom-4 -translate-x-1/2">
                {
                    detailsPageView && <div className='p-6 rounded-t-3xl -mb-6 pdpaddtocart-bottom-tab-nav'>
                        {
                            productDetailsData.map((product, pindex) => (
                                <>
                                    {
                                        qty === 0 ? <button
                                            className='addtocart rounded-full flex gap-2 items-center justify-center px-3 py-1'
                                            onClick={() => {
                                                addToCartClick(product)
                                            }}>
                                            <svg data-name="My cart"
                                                xmlns="http://www.w3.org/2000/svg" width="21.591"
                                                height="20.243" viewBox="0 0 21.591 20.243">
                                                <path data-name="Path 8"
                                                    d="M21.59 8.602a1.992 1.992 0 0 0-1.991-1.992h-3.18l-3.607-5.444a.9.9 0 0 0-.1-.762.909.909 0 0 0-1.26-.256.909.909 0 0 0-.254 1.26.892.892 0 0 0 .661.389l3.189 4.812h-8.5l3.189-4.812a.9.9 0 0 0 .662-.389.909.909 0 0 0-.256-1.26.91.91 0 0 0-1.26.256.9.9 0 0 0-.1.762L5.175 6.61h-3.18A1.992 1.992 0 0 0-.002 8.602a1.985 1.985 0 0 0 1.765 1.969c.008.045.005.089.015.134l1.7 7.661a2.2 2.2 0 0 0 2.086 1.8l10.707.077a2.212 2.212 0 0 0 2.133-1.888l1.423-7.739v-.044a1.984 1.984 0 0 0 1.763-1.97ZM7.582 18.334a.588.588 0 0 1-.588.587.588.588 0 0 1-.587-.587V12.08a.588.588 0 0 1 .587-.588.589.589 0 0 1 .588.588Zm2.533 0a.588.588 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.588.588 0 0 1 .587.588Zm2.533 0a.587.587 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.587.587 0 0 1 .587.588Zm2.533 0a.587.587 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.587.587 0 0 1 .587.588Z"
                                                    fill="#fff" />
                                            </svg>
                                            <span className='text-base xl:text-xl'>Add to Cart</span>
                                        </button> :
                                            <div className='flex w-full'>
                                                <label className='text-base sr-only'>Quantity</label>
                                                <div className='qty-wrapper pl-3 flex justify-between items-center w-full'>
                                                    {
                                                        removeLoader ? <Spinner /> :
                                                            <button
                                                                className='rounded-full w-7 h-7 bg-transparent border-transparent hover:border-0 border-0 p-0 text-2xl text-black'
                                                                onClick={() => {
                                                                    decreamentCartAction(product)
                                                                }}>
                                                                <span>-</span>
                                                            </button>
                                                    }
                                                    <div className='w-10 h-10 bg-white rounded-full flex justify-center items-center'>
                                                        <input type='text'
                                                            className='inline-block w-6 h-6 border-0 bg-transparent'
                                                            value={qty} />
                                                    </div>
                                                    {
                                                        addLoader ? <Spinner /> :
                                                            <button
                                                                className='rounded-full w-7 h-7 bg-transparent border-transparent hover:border-0 border-0 p-0 text-2xl text-black'
                                                                onClick={() => {
                                                                    increamentCartAction(product)
                                                                }}>
                                                                <span>+</span>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                    }
                                </>
                            ))}
                    </div>
                }
                <Nav className="w-100">
                    <div className="d-flex flex-row justify-between w-100">
                        {
                            tabs.map((tab, index) => (
                                <NavItem key={`tab-${index}`}>
                                    <NavLink to={tab.route} className="nav-link" onClick={(e) => {
                                        userLoginCheck(e, tab.route)
                                    }}>
                                        <div className="d-flex flex-column justify-content-center align-items-center relative">
                                            <div className='flex gap-5'>
                                                <img className='grayscale invert' src={tab.icon} alt='' width={17} />
                                                {
                                                    tab.label === "cart" && <span
                                                        className="rounded-full w-4 h-4 bg-red-600 flex items-center justify-center absolute -top-2 -right-2 text-white text-xs">
                                                        {cartproductCount}
                                                    </span>
                                                }
                                            </div>
                                            <div className='label font-light text-xs'>{tab.label}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </div>
                </Nav>
            </nav>
        </div>
    )
}

export default BottomNavigation