import { getStoredItem, setStoredItem, removeStoredItem } from "./../services/storageService";
import { checkValidJson } from "./../services/commonServices";

const storageKeys = {
    storageInitiated: 'storageInitiated',
    initialValue: 'initialValue',
    authToken: 'authToken',
    customerToken: 'customerToken',
    isLogin: 'isLogin',
    uniqueId: 'uniqueId',
    user: 'user',
    searchedStrings: 'searchedStrings',
    appIcon: 'appIcon',
    isOnboardingShown: 'isOnboardingShown',
    locationSearchHistory: 'locationSearchHistory',
    bgTaskRegistered: 'bgTaskRegistered',
    selectedLocation: 'selectedLocation',
    tempTelrOrderRef: 'tempTelrOrderRef',
    previousUrl: 'previousUrl',
    userBMI: 'userBMI',
    UserBMIStatus: 'UserBMIStatus'
};

export const setStorageInitiatedValue = (statusValue = false, type = 'local') => {
    setStoredItem(storageKeys.storageInitiated, statusValue, type);
};
export const getStorageInitiatedValue = (type = 'local') => {
    let checkValue = getStoredItem(storageKeys.storageInitiated, type);
    return (checkValue === 'true') ? true : false;
};
export const clearStorageInitiatedValue = (type = 'local') => {
    removeStoredItem(storageKeys.storageInitiated, type);
};

export const setStorageInitialValue = (statusValue = false, type = 'local') => {
    setStoredItem(storageKeys.initialValue, statusValue, type);
};
export const getStorageInitialValue = (type = 'local') => {
    let checkValue = getStoredItem(storageKeys.initialValue, type);
    return (checkValue === 'true') ? true : false;
};
export const clearStorageInitialValue = (type = 'local') => {
    removeStoredItem(storageKeys.initialValue, type);
};

export const setAuthToken = (tokenValue, type = 'local') => {
    setStoredItem(storageKeys.authToken, tokenValue, type);
};
export const getAuthToken = (type = 'local') => {
    return getStoredItem(storageKeys.authToken, type);
};
export const clearAuthToken = (type = 'local') => {
    removeStoredItem(storageKeys.authToken, type);
};

export const setCustomerToken = (tokenValue, type = 'local') => {
    setStoredItem(storageKeys.customerToken, tokenValue, type);
};
export const getCustomerToken = (type = 'local') => {
    return getStoredItem(storageKeys.customerToken, type);
};
export const clearCustomerToken = (type = 'local') => {
    removeStoredItem(storageKeys.customerToken, type);
};

export const setIsLogin = (statusValue = false, type = 'local') => {
    setStoredItem(storageKeys.isLogin, statusValue, type);
};
export const getIsLogin = (type = 'local') => {
    let loginCheckValue = getStoredItem(storageKeys.isLogin, type);
    return (loginCheckValue === '') ? false : loginCheckValue;
};
export const clearIsLogin = (type = 'local') => {
    removeStoredItem(storageKeys.isLogin, type);
};

export const setUniqueId = (uuidValue, type = 'local') => {
    setStoredItem(storageKeys.uniqueId, uuidValue, type);
};
export const getUniqueId = (type = 'local') => {
    return getStoredItem(storageKeys.uniqueId);
};
export const clearUniqueId = (type = 'local') => {
    removeStoredItem(storageKeys.uniqueId, type);
};

export const setUser = (userValue, type = 'local') => {
    setStoredItem(storageKeys.user, JSON.stringify(userValue), type);
};
export const getUser = (type = 'local') => {
    let currentUser = getStoredItem(storageKeys.user, type);
    return (checkValidJson(currentUser) === true) ? JSON.parse(currentUser) : {};
};
export const clearUser = (type = 'local') => {
    removeStoredItem(storageKeys.user, type);
};

export const setLocationHistory = (historyValues = [], type = 'local') => {
    setStoredItem(storageKeys.locationSearchHistory, historyValues, type);
};
export const geLocationHistory = (type = 'local') => {
    let currentHistory = getStoredItem(storageKeys.locationSearchHistory, type);
    return (Array.isArray(currentHistory)) ? currentHistory : [];
};
export const clearLocationHistory = (type = 'local') => {
    removeStoredItem(storageKeys.locationSearchHistory, type);
};

export const setSelectedLocation = (locationValue = {}, type = 'local') => {
    setStoredItem(storageKeys.selectedLocation, JSON.stringify(locationValue), type);
};
export const geSelectedLocation = (type = 'local') => {
    let currentLocation = getStoredItem(storageKeys.selectedLocation, type);
    return (checkValidJson(currentLocation) === true) ? JSON.parse(currentLocation) : {};
};
export const clearSelectedLocation = (type = 'local') => {
    removeStoredItem(storageKeys.selectedLocation, type);
};

export const setUserBMI = (userBMIValue , type = 'local') => {
    setStoredItem(storageKeys.userBMI, userBMIValue, type='local');
};
export const getUserBMI = (type = 'local') => {
    
    return (getStoredItem(storageKeys.userBMI,type));
};
export const clearUserBMI = (type = 'local') => {
    removeStoredItem(storageKeys.userBMI, type);
};

export const setUserBMIStatus = (userBMIStatus , type = 'local') => {
    setStoredItem(storageKeys.UserBMIStatus, userBMIStatus, type='local');
};
export const getUserBMIStatus = (type = 'local') => {
    
    return (getStoredItem(storageKeys.UserBMIStatus,type));
};
export const clearUserBMIStatus = (type = 'local') => {
    removeStoredItem(storageKeys.UserBMIStatus, type);
};

export const setPaymentDetails = (orderRef = {}, type = 'local') => {
    setStoredItem(storageKeys.tempTelrOrderRef, JSON.stringify(orderRef), type);
};
export const getPaymentDetails = (type = 'local') => {
    let orderRef = getStoredItem(storageKeys.tempTelrOrderRef, type);
    return (checkValidJson(orderRef) === true) ? JSON.parse(orderRef) : {};
};
export const clearPaymentDetails = (type = 'local') => {
    removeStoredItem(storageKeys.tempTelrOrderRef, type);
};