import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Brands.scss'; // Add your custom CSS for styling
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Brands({ collectionData }) {
    const settings = {
        infinite: false,
        slidesToShow: 5, // Number of items to show at once
        slidesToScroll: 1,
        variableWidth: false,
        margin: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    margin: 10,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    margin: 10,
                },
            },
        ],
    };
    return (
        <Container fluid className='TopOffers'>
            <Container className=''>
                <Slider {...settings}>
                    {collectionData.map((collection, i) => {
                        return (
                            <div className="carousel-item" key={i}>
                                <Link to={collection.handle}>
                                    <img src={collection.image} alt='' />
                                </Link>
                            </div>
                        );
                    })}
                </Slider>
            </Container>
        </Container>
    )
}

export default Brands
