import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useDispatch, useSelector, connect, useStore} from 'react-redux';
import Popup from 'reactjs-popup';
import GoogleMapReact from 'google-map-react';
import Autocomplete from "react-google-autocomplete";
import {usePlacesWidget} from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import 'reactjs-popup/dist/index.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BiCurrentLocation} from 'react-icons/bi';

import './LocationMapPopup.scss';
import {googleApiKey} from "./../../../Constants/constant";
import {
    setLocationHistory, geLocationHistory, clearLocationHistory,
    setSelectedLocation, geSelectedLocation, clearSelectedLocation,
} from "./../../../Constants/storedValues";
import {
    setLocationPopupStatusThunk,
    getSuggestionsFromGoogleThunk,
    getPlaceDetailsFromGoogleThunk,
    getGeocodeDetailsFromGoogleThunk,
    setAddressDisplayNameThunk
} from './../../../redux/location';

export default function LocationMapPopup({locationProps}) {

    const dispatch = useDispatch();
    const store = useStore();
    const isLocationMapPopUpOpen = useSelector((state) => state.location.isLocationMapPopUpOpen);
    const [shouldLocationMapPopupShow, setShouldLocationMapPopupShow] = useState(false);
    const [locationPopupStep, setLocationPopupStep] = useState(1);
    const [locationSectionFirst, setLocationSectionFirst] = useState({});
    const [locationSectionFinalCoords, setLocationSectionFinalCoords] = useState({});
    const [locationSectionFinal, setLocationSectionFinal] = useState({});
    const selectedLocationRef = useRef({});
    const locationSectionMarkers = useRef([]);

    const {ref, autocompleteRef} = usePlacesWidget({
        apiKey: googleApiKey,
        onPlaceSelected: (place) => {
            let placeData = {
                placeId: place.place_id,
                formattedAddres: place.formatted_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            };
            console.log('The selected Google Place : ', placeData);
            setLocationSectionFirst(current => placeData);
            setLocationPopupStep(current => 2);
        },
        options: {
            types: [],
            /* componentRestrictions: { country: "ae" }, */
        },
    });

    const loadGoogleMap = (map, maps) => {

        const setNewPlaceMarker = (location) => {

            let previousMarkers = locationSectionMarkers.current;
            if (previousMarkers.length > 0) {
                previousMarkers.forEach((mapMarker) => {
                    mapMarker.setMap(null);
                });
            }

            let marker = new maps.Marker({
                position: location,
                map,
                draggable: true,
            });
            setGeocodeDetails(location.lat, location.lng);

            marker.addListener('dragend', (e) => {
                setGeocodeDetails(e.latLng.lat(), e.latLng.lng());
            });

            previousMarkers = [];
            previousMarkers.push(marker);
            locationSectionMarkers.current = previousMarkers;

        };

        const setGeocodeDetails = (latitude, longitude) => {
            let currentCoords = {
                lat: latitude,
                lng: longitude,
            };
            setLocationSectionFinalCoords(current => currentCoords);
            let geocodeQString = {
                key: googleApiKey,
                latlng: currentCoords.lat + ',' + currentCoords.lng
            };
            dispatch(getGeocodeDetailsFromGoogleThunk(geocodeQString));
            map.setCenter(currentCoords);
        }

        setNewPlaceMarker({lat: locationSectionFirst.latitude, lng: locationSectionFirst.longitude});
        maps.event.addListener(map, 'click', function (event) {
            let placeData = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            setNewPlaceMarker(placeData);
        });

    };

    const resolveLocationWithCoords = async (lat, long) => {
        let currentGeocodeData = store.getState().location.geocodeResult;
        let placeData = {
            latitude: lat,
            longitude: long,
            placeId: '',
            formattedAddress: '',
        };
        if (Object.keys(currentGeocodeData).length > 0) {
            placeData['placeId'] = currentGeocodeData['placeId'];
            placeData['formattedAddress'] = currentGeocodeData['addressLine'];
            placeData['latitude'] = currentGeocodeData['coordinates']['lat'];
            placeData['longitude'] = currentGeocodeData['coordinates']['lng'];
        }
        setLocationSectionFinal(current => placeData);
        selectedLocationRef.current = placeData;
    };

    const detectMyCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(detectMyCurrentLocationSuccess, detectMyCurrentLocationError);
        } else {
            console.log("Geolocation not supported");
            alert('Unable to retrieve your location');
        }
    };

    const detectMyCurrentLocationSuccess = (position) => {
        let placeData = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        };
        setLocationSectionFirst(current => placeData);
        setLocationPopupStep(current => 2);
    };

    const detectMyCurrentLocationError = () => {
        console.log("Unable to retrieve your location");
        alert('Unable to retrieve your location');
    };

    const deliveryLocationSetup = () => {
        clearSelectedLocation();
        resolveLocationWithCoords(locationSectionFinalCoords.lat, locationSectionFinalCoords.lng);
        setSelectedLocation(selectedLocationRef.current);
        setLocationMapPopupOpen(false);
    };

    const setLocationMapPopupOpen = (value) => {
        setShouldLocationMapPopupShow(current => value);
        dispatch(setAddressDisplayNameThunk());
        dispatch(setLocationPopupStatusThunk(value));
    };

    const onLocationMapClose = () => setLocationMapPopupOpen(false);

    const locationSelectionSectionResolver = () => {

        if (locationPopupStep === 1) {
            return (
                <>
                    <Row className='max-md:p-2 md:py-12 md:px-20'>
                        <div className="header">Delivery Location</div>
                        <Col xs={12}>
                            <input ref={ref} style={{width: "100%"}} defaultValue="Dubai"
                                   placeholder='street name, landmark……..'/>
                        </Col>
                        <Col xs={12}>
                            <button
                                className="button w-full h-[50px] rounded-half bg-blue p-[8px] px-6 mt-6 text-base max-md:text-sm max-md:mt-3 flex items-center justify-center gap-2"
                                onClick={() => {
                                    detectMyCurrentLocation();
                                }}>
                                <BiCurrentLocation/>
                                Detect My Current Location
                            </button>
                        </Col>
                    </Row>
                </>
            );
        } else if (locationPopupStep === 2) {
            return (
                <>

                    <Row className='p-6 max-md:p-5'>
                        <Col xs={12}>
                            <div className='rounded-xl overflow-hidden mb-3 h-[400px] max-md:h-[200px]'
                                 style={{width: "100%", paddingLeft: "0%", paddingRight: "0%"}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: googleApiKey,
                                        libraries: ['places'],
                                        id: 'google-place-map-location-selection-area'
                                    }}
                                    defaultCenter={{
                                        lat: locationSectionFirst.latitude,
                                        lng: locationSectionFirst.longitude
                                    }}
                                    defaultZoom={15}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({map, maps}) => loadGoogleMap(map, maps)}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <button
                                className="button w-full h-[50px] rounded-half bg-blue border-white p-[8px] bg-black"
                                onClick={() => {
                                    deliveryLocationSetup();
                                }}>
                                Deliver Here
                            </button>
                        </Col>
                    </Row>

                </>
            );
        }

    };

    return (
        <>
            <Popup className='location-map-popup' open={isLocationMapPopUpOpen} closeOnDocumentClick={false}
                   onClose={onLocationMapClose} lockScroll modal>
                {onLocationMapClose => (
                    <div className="modal-inside">
                        <button className="close" onClick={onLocationMapClose}> &times; </button>
                        <div className="header">Delivery Location</div>
                        <div className="content">
                            {locationSelectionSectionResolver()}
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )

};


