// src/components/MultiItemCarousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './TopOffers.scss'; // Add your custom CSS for styling
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TopOffers = ({ collectionData }) => {
    const settings = {
        infinite: false,
        slidesToShow: 5, // Number of items to show at once
        slidesToScroll: 1,
        variableWidth: false,
        margin: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    margin: 10,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    margin: 10,
                },
            },
        ],
    };

    return (
        <Container fluid className='TopOffers'>
            <Container className=''>
                {collectionData.map((element, index) => {
                    return (
                        <div className="multi-item-carousel -mx-3" key={index}>
                            <h4 className='text-base font-medium md:text-[25px] md:font-bold mb-6'>{element.name}</h4>
                            <Slider {...settings}>
                                {element.collections.map((collection, i) => {
                                    return (
                                        <div className="carousel-item" key={i}>
                                            <Link to={collection.handle}>
                                                <img src={collection.collection_image} alt='' />
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    );
                })}
            </Container>
        </Container>
    );
};

export default TopOffers;
