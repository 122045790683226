import React from 'react'
import './Blog.scss';

const BlogDetails = (props) => {

    return (
        <>
            <div>
                <h2 className='pt-4 pb-10 block w-full text-3xl font-thin text-center'>
                    {props.title}
                </h2>
                <div className='image pb-8'>
                    <img className='rounded-3xl w-full' alt='' src={props.image}/>
                </div>
                <div dangerouslySetInnerHTML={{__html: props.blog_content}}/>
            </div>
        </>
    )
}

export default BlogDetails
