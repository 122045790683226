import React, {useState} from 'react'
import Product from '../../Product/Product';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Container} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import {Link} from 'react-router-dom';

function FeaturedList({featuredListData}) {
    const [show, setShow] = useState(false)
    const settings = {
        infinite: false,
        slidesToShow: 5, // Number of items to show at once
        slidesToScroll: 1,
        // variableWidth: true,
        nav: true,
        margin: 15,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,

                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3.2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.12,
                    swipeToSlide: true,
                    // variableWidth: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2.12,
                    swipeToSlide: true,
                    // infinite: false,
                    // variableWidth: true,
                },
            },
        ],
    };
    return (
        <Container fluid className='FeaturedList max-sm:px-0'>
            <Container>
                {featuredListData.map((featureData, index) => {
                    return (
                        <Row key={index}>
                            <div className='flex justify-between items-center mb-6'>
                                <h2 className='text-base font-medium md:text-[25px] md:font-bold px-0'>{featureData.name}</h2>
                                <Link to={featureData.handle}>
                                    <p className='underline underline-offset-8'>View More</p></Link>
                            </div>
                            <Slider {...settings}>
                                {featureData.product_featured_list.map((product, i) => {
                                    return (
                                        <div className="carousel-item" key={i}>
                                            <Product productData={product} handle={featureData.handle}/>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </Row>
                    )

                })}
            </Container>
        </Container>
    )
}

export default FeaturedList
