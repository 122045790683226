import React, {useEffect, useState} from 'react';
import {getUserInteres, updateInterestData} from '../../redux/profile';
import {useDispatch, useSelector} from 'react-redux';
import {updateInterest} from '../../services/apiCalls';

function MyInterests() {
    const {interestsList} = useSelector(state => state.profile);
    const [savedInterests, setSavedInterests] = useState([]);
    const dispatch = useDispatch();


    async function handleAddInterestClick(Selectedinterest) {
        const currentSavedInterests = [...savedInterests];
        if (!currentSavedInterests.includes(Selectedinterest)) {
            currentSavedInterests.push(Selectedinterest);
        }
        setSavedInterests(currentSavedInterests);
        const myIntrestData = await updateInterest(currentSavedInterests)
        if (myIntrestData.data.code === 1) {
            dispatch(getUserInteres());
        }
    }

    async function handleRemoveInterestClick(interestID) {

        const updatedData = savedInterests.filter((interestId) => interestId !== interestID);

        setSavedInterests(updatedData);
        const myIntrestData = await updateInterest(updatedData)
        if (myIntrestData.data.code === 1) {
            dispatch(getUserInteres());
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (interestsList.length !== 0) {
            const currentSavedInterests = [...savedInterests];
            interestsList.forEach((interest) => {
                if (interest.is_user_selected === 1 && !currentSavedInterests.includes(interest.id)) {
                    currentSavedInterests.push(interest.id);
                }
            });
            setSavedInterests(currentSavedInterests);
        }
    }, [interestsList]);

    useEffect(() => {
        dispatch(getUserInteres());
    }, []);
    return (
        <div className='my-interests-details'>
            <div className='your-interests'>

                <h2 className='text-3xl font-medium mb-3'>Your Interests</h2>
                <div className='your-interests-items'>
                    {interestsList.map((data, index) => (

                        <div className='your-interests-item' key={index}>


                            {(() => {
                                if (data.is_user_selected == 1) {

                                    return (
                                        <span onClick={() => handleRemoveInterestClick(data.id)}>{data.name}</span>
                                    );
                                }
                            })()}


                        </div>


                    ))}
                </div>
            </div>
            <div className='other-interests'>
                <h2 className='text-3xl font-medium mb-3'>Other Interests</h2>
                <div className='other-interests-items'>
                    {interestsList.map((data, index) => (
                        <div key={index}>

                            {(() => {
                                if (data.is_user_selected == 0) {

                                    return (
                                        <>
                                            <div className='other-interests-item' onClick={() => handleAddInterestClick(data.id)}>
                                                <span className='image'><img src={data.image}></img></span>
                                                <span className='label'>{data.name}</span>
                                            </div>
                                        </>
                                    );
                                }
                            })()}
                        </div>

                    ))}
                </div>
            </div>

        </div>
    );
}

export default MyInterests;
