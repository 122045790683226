import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProductDetails, addToCart, toggleWishlist, getAllProductsApi, getVariantProductDetails } from "../services/apiCalls";
import { toast } from "react-toastify";

export const productDetails = createAsyncThunk("Product/getDetails", async (product) => {
    const requestData = {
        "product_id": product.id,
        "handle": product.handle,
    }
    const res = await getProductDetails(requestData);
    return res.data;
})

export const variantProductDetails = createAsyncThunk("Product/getVariantProductDetails", async (data) => {
    const res = await getVariantProductDetails(data);
    return res.data;
})

export const addToCartAction = createAsyncThunk("Product/addToCart", async (requestData) => {
    const res = await addToCart(requestData);
    return res.data;
})

export const wishlistAction = createAsyncThunk("Product/wishlist", async (requestData) => {
    const res = await toggleWishlist(requestData);
    return res.data;
})

export const getAllProduct = createAsyncThunk("Product/getAllProduct", async (requestData) => {
    const res = await getAllProductsApi(requestData);
    return res.data;
})

const INITIAL_STATE = {
    commonDeals: [],
    productDetailsData: [],
    similarProducts: [],
    bestSellingProducts: [],
    productReview: [],
    allProducts: [],
    productOptions: [],
    productReviewCount: 0,
    loader: false,
    addToCartLoader: false,
    addToCartSuccess: false,
    wishlistSuccess: false,
    detailsPageView: false,
    showSearchResult: true,
}

const productSlice = createSlice({
    name: "product",
    initialState: INITIAL_STATE,
    reducers: {
        productView: (state, action) => {
            state.detailsPageView = action.payload;
        },
        clearAllProduct: (state, action) => {
            if (!action.payload) {
                state.allProducts = []
            }
            state.showSearchResult = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productDetails.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(productDetails.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.commonDeals = action.payload.product_common_deals;
                    state.productDetailsData = action.payload.result;
                    state.similarProducts = action.payload.similar_product_brand;
                    state.bestSellingProducts = action.payload.best_selling_similar_product;
                    state.productReview = action.payload.product_review;
                    state.productReviewCount = action.payload.product_review_count;
                    state.productOptions = action.payload.result[0].product_variant_options;
                }
                else {
                    state.commonDeals = []
                    state.productDetailsData = []
                    state.similarProducts = []
                    state.bestSellingProducts = []
                    state.bestSellingProducts = []
                    state.productReview = []
                    state.productOptions = []
                    state.productReviewCount = 0
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(productDetails.rejected, (state, action) => {
                state.loader = false;
                state.commonDeals = []
                state.productDetailsData = []
                state.similarProducts = []
                state.bestSellingProducts = []
                state.bestSellingProducts = []
                state.productReview = []
                state.productOptions = []
                state.productReviewCount = 0
                console.log("error api issue")
            })
            .addCase(variantProductDetails.pending, (state, action) => {
                console.log("pendning");
                // state.loader = true;
            })
            .addCase(variantProductDetails.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.productDetailsData = action.payload.result;
                }else{
                    toast.error(action.payload.msg, {
                        position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
                    });
                }
                // else {
                //     state.productDetailsData = []
                // }
                // state.loader = false;
                console.log("completed");
            })
            .addCase(variantProductDetails.rejected, (state, action) => {
                // state.loader = false;
                // state.productDetailsData = []
                console.log("error api issue")
            })
            .addCase(addToCartAction.pending, (state, action) => {
                console.log("pendning");
                state.addToCartLoader = true;
            })
            .addCase(addToCartAction.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.addToCartSuccess = true
                }
                else {
                    state.addToCartLoader = false;
                }
            })
            .addCase(addToCartAction.rejected, (state, action) => {
                state.addToCartLoader = false;
                console.log("error api issue")
            })
            .addCase(wishlistAction.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(wishlistAction.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.wishlistSuccess = true
                }
                else {
                    state.wishlistSuccess = false
                }
            })
            .addCase(wishlistAction.rejected, (state, action) => {
                console.log("error api issue")
            })
            .addCase(getAllProduct.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(getAllProduct.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.allProducts = action.payload.result
                } else {
                    state.allProducts = []
                }
                console.log("completed");
            })
            .addCase(getAllProduct.rejected, (state, action) => {
                state.allProducts = []
                console.log("error api issue")
            })
    }
})

export const { productView, clearAllProduct } = productSlice.actions;

export default productSlice.reducer;