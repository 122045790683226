import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from 'react-redux';

import { 
    setLocationHistory, geLocationHistory, clearLocationHistory, 
    setSelectedLocation, geSelectedLocation, clearSelectedLocation, 
 } from "./../Constants/storedValues";
 import { getAutoSuggestionsFromGoogle, getPlaceDetailsFromGoogle, getGeocodeDetailsFromGoogle } from "../services/apiCalls";
 import { checkValidJson } from "../services/commonServices";

 const INITIAL_STATE = {
    isLocationMapPopUpOpen: false,
    selectedLocationData: {},
    autocompleteResult: [],
    placeDetailsResult: {},
    geocodeResult: {},
    recentSearches: [],
    recentAddress: '-',
}

export const setLocationPopupStatusThunk = createAsyncThunk("Location/setLocationPopupStatus", async (statusValue) => {
    let currentStatus = statusValue;
    return { 'status': currentStatus };
});

export const getSuggestionsFromGoogleThunk = createAsyncThunk("Location/getSuggestionsFromGoogle", async (requestData) => {
    const res = await getAutoSuggestionsFromGoogle(requestData);
    return res.data;
});

export const getPlaceDetailsFromGoogleThunk = createAsyncThunk("Location/getPlaceDetailsFromGoogle", async (requestData) => {
    const res = await getPlaceDetailsFromGoogle(requestData);
    return res.data;
});

export const getGeocodeDetailsFromGoogleThunk = createAsyncThunk("Location/getGeocodeDetailsFromGoogle", async (requestData) => {
    const res = await getGeocodeDetailsFromGoogle(requestData);
    return res.data;
});

export const setAddressDisplayNameThunk = createAsyncThunk("Location/setAddressDisplayName", async () => {
    let recentAddressTemp = '-';
    let currentLocationDetails = geSelectedLocation();
    if (Object.keys(currentLocationDetails).length > 0) {
        let displayName = currentLocationDetails['formattedAddress'];
        if (displayName.trim() !== '') {
            recentAddressTemp = displayName;
        }
    }
    return { 'displayName': recentAddressTemp };
});

const locationStateSlice = createSlice({
    name: "locationState",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(setLocationPopupStatusThunk.pending, (state,action)=>{
            console.log("location popup status pending");
        })
        .addCase(setLocationPopupStatusThunk.fulfilled, (state,action)=>{
            state.isLocationMapPopUpOpen = action.payload.status;
            console.log("location popup status completed");
        })
        .addCase(setLocationPopupStatusThunk.rejected, (state,action)=>{
            console.log("location popup status error api issue : ", action)
        })
        .addCase(getSuggestionsFromGoogleThunk.pending, (state,action)=>{
            console.log("google location suggestions status pending");
        })
        .addCase(getSuggestionsFromGoogleThunk.fulfilled, (state,action)=>{
            state.autocompleteResult = [];
            if (action.payload.status === 'OK') {
                if (Array.isArray(action.payload.predictions) && (action.payload.predictions.length > 0)) {
                    for (let i = 0; i < action.payload.predictions.length; i++) {
                        let currentPrediction = action.payload.predictions[i];
                        state.autocompleteResult.push({
                            'placeId': currentPrediction['place_id'],
                            'description': currentPrediction['description']
                        });
                    }
                }
            }
            console.log("google location suggestions status completed");
        })
        .addCase(getSuggestionsFromGoogleThunk.rejected, (state,action)=>{
            console.log("google location suggestions status error api issue : ", action)
        })
        .addCase(getPlaceDetailsFromGoogleThunk.pending, (state,action)=>{
            console.log("google place details status pending");
        })
        .addCase(getPlaceDetailsFromGoogleThunk.fulfilled, (state,action)=>{
            state.placeDetailsResult = {};
            if (action.payload.status === 'OK') {
                let currentResult = action.payload.result;
                state.placeDetailsResult = {
                    formattedAddres: currentResult.formatted_address,
                    latitude: currentResult.geometry.location.lat,
                    longitude: currentResult.geometry.location.lng,
                };
            }
            console.log("google place details status completed");
        })
        .addCase(getPlaceDetailsFromGoogleThunk.rejected, (state,action)=>{
            console.log("google place details status error api issue : ", action)
        })
        .addCase(getGeocodeDetailsFromGoogleThunk.pending, (state,action)=>{
            console.log("google geocode details status pending");
        })
        .addCase(getGeocodeDetailsFromGoogleThunk.fulfilled, (state,action)=>{
            state.geocodeResult = {};
            if (action.payload.status === 'OK') {
                let currentResult = action.payload.results;
                let finalResults = [];
                if (Array.isArray(action.payload.results) && (action.payload.results.length > 0)) {
                    for (let i = 0; i < action.payload.results.length; i++) {
                        let currentGeocodeLoop = action.payload.results[i];
                        let finalResultTemp = {
                            placeId: currentGeocodeLoop.place_id,
                            addressLine: currentGeocodeLoop.formatted_address,
                            coordinates: {
                                lat: currentGeocodeLoop.geometry.location.lat,
                                lng: currentGeocodeLoop.geometry.location.lng,
                            },
                        };
                        let addressComponents = currentGeocodeLoop["address_components"];
                        addressComponents.forEach((item) => {
                            const types = item["types"];
                            if (types.indexOf("route") >= 0) {
                                finalResultTemp["thoroughfare"] = item["long_name"];
                            } else if (types.indexOf("street_number") >= 0) {
                                finalResultTemp["subThoroughfare"] = item["long_name"];
                            } else if (types.indexOf("country") >= 0) {
                                finalResultTemp["countryName"] = item["long_name"];
                                finalResultTemp["countryCode"] = item["short_name"];
                            } else if (types.indexOf("locality") >= 0) {
                                finalResultTemp["locality"] = item["long_name"];
                            } else if (types.indexOf("postal_code") >= 0) {
                                finalResultTemp["postalCode"] = item["long_name"];
                            } else if (types.indexOf("postal_code") >= 0) {
                                finalResultTemp["postalCode"] = item["long_name"];
                            } else if (types.indexOf("administrative_area_level_1") >= 0) {
                                finalResultTemp["adminArea"] = item["long_name"];
                                finalResultTemp["adminAreaCode"] = item["short_name"];
                            } else if (types.indexOf("administrative_area_level_2") >= 0) {
                                finalResultTemp["subAdminArea"] = item["long_name"];
                            } else if ((types.indexOf("sublocality") >= 0) || (types.indexOf("sublocality_level_1") >= 0)) {
                                finalResultTemp["subLocality"] = item["long_name"];
                            } else if (types.indexOf("premise") >= 0) {
                                finalResultTemp["featureName"] = item["long_name"];
                            }
                            finalResultTemp["featureName"] = finalResultTemp["featureName"] ?? finalResultTemp["addressLine"];
                        });
                        finalResults.push(finalResultTemp);
                    }
                    console.log("google geocode details status completed : ", finalResults);
                    state.geocodeResult = finalResults[0];
                }
                
            }
            console.log("google geocode details status completed");
        })
        .addCase(getGeocodeDetailsFromGoogleThunk.rejected, (state,action)=>{
            console.log("google geocode details status error api issue : ", action)
        })
        .addCase(setAddressDisplayNameThunk.pending, (state,action)=>{
            console.log("User Location address Display status pending");
        })
        .addCase(setAddressDisplayNameThunk.fulfilled, (state,action)=>{
            state.recentAddress = action.payload.displayName;
            console.log("User Location addres Display status completed");
        })
        .addCase(setAddressDisplayNameThunk.rejected, (state,action)=>{
            console.log("User Location addres Display status error issue : ", action)
        })
    }
});

export default locationStateSlice.reducer;
