import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./ProductDetailsPage.scss";
import { Button, Container } from "react-bootstrap";
import { StarIcon } from "@heroicons/react/20/solid";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DualBanner from "../Common/DualBanner/DualBanner";
import { useDispatch, useSelector, useStore } from "react-redux";
import { productDetails, productView, variantProductDetails } from "../../redux/product";
import MainLoader from "../Common/Loaders/MainLoader";
import Product from "../Product/Product";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noImage from '../../assets/images/noimage.jpeg'
import {
    addToCart, getFilterBrands, toggleWishlist,
} from "../../services/apiCalls";
import { FaStar } from "react-icons/fa";
import Review from "../Common/Review/Review";
import { Link, useHref, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import postpay_image from "../../assets/images/icons/postpay.png";
import { getCartPage } from "../../redux/cartRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { setLoginPopupStatusThunk } from "../../redux/login";
import selectedWishlist from "../../assets/images/icons/favoriteIconselected.png";
import favoriteIcon from "../../assets/images/icons/favoriteIcon.png";
import { Spinner } from "react-activity";
import { Helmet } from "react-helmet";

const product_details_bg = {
    backgroundColor: "#f8f8f8",
};

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ProductDetailsPage({ product }) {
    const dispatch = useDispatch();
    const fullUrl = useHref();
    const store = useStore();
    const urlSplit = fullUrl.split("/");
    const navigate = useNavigate();
    const { cartpageBilling, cartpageItems } = useSelector((state) => state.cart);
    const {
        commonDeals,
        productDetailsData,
        similarProducts,
        bestSellingProducts,
        productReview,
        productReviewCount,
        loader, productOptions
    } = useSelector((state) => state.product);
    let [qty, setQty] = useState(0);
    let [wishlist, setWishlist] = useState(0);
    const [addLoader, setAddLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [variantOption, setVariantOption] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (productDetailsData.length !== 0) {
            setQty(parseInt(productDetailsData[0].qty, 10));
            setWishlist(parseInt(productDetailsData[0].is_user_wishlist, 10));
            // if (productDetailsData[0].hasOwnProperty("product_variants") && productDetailsData[0].product_variants.length > 0) {
            //     var optionData = []
            //     productDetailsData[0].product_variants[0].options.map((options, index) => {
            //         var data = {
            //             "name": options.option,
            //             "value": options.value
            //         }
            //         optionData.push(data);
            //     })
            //     setVariantOption(optionData)
            //     const reqData = {
            //         "handle": product.handle,
            //     }
            //     optionData.map((opt, index) => {
            //         reqData["options[" + index + "]" + "[name]"] = opt.name;
            //         reqData["options[" + index + "]" + "[value]"] = opt.value;
            //     })
            //     dispatch(variantProductDetails(reqData));
            //     // optionData = []
            // }
            window.dataLayer.push({
                'event': 'select_item', 'ecommerce': {
                    "items": [{
                        "item_id": productDetailsData[0].sku,
                        "item_name": productDetailsData[0].name,
                        "price": productDetailsData[0].sellingPrice,
                        "quantity": productDetailsData[0].qty,
                    }],
                }
            });
            window.dataLayer.push({
                'event': 'view_item', 'ecommerce': {
                    "items": [{
                        "item_id": productDetailsData[0].sku,
                        "item_name": productDetailsData[0].name,
                        "price": productDetailsData[0].sellingPrice,
                        "quantity": productDetailsData[0].qty,
                    }],
                }
            });
        } else {
            setQty(0);
            setWishlist(0);
        }
    }, [productDetailsData]);
    useEffect(() => {
        dispatch(productDetails(product));
        dispatch(productView(true));
        return () => {
            dispatch(productView(false));
        };
    }, [product]);
    const settings = {
        infinite: false, slidesToShow: 5, // Number of items to show at once
        slidesToScroll: 1, // variableWidth: true,
        nav: false, margin: 15, responsive: [{
            breakpoint: 1200, settings: {
                slidesToShow: 4,
            },
        }, {
            breakpoint: 991, settings: {
                slidesToShow: 3,
            },
        }, {
            breakpoint: 767, settings: {
                slidesToShow: 2,
            },
        }, {
            breakpoint: 576, settings: {
                slidesToShow: 2,
            },
        },],
    };
    const addToCartClick = async (product) => {
        const data = {
            product_id: product.id,
            qty: 1,
            mrp: product.mrp,
            selling_price: product.selling_price,
            action: "1",
            flag: "1",
        };
        const addtoCart = await addToCart(data);
        if (addtoCart.data.code === 1) {
            dispatch(getCartPage());
            setQty(1);
            toast.success("Product Added to Cart", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
            window.dataLayer.push({
                'event': 'add_to_cart', 'ecommerce': {
                    "currency": "AED",
                    "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                    "items": cartpageItems,
                }
            });
        } else {
            toast.error(addtoCart.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    };
    const buyNowClick = async (product) => {
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            if (qty === 0) {
                const data = {
                    product_id: product.id,
                    qty: 1,
                    mrp: product.mrp,
                    selling_price: product.selling_price,
                    action: "1",
                    flag: "1",
                };
                const addtoCart = await addToCart(data);
                if (addtoCart.data.code === 1) {
                    dispatch(getCartPage());
                    setQty(1);
                    toast.success("Product Added to Cart", {
                        position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
                    });
                    navigate('/checkout', { state: { flag: "1" } });
                } else {
                    toast.error(addtoCart.data.msg, {
                        position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
                    });
                }
            } else {
                navigate('/checkout', { state: { flag: "1" } });
            }
        }

    };
    const increamentCartAction = async (product) => {
        setAddLoader(true)
        const data = {
            product_id: product.id,
            qty: 1,
            mrp: product.mrp,
            selling_price: product.selling_price,
            action: "1",
            flag: "1",
        };
        const qtyIncrement = await addToCart(data);
        qty = qty + 1;
        if (qtyIncrement.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage());
            setQty(qty);
        } else {
            setAddLoader(false)
            toast.error(qtyIncrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    };
    const decreamentCartAction = async (product) => {
        setRemoveLoader(true)
        const data = {
            product_id: product.id,
            qty: 1,
            mrp: product.mrp,
            selling_price: product.selling_price,
            action: "2",
            flag: "1",
        };
        const qtyDecrement = await addToCart(data);
        qty = qty - 1;
        if (qtyDecrement.data.code === 1) {
            dispatch(getCartPage());
            setQty(qty);
            if (qty === 0) {
                toast.error(qtyDecrement.data.msg, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
                });
                window.dataLayer.push({
                    'event': 'remove_from_cart', 'ecommerce': {
                        "currency": "AED",
                        "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                        "items": cartpageItems,
                    }
                });
            }
            setRemoveLoader(false)
        } else {
            setRemoveLoader(false)
            toast.error(qtyDecrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    };
    const wishlistClick = async (id) => {
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            const data = {
                product_id: id,
            };
            const addWishlist = await toggleWishlist(data);
            if (addWishlist.data.code === 1) {
                toast.success(addWishlist.data.msg, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
                });
                setWishlist(!wishlist);
            }
        }
    };
    const brandClick = async (id) => {
        const reqData = {
            flag: "4", similar_flag: "1", product_id: id,
        };
        const brandData = await getFilterBrands(reqData);
        if (brandData.data.code === 1) {
            navigate(brandData.data.result[0].brand_handle);
        }
    };
    const [showComponent, setShowComponent] = useState(false);
    const [tabValue, setTabValue] = useState("Description");
    const [showFullDescription, setFullDescription] = useState(false);

    const [show, setShow] = useState(false);
    const [productId, setProductId] = useState("");

    const handleButtonClick = (id) => {
        setShow(true);
        setProductId(id);
        setShowComponent(!showComponent);
    };

    const breadCrumbFormate = (handle) => {
        const words = handle.split("-");
        const formattedString = words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        return formattedString;
    };

    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const onChangeVariant = (name, val) => {
        if (variantOption.length !== 0) {
            const updatedData = variantOption.map(item => {
                if (item.name === name) {
                    return { ...item, value: val };
                }
                return item;
            });
            const index = updatedData.findIndex(object => object.name === name);
            if (index === -1) {
                var data = {
                    "name": name,
                    "value": val
                }
                updatedData.push(data);
            }
            setVariantOption(updatedData)
            const reqData = {
                "handle": product.handle,
            }
            updatedData.map((opt, index) => {
                reqData["options[" + index + "]" + "[name]"] = opt.name;
                reqData["options[" + index + "]" + "[value]"] = opt.value;
            })
            dispatch(variantProductDetails(reqData));
        }
        else {
            var optionData = []
            var data = {
                "name": name,
                "value": val
            }
            optionData.push(data);
            setVariantOption(optionData)
            const reqData = {
                "handle": product.handle,
            }
            optionData.map((opt, index) => {
                reqData["options[" + index + "]" + "[name]"] = opt.name;
                reqData["options[" + index + "]" + "[value]"] = opt.value;
            })
            dispatch(variantProductDetails(reqData));
        }
    }

    return (<>
        {loader ? (<MainLoader />) : (<>
            {productDetailsData.map((product, pindex) => {
                return (<>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{product.seo_title}</title>
                        <meta name="title" content={product.seo_title} key="title" />
                        <meta name="description" content={product.seo_desc} key="description"/>
                        <meta name="keywords" content={product.seo_keywords} key="keywords"/>
                        <meta property="og:title" content={product.seo_title} key="og:title" />
                        <meta property="og:description" content={product.seo_desc} key="og:description" />
                    </Helmet>
                    <Container className="flex flex-column pb-2">
                        <Row>
                            <Col className="max-lg:hidden">
                                <ul className="flex gap-2 py-2 text-xs ">
                                    {urlSplit.map((split, index) => {
                                        if (index === 0) {
                                            return (<li>
                                                <Link to="/">
                                                    Home{" "}
                                                    <span>
                                                        <FontAwesomeIcon
                                                            size="md"
                                                            icon={faAngleRight}
                                                        />
                                                    </span>
                                                </Link>
                                            </li>);
                                        } else if (urlSplit.length - 1 === index) {
                                            return (<li className="opacity-50">
                                                {breadCrumbFormate(split)}
                                            </li>);
                                        } else {
                                            return (<li>
                                                <Link to={split}>
                                                    {breadCrumbFormate(split)}{" "}
                                                    <span>
                                                        <FontAwesomeIcon
                                                            size="md"
                                                            icon={faAngleRight}
                                                        />
                                                    </span>
                                                </Link>
                                            </li>);
                                        }
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                    <Container
                        fluid
                        className="py-6 max-md:py-4 md:bg-[#f8f8f8] max-md:px-0"
                    >

                        <Container className="flex flex-column">
                            <Row key={pindex}>
                                <Col
                                    xs={12}
                                    md={6}
                                    className="product_image flex flex-wrap justify-center"
                                >
                                    <div className="product_image relative">
                                        <div className='flex justify-between w-full absolute top-2 left-0 px-3'>
                                            <div className='inline-block'>
                                                {product.available_offer !== null &&
                                                    <div
                                                        className='discount_label inline-block bg-[#FF0000] text-xs text-white rounded-full min-w-[64px] py-1 px-2'>
                                                        <span>{product.available_offer.offer_code}</span>
                                                    </div>}
                                            </div>
                                            <div className='inline-block ml-auto'>
                                                {product.product_discount !== 0 && <div
                                                    className='discount_label inline-block bg-[#FF0000] text-xs text-white rounded-full min-w-[64px] py-1 px-2'>
                                                    <span>{product.product_discount}% OFF</span>
                                                </div>}
                                            </div>
                                        </div>
                                        <div
                                            className="ml-auto absolute inline-block top-0 right-0 md:hidden">
                                            <button
                                                href="#"
                                                className="wishlist h-auto flex items-center gap-2 text-base xl:text-lg font-normal hover:text-black bg-transparent p-0 border-none text-black hover:border-none"
                                                onClick={() => {
                                                    wishlistClick(product.id);
                                                }}
                                            >
                                                <div className="pb-3">
                                                    <img
                                                        alt=""
                                                        src={wishlist ? selectedWishlist : favoriteIcon}
                                                        width="26"
                                                        height="auto"
                                                        className="d-inline-block align-end"
                                                        style={{ filter: wishlist && "unset", }}
                                                    />
                                                </div>
                                            </button>

                                            <a
                                                href="#"
                                                className="share flex items-center gap-2 text-base xl:text-lg font-base hover:text-black"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="19"
                                                    height="20"
                                                    viewBox="0 0 19 20"
                                                >
                                                    <g
                                                        transform="translate(.5 .5)"
                                                        fill="none"
                                                        stroke="#41bdde"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <circle
                                                            data-name="Ellipse 21"
                                                            cx="3"
                                                            cy="3"
                                                            r="3"
                                                            transform="translate(12)"
                                                        />
                                                        <circle
                                                            data-name="Ellipse 22"
                                                            cx="3.5"
                                                            cy="3.5"
                                                            r="3.5"
                                                            transform="translate(0 6)"
                                                        />
                                                        <circle
                                                            data-name="Ellipse 23"
                                                            cx="3"
                                                            cy="3"
                                                            r="3"
                                                            transform="translate(12 13)"
                                                        />
                                                        <path
                                                            data-name="Line 9"
                                                            d="m6.5 11.5 6 3"
                                                        />
                                                        <path
                                                            data-name="Line 10"
                                                            d="m12.5 4.5-6 3"
                                                        />
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                        {product.product_images.map((images, index) => {
                                            return (
                                                <Image
                                                    src={images.file}
                                                    width={459}
                                                    height={459}
                                                    key={index}
                                                    rounded
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = { noImage };
                                                    }}
                                                />
                                            );
                                        })}
                                        {
                                            product.product_images.length === 0 &&
                                            <img src={noImage}
                                                width={459}
                                                height={459}
                                                alt="" />
                                        }
                                    </div>
                                    {commonDeals.map((images, index) => {

                                        return (<div
                                            className="banner pt-6 hidden md:block w-full"
                                            key={index}
                                        >
                                            <Link to={images.banners[0] ? images.banners[0].redirect_url : ""}>
                                                <Image
                                                    src={images.banners[0] ? images.banners[0].image : ""}
                                                    className="rounded-5 "
                                                /></Link>
                                        </div>);
                                    })}
                                </Col>
                                <Col
                                    xs={12}
                                    md={6}
                                    className="product_details pt-4 md:pt-0 max-md:flex max-md:flex-wrap"
                                >
                                    <div className="product_name text-lg md:text-2xl md:pb-7">
                                        <strong
                                            className="max-md:font-medium max-md:text-[#575756] font-bold">
                                            {product.name}
                                        </strong>
                                    </div>
                                    <div className="items-start pb-10 flex max-md:hidden">
                                        <div className="flex items-center">
                                            {[0, 1, 2, 3, 4].map((rating) => (<StarIcon
                                                key={rating}
                                                className={classNames(product.rating > rating ? "text-yellow-300" : "text-[#fcd93a]", "h-5 w-5 flex-shrink-0")}
                                                aria-hidden="true"
                                            />))}
                                        </div>
                                        <p className="sr-only">
                                            {product.rating} out of 5 stars
                                        </p>
                                        <a
                                            href="#review"
                                            className="ml-3 text-sm font-medium hover:text-indigo-500 text-slate-500 "
                                            onClick={() => {
                                                setTabValue("Reviews")
                                            }}

                                        >
                                            Reviews ({productReviewCount})
                                        </a>
                                    </div>
                                    <div>
                                        <ul className='swatch-opt'>
                                            {
                                                productOptions.map((option, index) => (
                                                    <li className='swatch-attribute' key={index}>
                                                        <div className='swatch-attribute-label'>{option.name}</div>
                                                        <ul className='swatch-attribute-options'>
                                                            {
                                                                option.values.map((val, inx) => {
                                                                    return (
                                                                        <li className='swatch-option' key={inx}>
                                                                            <input className='sr-only' type="radio" name={option.name} value={val} id={val} onClick={() => { onChangeVariant(option.name, val) }} />
                                                                            <label htmlFor={val}>
                                                                                <span>{val}</span>
                                                                            </label>
                                                                        </li>
                                                                    );
                                                                }
                                                                )
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div
                                        className="items-center md:pb-4 max-md:pt-1 flex max-md:justify-between max-md:w-full">
                                        <div
                                            className="brand max-md:text-xs max-md:text-[#575756]"
                                            onClick={() => {
                                                brandClick(product.id);
                                            }}
                                        >
                                            <Link to="">{product.brand_name}</Link>
                                        </div>
                                        <div
                                            className="border-black border-solid border-r h-4 mx-3 max-md:hidden"></div>
                                        <div
                                            className="sku max-md:text-xs max-md:text-[#575756] font-light">
                                            SKU: {product.sku}
                                        </div>
                                    </div>
                                    <div
                                        className="max-md:hidden py-10 lg:col-span-2 lg:col-start-1 lg:pb-8 lg:pr-0 lg:pt-0">
                                        <div className="space-y-6">
                                            <div>
                                                <span
                                                    className="text-base text-gray-900"
                                                    dangerouslySetInnerHTML={{
                                                        __html: showFullDescription ? product.product_subtext : product.product_subtext.slice(0, 300),
                                                    }}
                                                />
                                                {product.product_subtext.length > 300 &&
                                                    <span className="text-cyan-400 cursor-pointer"
                                                        onClick={showFullDescriptionHandler}> Read {showFullDescription ? "Less" : "More"}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price_wrapper max-md:pt-2">
                                        <div className="price flex items-center gap-2">
                                            <div
                                                className="selling_price font-bold text-2xl max-md:text-base max-md:text-[#575756]">
                                                AED {product.selling_price}
                                            </div>
                                            {/* doubt cleare needed section */}
                                            {parseFloat(product.mrp) > parseFloat(product.selling_price) && (
                                                <div
                                                    className="mrp_price text-base max-md:text-xs font-bold line-through text-[#c6c6c6]">
                                                    AED {product.mrp}
                                                </div>)}
                                        </div>
                                    </div>
                                    <div
                                        className="emi flex items-center text-sm font-light max-md:text-xs max-md:block max-md:w-full max-md:pt-2 pt-2 max-md:hidden">
                                        <span className="font-semibold pr-1 text-base">
                                            AED {(product.selling_price / 3).toFixed(2)}{" "}
                                        </span>
                                        <span> Pay in 3 interest-free payments with</span>
                                        <img alt="postpay" src={postpay_image} />
                                    </div>
                                    <div className="hidden md:flex items-center pt-5 gap-3">
                                        {qty === 0 ? (<button
                                            className="addtocart rounded-full flex gap-2 items-center justify-center px-3 py-1 h-auto"
                                            onClick={() => {
                                                addToCartClick(product);
                                            }}
                                        >
                                            <svg
                                                data-name="My cart"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21.591"
                                                height="20.243"
                                                viewBox="0 0 21.591 20.243"
                                            >
                                                <path
                                                    data-name="Path 8"
                                                    d="M21.59 8.602a1.992 1.992 0 0 0-1.991-1.992h-3.18l-3.607-5.444a.9.9 0 0 0-.1-.762.909.909 0 0 0-1.26-.256.909.909 0 0 0-.254 1.26.892.892 0 0 0 .661.389l3.189 4.812h-8.5l3.189-4.812a.9.9 0 0 0 .662-.389.909.909 0 0 0-.256-1.26.91.91 0 0 0-1.26.256.9.9 0 0 0-.1.762L5.175 6.61h-3.18A1.992 1.992 0 0 0-.002 8.602a1.985 1.985 0 0 0 1.765 1.969c.008.045.005.089.015.134l1.7 7.661a2.2 2.2 0 0 0 2.086 1.8l10.707.077a2.212 2.212 0 0 0 2.133-1.888l1.423-7.739v-.044a1.984 1.984 0 0 0 1.763-1.97ZM7.582 18.334a.588.588 0 0 1-.588.587.588.588 0 0 1-.587-.587V12.08a.588.588 0 0 1 .587-.588.589.589 0 0 1 .588.588Zm2.533 0a.588.588 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.588.588 0 0 1 .587.588Zm2.533 0a.587.587 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.587.587 0 0 1 .587.588Zm2.533 0a.587.587 0 0 1-.587.587.588.588 0 0 1-.588-.587V12.08a.589.589 0 0 1 .588-.588.587.587 0 0 1 .587.588Z"
                                                    fill="#fff"
                                                />
                                            </svg>
                                            <span className="text-base xl:text-xl font-light">
                                                Add to Cart
                                            </span>
                                        </button>) : (<div className="flex">
                                            <label className="text-base">Quantity</label>
                                            <div className="qty-wrapper pl-3 inline-flex">
                                                {
                                                    removeLoader ? <Spinner /> : <button
                                                        className="rounded-full w-7 h-7 bg-transparent border-black border-1 p-0 text-base text-black"
                                                        onClick={() => {
                                                            decreamentCartAction(product);
                                                        }}
                                                    >
                                                        <span>-</span>
                                                    </button>
                                                }
                                                <div className="inline-block w-9 h-7">
                                                    <input
                                                        type="text"
                                                        className="inline-block w-6 h-6 border-0 bg-transparent"
                                                        value={qty}
                                                    />
                                                </div>
                                                {
                                                    addLoader ? <Spinner /> : <button
                                                        className="rounded-full w-7 h-7 bg-transparent border-black border-1 p-0 text-base text-black"
                                                        onClick={() => {
                                                            increamentCartAction(product);
                                                        }}
                                                    >
                                                        <span>+</span>
                                                    </button>
                                                }
                                            </div>
                                        </div>)}
                                        <button
                                            className="buynow rounded-full flex gap-2 items-center justify-center px-3 py-1 h-auto bg-black border-black"
                                            onClick={() => {
                                                buyNowClick(product);
                                            }}
                                        >
                                            <span className="text-base xl:text-xl font-light">
                                                Buy Now
                                            </span>
                                        </button>
                                        <button
                                            href="#"
                                            className="wishlist h-auto flex items-center gap-2 text-base xl:text-lg font-normal hover:text-black bg-transparent p-0 border-none text-black hover:border-none"
                                            onClick={() => {
                                                wishlistClick(product.id);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20.999"
                                                height="17.74"
                                                viewBox="0 0 20.999 17.74"
                                            >
                                                <path
                                                    data-name="Path 42"
                                                    d="M10.502 3.136s1.727-2.5 4.344-2.623a5.181 5.181 0 0 1 5.644 4.783c.155 2.586-1.68 4.918-3.162 6.5a33.664 33.664 0 0 1-6.835 5.364 33.65 33.65 0 0 1-6.829-5.372C2.184 10.202.35 7.869.509 5.282A5.179 5.179 0 0 1 6.157.505c2.62.13 4.345 2.631 4.345 2.631Z"
                                                    fill="none"
                                                    stroke="#41bdde"
                                                />
                                            </svg>
                                            <span>Add to Wishlist</span>
                                        </button>
                                        <div className="sepration h-4 w-px bg-black"></div>
                                        <a
                                            href="#"
                                            className="share flex items-center gap-2 text-base xl:text-lg font-base hover:text-black"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19"
                                                height="20"
                                                viewBox="0 0 19 20"
                                            >
                                                <g
                                                    transform="translate(.5 .5)"
                                                    fill="none"
                                                    stroke="#41bdde"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <circle
                                                        data-name="Ellipse 21"
                                                        cx="3"
                                                        cy="3"
                                                        r="3"
                                                        transform="translate(12)"
                                                    />
                                                    <circle
                                                        data-name="Ellipse 22"
                                                        cx="3.5"
                                                        cy="3.5"
                                                        r="3.5"
                                                        transform="translate(0 6)"
                                                    />
                                                    <circle
                                                        data-name="Ellipse 23"
                                                        cx="3"
                                                        cy="3"
                                                        r="3"
                                                        transform="translate(12 13)"
                                                    />
                                                    <path data-name="Line 9" d="m6.5 11.5 6 3" />
                                                    <path data-name="Line 10" d="m12.5 4.5-6 3" />
                                                </g>
                                            </svg>
                                            <span>share</span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container fluid className="max-md:px-0 max-md:pb-20">
                        <Container className="flex flex-column">
                            <Row className="pt-12 max-md:py-0">
                                <div className="product-description-container">
                                    <div className="pt-8">
                                        <h1 className="text-[25px] font-bold mb-2 max-md:text-base max-md:font-medium">
                                            How about These?
                                        </h1>
                                        <Slider {...settings}>
                                            {similarProducts.map((product, i) => {
                                                return (<div className="carousel-item" key={i}>
                                                    <Product productData={product} />
                                                </div>);
                                            })}
                                        </Slider>
                                    </div>
                                    <div className="pt-8">
                                        <h1 className="text-[25px] font-bold mb-2 max-md:text-base max-md:font-medium">
                                            Similar Products
                                        </h1>
                                        <Slider {...settings}>
                                            {bestSellingProducts.map((product, i) => {
                                                return (<div className="carousel-item" key={i}>
                                                    <Product productData={product} />
                                                </div>);
                                            })}
                                        </Slider>
                                    </div>
                                    <div id="review">
                                        <Tabs
                                            defaultActiveKey={tabValue}
                                            id="uncontrolled-tab-example"
                                            className="max-md:mb-0 md:mb-3 product-description"
                                        >
                                            <Tab
                                                eventKey="Description"
                                                title="Description"
                                                className="font-light text-base max-md:p-3 md:px-0"
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.description,
                                                    }}
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="Reviews"
                                                title="Reviews"
                                                className="max-md:p-3 md:px-0"
                                            >
                                                <div>
                                                    <div className='flex justify-between items-center'>
                                                        <div className="text-slate-400 ">
                                                            Reviews ({productReviewCount})
                                                        </div>
                                                        {product.is_product_purchase === 1 && (<button
                                                            className="bg-black font-light border-black text-base max-h-[50px]"
                                                            onClick={() => handleButtonClick(product.id)}
                                                        >
                                                            Write A Review
                                                        </button>)}
                                                    </div>
                                                    {showComponent && (<Review id={productId} showpopup={show} />)}
                                                    {productReview.map((review, rIndex) => {
                                                        return (<>
                                                            <div className="pdp-review-items">
                                                                <div className="pdp-review-item">
                                                                    <p className="text-xl font-medium text-[#575756] mb-2 pl-8 pt-4">
                                                                        {review.name}
                                                                    </p>
                                                                    <div className="flex gap-6">
                                                                        <p className="text-base font-light">
                                                                            {review.review_added_date}
                                                                        </p>
                                                                        <div
                                                                            className={"flex star rating-" + review.rating}
                                                                        >
                                                                            <div className="rating">
                                                                                <label
                                                                                    htmlFor="rating-5"
                                                                                    id="rating-5"
                                                                                ></label>
                                                                                <label
                                                                                    htmlFor="rating-4"
                                                                                    id="rating-4"
                                                                                ></label>
                                                                                <label
                                                                                    htmlFor="rating-3"
                                                                                    id="rating-3"
                                                                                ></label>
                                                                                <label
                                                                                    htmlFor="rating-2"
                                                                                    id="rating-2"
                                                                                ></label>
                                                                                <label
                                                                                    htmlFor="rating-1"
                                                                                    id="rating-1"
                                                                                ></label>
                                                                            </div>
                                                                        </div>
                                                                        <p className="text-base font-light italic">
                                                                            {review.review_body}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className="disclaimer-container py-8 max-md:py-4">
                                    <h1 className="text-base font-bold mb-1 max-md:font-medium">
                                        Disclaimer
                                    </h1>
                                    <p className="text-sm font-light">
                                        {product.disclaimer}
                                    </p>
                                </div>
                                {commonDeals.map((images, index) => {
                                    return (<DualBanner
                                        key={index}
                                        dualBannerData={[{ image: images.banners[1] ? images.banners[1].image : "", redirect_url: images.banners[1] ? images.banners[1].redirect_url : "" }, { image: images.banners[2] ? images.banners[2].image : "", redirect_url: images.banners[2] ? images.banners[2].redirect_url : "" },]}
                                    />);
                                })}
                                {/* <DualBanner dualBannerData={[{ image: "" }]} /> */}
                            </Row>
                        </Container>
                    </Container>
                </>);
            })}
        </>)}
    </>);
}

export default ProductDetailsPage;