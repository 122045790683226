// import React from 'react'
import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useNavigate, Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrder, getUserInteres } from "../../redux/profile";
import { processUserLogoutApi, updateInterest } from "../../services/apiCalls";
import {
    clearCustomerToken, clearUser, getUser, getUserBMI, getUserBMIStatus, setIsLogin, setUniqueId,
} from "../../Constants/storedValues";
import { uuidv4 } from "@firebase/util";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiChevronRight } from "react-icons/fi";
import profilemyOrder from "../../assets/images/icons/profile-myOrder.png";
import profilebmi from "../../assets/images/icons/profile-bmi.png";
import profilediat from "../../assets/images/icons/profile-diat.png";
import profilegym from "../../assets/images/icons/profile-gym.png";
import {
    getUserProfileDetailsThunk, setLoggedInStatusThunk, setProfileDisplayNameThunk, updateUserProfile,
} from "../../redux/login";
import BMI from "../../Components/Common/BMI/BMI";

function CustomerProfile() {
    const pathURL = "/myProfile";
    const myOrderesURL = "/my-orders";
    const myaddressURL = "/my-address";
    const myInterestsURL = "/my-interests";
    const notificationsURL = "/notifications";
    const [togglestate, settogglestate] = useState("profile");
    const [currentpathURL, setPathURL] = useState(window.location.pathname.split("/").length - 1);
    const [people, setPeople] = useState({});
    const { profileName } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = getUser();
    const bmiValue = getUserBMI();
    const bmiStatus = getUserBMIStatus();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMyOrder());
    }, []);

    useEffect(() => {
        const userData = getUser();
        setPeople({
            name: userData.fName !== undefined && userData.fName + " " + userData.lName !== undefined && userData.lName,
            email: userData.email,
            imageUrl: userData.photoUrl,
        })
    }, [profileName]);

    useEffect(() => {
        const pathname = window.location.pathname;
        const parts = pathname.split("/");
        const lastPart = parts[parts.length - 1];
        setPathURL(lastPart);

        const toggleStateMap = {
            "my-orders": ["my-orders"],
            "my-address": ["my-address", "add-address", "edit-address"],
            "my-interests": ["my-interests"],
            notifications: ["notifications"],
        };

        let toggleState = "profile";

        for (const state in toggleStateMap) {
            if (toggleStateMap[state].includes(lastPart)) {
                toggleState = state;
                break;
            } else if (pathname.includes("order")) {
                toggleState = "my-orders";
                break;
            }
        }

        settogglestate(toggleState);
    }, [currentpathURL]);

    function handleTabClick(targetUrl, tabName) {
        navigate(pathURL + targetUrl);
        settogglestate(tabName);
    }

    const [showpopup, setShowPopup] = useState(false);
    const [popup, setPopup] = useState(false);

    function handleClick(page) {
        if (page !== "logout") {
            if (page === "my-interests") {
                setPopup(true);
                setShowPopup(!showpopup);
            } else {
                navigate(page);
                setShow(false);
            }
        } else {
            logOutClick();
        }
    }

    const logOutClick = async () => {
        const reqData = {
            device: "web",
        };
        const logout = await processUserLogoutApi(reqData);
        if (logout.data.code === 1) {
            clearCustomerToken();
            setIsLogin(Boolean(0));
            setUniqueId(uuidv4());
            clearUser();
            dispatch(getUserProfileDetailsThunk());
            dispatch(setLoggedInStatusThunk());
            dispatch(setProfileDisplayNameThunk());
            navigate("/");
        } else {
            toast.success(logout.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    };
    const [showComponent, setShowComponent] = useState(false);
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    function showSetting() {
        setShow(true);
        setShowComponent(!showComponent);
    }

    const { interestsList } = useSelector((state) => state.profile);
    const [savedInterests, setSavedInterests] = useState([]);

    async function handleRemoveInterestClick(interestID) {
        let flag = 0;
        savedInterests.map((data, index) => {
            if (data === interestID) {
                flag = 1;
                const updatedData = savedInterests.filter((interestId) => interestId !== interestID);

                setSavedInterests(updatedData);

            }
        });
        if (flag === 0) {
            setSavedInterests((current) => [...current, interestID]);
        }
    }

    const SubmitClick = async () => {
        const myIntrestData = await updateInterest(savedInterests);
        if (myIntrestData.data.code === 1) {
            dispatch(getUserInteres());
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (interestsList.length !== 0) {
            const currentSavedInterests = [...savedInterests];
            interestsList.forEach((interest) => {
                if (interest.is_user_selected === 1 && !currentSavedInterests.includes(interest.id)) {
                    currentSavedInterests.push(interest.id);
                }
            });
            setSavedInterests(currentSavedInterests);
        }
    }, [interestsList]);

    useEffect(() => {
        dispatch(getUserInteres());
    }, []);
    const [showBMIPopup, setShowBMIPopup] = useState(false);
    const handleDataReceivedBmi = (data) => {
        handleClickBmi();
    };
    const handleClickBmi = () => {
        setShowBMIPopup(!showBMIPopup);
    };

    const interestclassname = (value) => {
        if (value === 1) {
            return "other-interests-item active";
        } else {
            return "other-interests-item";
        }
    };

    const uploadProfile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const formData = {
                "f_name": user.fName,
                "l_name": user.lName,
                "email": user.email,
                "dob": user.dob,
                "photo_url": e.target.files[0],
                "gender": user.gender,
            }
            dispatch(updateUserProfile(formData));
            toast.success("Profile updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            const updatePeople = { ...people, imageUrl: URL.createObjectURL(e.target.files[0]) };
            setPeople(updatePeople)
        }
    }


    return (<>
        <div className="flex justify-end py-2 max-md:px-3 md:hidden">
            <button
                className="bg-[#F6F6F6] border-0 h-auto p-2.5 hover:bg-[#F6F6F6]"
                onClick={showSetting}
            >
                <span className="sr-only">setting</span>
                <div className="flex w-5 h-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.715 55.715">
                        <path
                            data-name="Path 57"
                            d="M50.65 22.792h-.431a4.173 4.173 0 0 1-3.824-2.533v-.2a4.174 4.174 0 0 1 .836-4.609l.152-.152a5.067 5.067 0 0 0 0-7.164h-.005a5.064 5.064 0 0 0-7.162 0l-.152.152a4.174 4.174 0 0 1-4.609.836 4.173 4.173 0 0 1-2.533-3.824V5.07A5.067 5.067 0 0 0 27.857.005a5.065 5.065 0 0 0-5.065 5.065v.431a4.181 4.181 0 0 1-2.532 3.824h-.2a4.178 4.178 0 0 1-4.609-.836l-.152-.152h-.005a5.064 5.064 0 0 0-7.162 0h-.005a5.062 5.062 0 0 0 .005 7.162l.152.152a4.183 4.183 0 0 1 .836 4.609 4.179 4.179 0 0 1-3.824 2.735h-.228A5.065 5.065 0 0 0 .003 28.06a5.064 5.064 0 0 0 5.065 5.065h.431a4.184 4.184 0 0 1 3.824 2.532 4.183 4.183 0 0 1-.836 4.609l-.152.152-.005.005a5.062 5.062 0 0 0 .005 7.162 5.067 5.067 0 0 0 7.164 0l.152-.152a4.183 4.183 0 0 1 4.609-.836 4.18 4.18 0 0 1 2.735 3.824v.228a5.064 5.064 0 0 0 5.065 5.065 5.065 5.065 0 0 0 5.065-5.065v-.43a4.176 4.176 0 0 1 2.533-3.824 4.183 4.183 0 0 1 4.609.836l.152.152v.005a5.067 5.067 0 0 0 7.164-.005 5.067 5.067 0 0 0 0-7.165l-.152-.152a4.174 4.174 0 0 1-.836-4.609 4.181 4.181 0 0 1 3.824-2.532h.228a5.065 5.065 0 0 0 5.065-5.065 5.067 5.067 0 0 0-5.062-5.068ZM27.858 35.454a7.6 7.6 0 0 1-7.6-7.6 7.6 7.6 0 0 1 7.6-7.6 7.6 7.6 0 0 1 7.6 7.6 7.6 7.6 0 0 1-7.601 7.601Z"
                            fill="#41bdde"
                        />
                    </svg>
                </div>
            </button>
        </div>
        {showComponent && (<Modal
            className="settings-popup"
            show={show}
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ul className="flex flex-wrap gap-y-3 max-h-[300px] overflow-auto">
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/notifications")}
                    >
                        Notifications
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("my-interests")}
                    >
                        My Interests
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/my-address")}
                    >
                        My Address
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/editprofile")}
                    >
                        Edit Profile
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/myWishlist")}
                    >
                        Wishlist
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/privacypolicy")}
                    >
                        Privacy Policy
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/about-us")}
                    >
                        About Us
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/termscondition")}
                    >
                        Terms Of Services
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/faqs")}
                    >
                        FAQs
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("/refundandexchange")}
                    >
                        Exchange Policy
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                    <li
                        className="bg-white py-2 px-6 rounded-full w-full flex justify-between items-center"
                        onClick={() => handleClick("logout")}
                    >
                        Logout
                        <FiChevronRight className="text-2xl translate-x-4" />
                    </li>
                </ul>
            </Modal.Body>
        </Modal>)}
        {showpopup && (<Modal
            className="my-interests-popup"
            show={popup}
            fullscreen={fullscreen}
            onHide={() => setPopup(false)}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="my-interests-details">
                    <div className="other-interests">
                        <h2 className="text-xl font-medium">Select Interests</h2>
                        <div className="other-interests-items ">
                            {interestsList.map((data, index) => (<div key={index}>
                                {(() => {
                                    return (<>
                                        <div
                                            className={interestclassname(data.is_user_selected)}
                                            onClick={() => handleRemoveInterestClick(data.id)}
                                        >
                                            <div className="image">
                                                <img src={data.image}></img>
                                            </div>
                                        </div>
                                    </>);
                                })()}
                            </div>))}
                        </div>
                        <input
                            type="button"
                            value="Submit"
                            onClick={SubmitClick}
                            className="h-[40px] bg-btn-color rounded-full text-white w-full mt-4 active:bg-btn-color active:border-btn-color active:text-white "
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>)}
        <Container fluid>
            <Container>
                <div className="max-lg:hidden">
                    <div className="customer-details">
                        <ul role="list" className="grid grid-cols-3 pt-4 mb-10">
                            <li key={people.email} className="flex justify-between gap-x-6">
                                <div className="flex min-w-0 gap-x-4 items-center">
                                    <div className="profile-photo relative">
                                        <label htmlFor='file-upload-profile-pic'
                                            className='profile w-32 h-32 rounded-full inline-block mx-auto relative'>
                                            <img
                                                className="h-[130px] w-[130px] flex-none rounded-full bg-gray-50 overflow-hidden object-cover"
                                                src={people.imageUrl}
                                                alt=""
                                            />
                                            <div
                                                type="button"
                                                className="rounded-full bg-black border-black h-[30px] w-[30px] p-0 text-sm font-semibold text-white flex items-center justify-center absolute top-[65%] right-1.5"
                                            >
                                                <input
                                                    type="file"
                                                    id="file-upload-profile-pic"
                                                    name="file_upload_profile_pic"
                                                    className='opacity-0 sr-only'
                                                    onChange={(e) => {
                                                        uploadProfile(e);
                                                    }}
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="11.197"
                                                    height="13.771"
                                                    viewBox="0 0 11.197 13.771"
                                                >
                                                    <g data-name="Group 678">
                                                        <path
                                                            data-name="Path 227"
                                                            d="M.122 11.129c.043-.753.082-1.507.134-2.26a.619.619 0 0 1 .129-.313q2.767-3.592 5.543-7.178c.124-.16.246-.322.372-.481a2.365 2.365 0 0 1 3.266-.43q.354.262.7.532a2.347 2.347 0 0 1 .437 3.306q-2.94 3.814-5.9 7.615a.631.631 0 0 1-.25.187Q2.59 12.919.621 13.72c-.371.152-.646-.039-.626-.436.037-.718.08-1.436.12-2.154Zm1.113-2.328 3.13 2.347 4.768-6.156-3.123-2.37Zm8.4-4.461c.153-.2.3-.381.441-.574a1.524 1.524 0 0 0-.293-2.1q-.362-.289-.739-.559a1.519 1.519 0 0 0-1.918.114 8.68 8.68 0 0 0-.623.737ZM.854 12.734l2.747-1.124-2.573-1.929c-.056 1.031-.113 2.03-.172 3.053Z"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </svg>
                                                <span className="sr-only">Change</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-2xl font-bold leading-6 text-black">
                                            {people.name}
                                        </p>
                                        <button
                                            href="#"
                                            className="mt-1 truncate text-sm leading-5 text-[#41BDDE] hover:text-[#41BDDE] bg-transparent h-auto border-0 w-auto px-0 font-normal"
                                            onClick={logOutClick}
                                        >
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li className="flex justify-center gap-x-6 ">
                                <div className="flex min-w-0 gap-x-4 items-center">
                                    <div className="min-w-0 flex-auto text-center" onClick={handleClickBmi}>
                                        <p className="text-xl font-thin leading-6 text-black">
                                            Your BMI Rate
                                        </p>
                                        <p className="text-xl font-bold leading-6 text-black">
                                            {bmiValue !== undefined ? bmiValue : "0.00"}
                                        </p>
                                        <p className="text-xl font-medium leading-6 text-black">
                                            {bmiStatus !== undefined ? bmiStatus : "Check BMI"}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="flex justify-center gap-x-6 ">
                                <div className="flex min-w-0 gap-x-4 items-center">
                                    <div
                                        className="min-w-0 flex-auto text-center"
                                        onClick={() => {
                                            navigate("/blogs");
                                        }}
                                    >
                                        <p className="text-xl font-thin leading-6 text-black">
                                            Health
                                        </p>
                                        <p className="text-xl font-bold leading-6 text-black">
                                            Advise
                                        </p>
                                        <p className="text-xl font-medium leading-6 text-black">
                                            For you
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="flex justify-start gap-[20px] pb-10">
                        <div className="inline-flex flex-col w-25 gap-6">
                            <button
                                className={togglestate === "profile" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                                onClick={() => handleTabClick("/", "profile")}
                            >
                                <span className="text-[23px] font-light text-black w-full flex items-center justify-start gap-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="45.226"
                                        height="45.226"
                                        viewBox="0 0 45.226 45.226"
                                    >
                                        <g data-name="Group 690">
                                            <path
                                                data-name="Path 240"
                                                d="M22.613 0A22.613 22.613 0 1 1 0 22.613 22.613 22.613 0 0 1 22.613 0Z"
                                            />
                                            <g
                                                data-name="Group 689"
                                                transform="translate(-1192.616 -420.291)"
                                            >
                                                <g fill="#fff">
                                                    <path
                                                        data-name="Path 10"
                                                        d="M1223.034 439.829a4.259 4.259 0 0 0-3.163 1.176 6.654 6.654 0 0 1-4.731 2.086 6.265 6.265 0 0 1-4.009-1.635 5.812 5.812 0 0 0-3.79-1.629 5.516 5.516 0 0 0-5.394 4.373l-1.032 4.874a3.715 3.715 0 0 0 3.633 4.484h21.362a3.716 3.716 0 0 0 3.621-4.542l-1.122-4.9a5.513 5.513 0 0 0-5.375-4.287Z"
                                                    />
                                                    <circle
                                                        data-name="Ellipse 2"
                                                        cx="6.475"
                                                        cy="6.475"
                                                        r="6.475"
                                                        transform="translate(1208.479 427.73)"
                                                    />
                                                </g>
                                                <circle
                                                    data-name="Ellipse 89"
                                                    cx="6.318"
                                                    cy="6.318"
                                                    r="6.318"
                                                    transform="translate(1208.875 446.414)"
                                                    fill="#fff"
                                                    stroke="#000"
                                                />
                                                <g data-name="Group 688">
                                                    <path
                                                        data-name="Path 230"
                                                        d="M1215.278 456.348a.769.769 0 1 1 .758-.769.76.76 0 0 1-.758.769Zm-.674-7.391h1.274l-.137 5.106h-1Z"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    Profile
                                </span>
                            </button>
                            <button
                                className={togglestate === "my-orders" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                                onClick={() => handleTabClick(myOrderesURL, "my-orders")}
                            >
                                <span className="text-[20px] font-light text-black w-full flex items-center justify-start gap-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="45.226"
                                        height="45.226"
                                        viewBox="0 0 45.226 45.226"
                                    >
                                        <g
                                            data-name="Group 692"
                                            transform="translate(-1192.541 -507.536)"
                                        >
                                            <circle
                                                data-name="Ellipse 90"
                                                cx="22.613"
                                                cy="22.613"
                                                r="22.613"
                                                transform="translate(1192.541 507.536)"
                                            />
                                            <g data-name="Group 691">
                                                <path
                                                    data-name="Path 8"
                                                    d="M1229.449 525.689a2.637 2.637 0 0 0-2.637-2.637h-4.212l-4.776-7.21a1.184 1.184 0 0 0-.133-1.009 1.2 1.2 0 0 0-1.668-.338 1.2 1.2 0 0 0-.337 1.668 1.179 1.179 0 0 0 .875.516l4.223 6.372h-11.262l4.222-6.372a1.188 1.188 0 0 0 .877-.516 1.2 1.2 0 0 0-.338-1.668 1.2 1.2 0 0 0-1.669.338 1.187 1.187 0 0 0-.131 1.009l-4.772 7.205h-4.211a2.638 2.638 0 0 0-2.643 2.632v.01a2.629 2.629 0 0 0 2.337 2.607c.011.059.008.117.019.177l2.253 10.144a2.918 2.918 0 0 0 2.761 2.381l14.178.1a2.927 2.927 0 0 0 2.824-2.5l1.885-10.248a.235.235 0 0 1 .005-.058 2.627 2.627 0 0 0 2.33-2.603ZM1210.9 538.57a.778.778 0 0 1-1.556 0v-8.278a.778.778 0 1 1 1.556 0Zm3.355 0a.778.778 0 0 1-1.556 0v-8.278a.778.778 0 1 1 1.556 0Zm3.355 0a.779.779 0 0 1-1.559 0v-8.28a.778.778 0 1 1 1.555 0Zm3.355 0a.778.778 0 0 1-1.556 0v-8.278a.778.778 0 1 1 1.556 0Z"
                                                    fill="#fff"
                                                />
                                                <circle
                                                    data-name="Ellipse 91"
                                                    cx="6.318"
                                                    cy="6.318"
                                                    r="6.318"
                                                    transform="translate(1208.951 535.029)"
                                                    fill="#fff"
                                                    stroke="#000"
                                                />
                                                <path
                                                    data-name="Path 231"
                                                    d="m1211.69 541.743 2.328 2.328 4.718-4.718"
                                                    fill="none"
                                                    stroke="#000"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    My Orders
                                </span>
                            </button>
                            <button
                                className={togglestate === "my-address" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                                onClick={() => handleTabClick(myaddressURL, "my-address")}
                            >
                                <span className="text-[20px] font-light text-black w-full flex items-center justify-start gap-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="45.226"
                                        height="45.226"
                                        viewBox="0 0 45.226 45.226"
                                    >
                                        <g
                                            data-name="Group 694"
                                            transform="translate(-1192.12 -596.461)"
                                        >
                                            <circle
                                                data-name="Ellipse 92"
                                                cx="22.613"
                                                cy="22.613"
                                                r="22.613"
                                                transform="translate(1192.12 596.461)"
                                            />
                                            <g data-name="Group 693">
                                                <path
                                                    data-name="Path 232"
                                                    d="M1213.744 631.227a1.354 1.354 0 0 0 1.864 0c2.462-2.309 8.945-8.92 8.945-14.488 0-6.379-3.828-10.165-9.878-10.165-5.972 0-9.763 3.79-9.763 10.165 0 5.625 6.393 12.188 8.832 14.488Z"
                                                    fill="#fff"
                                                />
                                                <circle
                                                    data-name="Ellipse 93"
                                                    cx="3.14"
                                                    cy="3.14"
                                                    r="3.14"
                                                    transform="translate(1211.593 610.213)"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    My Addresses
                                </span>
                            </button>
                            <button
                                className={togglestate === "my-interests" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                                onClick={() => handleTabClick(myInterestsURL, "my-interests")}
                            >
                                <span className="text-[20px] font-light text-black w-full flex items-center justify-start gap-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="45.226"
                                        height="45.226"
                                        viewBox="0 0 45.226 45.226"
                                    >
                                        <g
                                            data-name="Group 696"
                                            transform="translate(-1191.603 -684.196)"
                                        >
                                            <circle
                                                data-name="Ellipse 94"
                                                cx="22.613"
                                                cy="22.613"
                                                r="22.613"
                                                transform="translate(1191.603 684.196)"
                                            />
                                            <g data-name="Group 695">
                                                <path
                                                    data-name="Path 233"
                                                    d="M1220.569 719.562v-7.953a10.66 10.66 0 0 0 3.857-6.8 10.361 10.361 0 0 0-10.24-11.631 10.916 10.916 0 0 0-7.434 3.121 9.717 9.717 0 0 0-2.338 3.671 28.119 28.119 0 0 0-.6 3.729s-1.972 4.972-2.12 5.33a.879.879 0 0 0 .109.97.836.836 0 0 0 .585.292h1.438v2.993a4.02 4.02 0 0 0 3.881 3.939h1.835v2.377a.847.847 0 0 0 .85.847l9.329-.034a.847.847 0 0 0 .848-.851Z"
                                                    fill="#fff"
                                                />
                                                <path
                                                    data-name="Path 234"
                                                    d="M1220.335 701.369a12.861 12.861 0 0 1-8.775-3.1 3.1 3.1 0 0 0-1.834 5.6l3.658 3.573a1.466 1.466 0 0 0 2.044 0l3.988-3.872a3.092 3.092 0 0 0 .919-2.201Z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    My Interests
                                </span>
                            </button>
                            {/* <button
                            className={togglestate === "delivered" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                            onClick={() => handleTabClick(customerSupportURL)}>
                            <span
                                className='text-[20px] font-light text-black w-full flex items-center justify-start gap-8'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="45.226" height="45.226"
                                    viewBox="0 0 45.226 45.226">
                                    <g data-name="Group 698" transform="translate(-1192.161 -774.417)">
                                        <circle data-name="Ellipse 95" cx="22.613" cy="22.613" r="22.613"
                                            transform="translate(1192.161 774.417)" />
                                        <g data-name="Group 697" fill="#fff">
                                            <path data-name="Path 235"
                                                d="M1219.683 799.493a7.643 7.643 0 0 1 2.353.589 8.033 8.033 0 0 1 4.888 8.029 3.287 3.287 0 0 1-1.614 2.828 5.073 5.073 0 0 1-1.637.548 18.527 18.527 0 0 1-3.252.2c-4.225-.005-8.451-.005-12.676-.023a9.308 9.308 0 0 1-2.461-.308 3.314 3.314 0 0 1-2.674-3.328 8.035 8.035 0 0 1 6.891-8.5c.115-.018.232-.023.345-.034a7.325 7.325 0 0 0 2.314 2.906c.784.718 1.63 1.366 2.448 2.047.1.081.173.123.3.023a28.528 28.528 0 0 0 3.747-3.334 10.519 10.519 0 0 0 .851-1.173 2.248 2.248 0 0 0 .177-.47Z" />
                                            <path data-name="Path 236"
                                                d="M1217.789 799.274a4.212 4.212 0 0 1-5.7.28 9.509 9.509 0 0 1-2.669-3.317 12.427 12.427 0 0 1-1.3-4.042 6.71 6.71 0 0 1 13.205-2.333 8.034 8.034 0 0 1-.279 4.075c-.153.516-.356 1.017-.556 1.518a.439.439 0 0 1-.256.228c-.407.108-.822.19-1.235.276a.239.239 0 0 1-.174-.025 2.16 2.16 0 0 0-1.466-.378 10.158 10.158 0 0 0-1.379.061 1.807 1.807 0 0 0-1.451 2.024 1.859 1.859 0 0 0 1.825 1.632c.461.006.92.001 1.435.001Z" />
                                            <path data-name="Path 237"
                                                d="M1203.683 793.241v-1.644a1.614 1.614 0 0 1 .82-1.462c.182-.108.144-.264.155-.406a10.144 10.144 0 0 1 20.1-1.005c.072.384.091.777.15 1.163a.374.374 0 0 0 .139.236 1.688 1.688 0 0 1 .817 1.494v3.217a1.718 1.718 0 0 1-2.521 1.579.314.314 0 0 0-.265.013 8.927 8.927 0 0 1-3.966 1.4 3.293 3.293 0 0 0-.372.046.386.386 0 0 0-.2.118 1.056 1.056 0 0 1-.891.436h-1.244a1.006 1.006 0 1 1 .012-2.011h1.268a1.049 1.049 0 0 1 .791.347.3.3 0 0 0 .21.093 8.326 8.326 0 0 0 3.632-1.15c.172-.105.242-.2.168-.409a1.34 1.34 0 0 1-.05-.44v-3.24a1.642 1.642 0 0 1 .787-1.46.244.244 0 0 0 .137-.261 8.6 8.6 0 0 0-17.033-.905c-.052.3-.09.6-.113.907a.309.309 0 0 0 .115.239 1.717 1.717 0 0 1 .8 1.528c-.005 1.088 0 2.176 0 3.264a1.665 1.665 0 0 1-1.123 1.577 1.749 1.749 0 0 1-1.934-.531 1.614 1.614 0 0 1-.373-1.039c-.018-.567-.016-1.135-.016-1.694Z" />
                                        </g>
                                    </g>
                                </svg>
                                Customer Support
                            </span>
                        </button> */}
                            <button
                                className={togglestate === "notifications" ? "active block-tabs-order bg-[#F8F8F8] border-[#F8F8F8] hover:bg-[#F8F8F8] hover:border-[#F8F8F8] h-[80px] rounded-[53px] px-4" : "block-tabs-order bg-transparent h-[80px] border-transparent hover:border-transparent px-4 opacity-50"}
                                onClick={() => handleTabClick(notificationsURL, "notifications")}
                            >
                                <span className="text-[20px] font-light text-black w-full flex items-center justify-start gap-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="45.226"
                                        height="45.226"
                                        viewBox="0 0 45.226 45.226"
                                    >
                                        <g
                                            data-name="Group 700"
                                            transform="translate(-1192.428 -865.945)"
                                        >
                                            <circle
                                                data-name="Ellipse 96"
                                                cx="22.613"
                                                cy="22.613"
                                                r="22.613"
                                                transform="translate(1192.428 865.945)"
                                            />
                                            <g data-name="Group 699" fill="#fff">
                                                <path
                                                    data-name="Path 238"
                                                    d="M1216.405 876.442a24.947 24.947 0 0 1 3.339 1.5 8.583 8.583 0 0 1 4.192 7.476c.042 2.7-.054 5.4.052 8.1a3.891 3.891 0 0 0 .914 2.07c.549.69 1.422 1.339.841 2.207a2.782 2.782 0 0 1-2.012.973q-8.812.111-17.629-.006a2.5 2.5 0 0 1-1.9-1.055c-.213-.427.262-1.474.738-1.9a2.936 2.936 0 0 0 1.052-2.5c-.063-2.6-.042-5.2-.015-7.808a8.922 8.922 0 0 1 6.358-8.546c.631-.2 1.318-.237 1.24-1.226a1.329 1.329 0 0 1 1.434-1.6c1.005.029 1.373.747 1.36 1.693-.005.227.031.463.036.622Z"
                                                />
                                                <path
                                                    data-name="Path 239"
                                                    d="M1218.021 900.02c-.4 2.026-1.57 3.056-3.218 2.973a2.927 2.927 0 0 1-2.8-2.973Z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    Notifications
                                </span>
                            </button>
                        </div>
                        <div className="w-75 p-[50px] bg-[#F8F8F8] rounded-[30px]">
                            <Outlet />
                        </div>
                    </div>
                </div>
                <div className="lg:hidden pt-10">
                    <div className="bg-[#F6F6F6] p-4 rounded-2xl relative">
                        <div
                            className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F6F6F6] rounded-full w-[80px] h-[80px] p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 164">
                                <defs>
                                    <clipPath id="a">
                                        <circle
                                            data-name="Ellipse 10"
                                            cx="82"
                                            cy="82"
                                            r="82"
                                            transform="translate(-.464 -.464)"
                                            fill="none"
                                        />
                                    </clipPath>
                                </defs>
                                <g data-name="Group 25">
                                    <g
                                        data-name="Group 24"
                                        clip-path="url(#a)"
                                        transform="translate(.464 .464)"
                                    >
                                        <g data-name="Group 4">
                                            <path
                                                data-name="Path 13"
                                                d="m110.182 100.867 31.293 18.589a7.179 7.179 0 0 1 3.014 3.541l2.81 7.135c-2.712 3.65-24.655 32.133-63.383 33.4-42.013 1.381-66.63-30.482-68.809-33.4l4.219-8.672a7.192 7.192 0 0 1 2.714-2.987l30.469-18.6Z"
                                                fill="#513232"
                                            />
                                            <path
                                                data-name="Path 14"
                                                d="m65.947 92.961-13.439 6.908a28.794 28.794 0 0 0 5.425 15.7 28.5 28.5 0 0 0 10.07 8.565l-3.124-11.135h33.842l-3 11.135a33.609 33.609 0 0 0 11.887-13.989 32.361 32.361 0 0 0 2.57-9.28l-11.97-7.154Z"
                                                fill="#a37f79"
                                            />
                                            <path
                                                data-name="Path 15"
                                                d="M64.879 113.003h33.842l-3 11.135-3.619 1.047c1 6.978 1.8 14.481 2.284 22.46.309 5.12.464 10.05.493 14.773a46.83 46.83 0 0 1-26.789.054l2.313-37.478-2.4-.856Z"
                                                fill="#fff"
                                            />
                                            <path
                                                data-name="Path 16"
                                                d="M142.504 120.212a86.4 86.4 0 0 0-12.758 26.954l-.786.572a79.4 79.4 0 0 1 12.685-28.177Z"
                                                fill="#110808"
                                            />
                                            <path
                                                data-name="Path 17"
                                                d="M19.906 120.212a86.307 86.307 0 0 1 6.786 11.349 86.518 86.518 0 0 1 5.972 15.606l.786.572a79.417 79.417 0 0 0-7.023-18.946 78.972 78.972 0 0 0-5.828-9.438Z"
                                                fill="#110808"
                                            />
                                        </g>
                                        <g data-name="Group 23">
                                            <g data-name="Group 5">
                                                <path
                                                    data-name="Path 18"
                                                    d="M100.093 105.009q-.689 3.994-1.372 7.994a40.458 40.458 0 0 1-9 2.855 43.443 43.443 0 0 1-24.84-2.855q-.664-3.948-1.331-7.9a70.8 70.8 0 0 0 2.67-15.334 69.811 69.811 0 0 0 .017-8.461 58.424 58.424 0 0 0-.117-1.709q11.336-.069 22.673-.141l8.351-.051.021.76c.006.225.014.451.022.679.114 3.506.369 7.236.817 11.161q.2 1.789.457 3.637.4 2.852.888 5.533.352 1.96.744 3.832Z"
                                                    fill="#ea9466"
                                                />
                                                <path
                                                    data-name="Path 19"
                                                    d="M98.203 93.712c-1.276.621-3.906.945-8.011.945-.017 0-13.174.678-23.958-13.349a60.631 60.631 0 0 1-1.488-2.038c-.489-.7-.919-1.36-1.3-1.956l25.34 2.144 8.371.709.022.679c.163 4.393.51 8.679 1.024 12.866Z"
                                                    fill="#b96d52"
                                                />
                                            </g>
                                            <path
                                                data-name="Path 20"
                                                d="m57.218 53.473-4.907-8.735a5.143 5.143 0 0 1 .018-5.074l2.308-4.035c-.544.138-1.3.319-2.2.5-1.867.373-2.05.284-2.141.214-.777-.61.553-3.7 1.428-5.283 1.237-2.244 4.292-6.414 12.337-9.422a68.798 68.798 0 0 0-3.7-.713c-4.356-.718-5.53-.483-5.71-1-.452-1.3 5.967-5.713 13.419-7.494a33.038 33.038 0 0 1 13.348-.358 12.046 12.046 0 0 1-1.714-3c-.477-1.212-.732-1.862-.5-2.357.771-1.627 6.354-.45 6.782-.356 2.764.6 7.845 2.341 13.263 8.423q.948-4.577 1.6-4.535.837.054 1.189 7.675c5.846 1.187 7.275 2.006 7.247 2.565-.014.258-.519.558-1.53 1.146a24.771 24.771 0 0 1-2.858 1.428q7.525 3.95 7.141 5-.248.677-3.783.143a11.944 11.944 0 0 1 3.783 6.424c.138.722.522 3.156-2.01 9.375a54.289 54.289 0 0 1-5.269 9.861Z"
                                                fill="#513232"
                                            />
                                            <g data-name="Group 22">
                                                <g data-name="Group 12">
                                                    <g data-name="Group 8">
                                                        <path
                                                            data-name="Path 21"
                                                            d="M104.755 53.865a5.4 5.4 0 0 1 4.229-.956c2.808.786 3.767 4.665 3.772 6.744.011 4.422-4.271 8.79-10.723 10.963Z"
                                                            fill="#ea9466"
                                                        />
                                                        <g data-name="Group 7" fill="#b76456">
                                                            <path
                                                                data-name="Path 22"
                                                                d="M109.411 57.252a1.533 1.533 0 0 0-1.129-.418c-2.279.26-3.032 7.844-3.149 9.1.466-.26 3.742-2.124 4.715-4.738a4.141 4.141 0 0 0 .267-2.532 3.681 3.681 0 0 0-.704-1.412Z"
                                                                opacity=".57"
                                                            />
                                                            <g data-name="Group 6">
                                                                <path
                                                                    data-name="Path 23"
                                                                    d="M108.089 56.469c-1.523.024-2.552 3.538-2.717 4.133-.782 2.81-.4 5.322-.239 5.327.148 0 .146-2.021 1.062-5.117.862-2.912 1.609-3.64 2.254-3.712.468-.051.923.235.962.15a1.628 1.628 0 0 0-1.322-.781Z"
                                                                />
                                                                <path
                                                                    data-name="Path 24"
                                                                    d="M107.957 60.769a3.151 3.151 0 0 1-.2 3.082c-.125-.018.519-1.707-.367-2.763-.5-.6-1.367-.845-1.313-1.324a.377.377 0 0 1 .153-.269c.358-.203 1.29.5 1.727 1.274Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g data-name="Group 11">
                                                        <path
                                                            data-name="Path 25"
                                                            d="M58.978 53.899a5.407 5.407 0 0 0-4.214-1.025c-2.82.742-3.842 4.6-3.879 6.683-.084 4.422 4.129 8.859 10.544 11.135Z"
                                                            fill="#ea9466"
                                                        />
                                                        <g data-name="Group 10" fill="#b76456">
                                                            <path
                                                                data-name="Path 26"
                                                                d="M54.639 57.243a1.533 1.533 0 0 1 1.137-.4c2.275.3 2.905 7.893 3 9.145-.462-.267-3.707-2.184-4.638-4.812a4.155 4.155 0 0 1-.227-2.536 3.7 3.7 0 0 1 .728-1.397Z"
                                                                opacity=".57"
                                                            />
                                                            <g data-name="Group 9">
                                                                <path
                                                                    data-name="Path 27"
                                                                    d="M55.975 56.482c1.521.049 2.493 3.579 2.649 4.176.738 2.823.319 5.327.155 5.33-.148 0-.114-2.023-.98-5.132-.814-2.926-1.551-3.665-2.194-3.749-.466-.06-.926.22-.965.135a1.634 1.634 0 0 1 1.335-.76Z"
                                                                />
                                                                <path
                                                                    data-name="Path 28"
                                                                    d="M56.037 60.783a3.148 3.148 0 0 0 .148 3.083c.125-.015-.491-1.714.412-2.756.511-.59 1.379-.823 1.334-1.3a.372.372 0 0 0-.148-.271c-.353-.217-1.297.477-1.746 1.244Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g data-name="Group 21">
                                                    <path
                                                        data-name="Path 29"
                                                        d="m106.113 45.05-1.356 8.7q-1.787 11.448-3.569 22.893a4.605 4.605 0 0 1-1.7 2.912 28.832 28.832 0 0 1-15.2 6.136 29.509 29.509 0 0 1-20.408-6.2 4.613 4.613 0 0 1-1.715-2.813q-1.169-6.358-2.237-12.987-.825-5.174-1.535-10.216v-.02q-.635-4.472-1.176-8.831a22.944 22.944 0 0 1 7.851-10.137 22.4 22.4 0 0 1 7.281-3.426q-1.029 1.247-2.059 2.49-2.721 3.289-5.436 6.574a28.478 28.478 0 0 0 17.4-7.72 29.872 29.872 0 0 0 3.38-3.775 47.064 47.064 0 0 1-6.141 9.85 21.781 21.781 0 0 0 14.922-7.358c.539-.611.97-1.171 1.307-1.636a36.37 36.37 0 0 1 4.471 4.711 36.9 36.9 0 0 1 5.92 10.853Z"
                                                        fill="#ea9466"
                                                    />
                                                    <g data-name="Group 19">
                                                        <g data-name="Group 17">
                                                            <g data-name="Group 13" fill="#3f1f1c">
                                                                <path
                                                                    data-name="Path 30"
                                                                    d="M76.049 47.706a1.1 1.1 0 0 1 .355.276 1.265 1.265 0 0 1 0 1.6 1.232 1.232 0 0 1-1.59.221 10.938 10.938 0 0 0-4.584-1.622c-3.345-.356-5.981 1.082-6.164.671-.145-.326 1.372-1.576 3.157-2.223 3.597-1.3 7.196.227 8.826 1.077Z"
                                                                />
                                                                <path
                                                                    data-name="Path 31"
                                                                    d="M87.26 47.706a1.125 1.125 0 0 0-.355.276 1.259 1.259 0 0 0 0 1.6 1.23 1.23 0 0 0 1.588.221 10.952 10.952 0 0 1 4.584-1.622c3.346-.356 5.983 1.082 6.165.671.143-.326-1.374-1.576-3.157-2.223-3.595-1.3-7.195.227-8.825 1.077Z"
                                                                />
                                                            </g>
                                                            <g data-name="Group 14" fill="#3f1f1c">
                                                                <path
                                                                    data-name="Path 32"
                                                                    d="M70.685 50.19a5.219 5.219 0 0 1 5.045 1.523c-.074.189-2.281-1.225-4.807-.476-1.828.543-2.756 1.9-2.9 1.761-.169-.165.811-2.188 2.662-2.808Z"
                                                                />
                                                                <path
                                                                    data-name="Path 33"
                                                                    d="M93.034 50.19a5.214 5.214 0 0 0-5.043 1.523c.074.189 2.28-1.225 4.805-.476 1.829.543 2.758 1.9 2.9 1.761.17-.165-.811-2.188-2.662-2.808Z"
                                                                />
                                                            </g>
                                                            <g
                                                                data-name="Group 15"
                                                                transform="translate(70.876 51.95)"
                                                            >
                                                                <ellipse
                                                                    data-name="Ellipse 8"
                                                                    cx="1.903"
                                                                    cy="3.022"
                                                                    rx="1.903"
                                                                    ry="3.022"
                                                                    fill="#1e0f0f"
                                                                />
                                                                <path
                                                                    data-name="Path 34"
                                                                    d="M2.379 1.974a.452.452 0 1 1-.452-.452.452.452 0 0 1 .452.452Z"
                                                                    fill="#fff"
                                                                />
                                                            </g>
                                                            <g
                                                                data-name="Group 16"
                                                                transform="translate(88.578 51.95)"
                                                            >
                                                                <ellipse
                                                                    data-name="Ellipse 9"
                                                                    cx="1.903"
                                                                    cy="3.022"
                                                                    rx="1.903"
                                                                    ry="3.022"
                                                                    fill="#1e0f0f"
                                                                />
                                                                <path
                                                                    data-name="Path 35"
                                                                    d="M2.356 1.974a.452.452 0 1 1-.452-.452.452.452 0 0 1 .452.452Z"
                                                                    fill="#fff"
                                                                />
                                                            </g>
                                                        </g>
                                                        <g data-name="Group 18" fill="#b76456">
                                                            <path
                                                                data-name="Path 36"
                                                                d="m80.931 56.755-3.267 8.712a.514.514 0 0 0 .168.593 6.213 6.213 0 0 0 7.6.01.516.516 0 0 0 .16-.612l-3.7-8.724a.518.518 0 0 0-.961.021Z"
                                                            />
                                                            <path
                                                                data-name="Path 37"
                                                                d="M91.444 65.067a.909.909 0 1 1 1.488 1.034 14.805 14.805 0 0 1-5.877 4.569c-5.778 2.414-11.555.109-11.527-.143s6.32 1.714 11.776-1.534a13.47 13.47 0 0 0 4.14-3.926Z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g data-name="Group 20" fill="#b76456">
                                                        <path
                                                            data-name="Path 38"
                                                            d="M65.07 34.486a22.4 22.4 0 0 1 7.281-3.426q-1.029 1.247-2.059 2.49a20.114 20.114 0 0 0-4.223 2.149 21.207 21.207 0 0 0-7.494 9.065q-.09 4.356-.177 8.708v-.019q-.635-4.472-1.176-8.831a22.943 22.943 0 0 1 7.848-10.136Z"
                                                            opacity=".64"
                                                        />
                                                        <path
                                                            data-name="Path 39"
                                                            d="M85.629 28.63a47.064 47.064 0 0 1-6.141 9.85 20.8 20.8 0 0 0 2-3.783 20.28 20.28 0 0 0 .774-2.3 29.874 29.874 0 0 0 3.367-3.767Z"
                                                            opacity=".64"
                                                        />
                                                        <path
                                                            data-name="Path 40"
                                                            d="m106.112 45.05-1.356 8.7q-.113-4.416-.214-8.839a35.979 35.979 0 0 0-10.135-13.785c.539-.611.97-1.171 1.307-1.636a36.371 36.371 0 0 1 4.471 4.71 36.9 36.9 0 0 1 5.927 10.85Z"
                                                            opacity=".64"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="flex pt-4 justify-center">
                            <span>{people.name}</span>
                            {/* <strong className="lastname">Althaf</strong> */}
                        </div>
                        <div className="text-center font-light text-xs mt-1">
                            Track & Get Healthy
                        </div>

                        <ul className="flex gap-x-2 justify-center pt-2">
                            <li>
                                <Link to={"/ordersStatus"}>
                                    <img src={profilemyOrder} alt="profilemyOrder" />
                                </Link>
                            </li>
                            <li>
                                <Link onClick={handleClickBmi}>
                                    <img src={profilebmi} alt="profilebmi" />
                                </Link>
                            </li>
                            <li>
                                <Link to={"/blogs"}>
                                    <img src={profilediat} alt="profilediat" />
                                </Link>
                            </li>
                            <li>
                                <Link to={"/blogs"}>
                                    <img src={profilegym} alt="profilegym" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {showBMIPopup && <BMI onDataReceived={handleDataReceivedBmi} />}
            </Container>
        </Container>
    </>);
}

export default CustomerProfile;
