import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getEmiratesData, setAddressData} from '../../../redux/profile';
import {toast} from 'react-toastify';
import { getUser } from '../../../Constants/storedValues';

function AddNewAddressMobileView() {
    const user = getUser();
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        defaultValues: {
            mobile: user.mobile,
        },
        mode: "onChange"
    });
    const {setNewAddress} = useSelector((state) => state.profile);
    const {getEmiratesList} = useSelector(state => state.profile);


    const [isAddAddress, setIsAddAddress] = useState(false);
    const dispatch = useDispatch();
    const pathURL = '/myProfile';
    const listAddressURL = '/my-address';
    const navigate = useNavigate();
    const [enabled, setEnabled] = useState(false);

    const onSubmit = (data) => {


        const formattedData = {...data};

        if (data.is_default === true) {
            formattedData.is_default = 1;
        } else if (data.is_default === false) {
            formattedData.is_default = 0;

        }
        dispatch(setAddressData(formattedData));
        navigate(listAddressURL);
        toast.success("New Address Added", {
            position: toast.POSITION.TOP_RIGHT, autoClose: 2000,

        });


    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsAddAddress(setNewAddress);

        if (isAddAddress) {
            // navigate(listAddressURL);
            setIsAddAddress(false);

        }
    }, [setNewAddress]);
    useEffect(() => {
        dispatch(getEmiratesData());
        console.log("emirates", getEmiratesList);

    }, []);

    return (<div>
        <h2 className='text-3xl font-medium md:mb-12 sr-only'>New Address</h2>
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-2 gap-2.5 px-3'>
                    <label className='sr-only'>First Name</label>
                    <input
                        className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border ${errors.f_name ? ' error' : ''}`}
                        placeholder={errors.f_name ? 'First Name Required' : "First Name"} {...register("f_name", {required: true})} />
                    <label className='sr-only'>Last Name</label>
                    <input
                        className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border ${errors.f_name ? ' error' : ''}`}
                        placeholder={errors.l_name ? 'Last Name Required' : 'Last Name'} {...register("l_name", {required: true})} />
                    <label className='sr-only'>Address</label>
                    <input
                        className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                        placeholder={errors.full_address ? 'Address Required' : 'Address'} {...register("full_address", {required: true})} />
                    <label className='sr-only'>Apartment</label>
                    <input
                        className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                        placeholder={errors.building_name ? 'Apartment Name Required' : 'Apartment'} {...register("building_name", {required: true})} />
                    <label className='sr-only'>City</label>
                    <input
                        className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border col-span-2 ${errors.f_name ? ' error' : ''}`}
                        placeholder='City'  {...register("city", {required: true})} />
                    <label className='sr-only'>Emirate ID</label>
                    <div className='flex w-full gap-2 col-span-2'>
                        <label className='sr-only'>Phone</label>
                        <span
                            className='h-[48px] rounded-lg border px-4 placeholder:font-light bg-white inline-flex justify-center items-center text-gray-500 text-xs'>+971</span>
                        <input
                            className={`h-[48px] rounded-lg px-4 placeholder:font-light placeholder:text-gray-600 placeholder:text-xs border block w-full ${errors.f_name ? ' error' : ''}`}
                            placeholder={errors.building_name ? 'Contact Number Required' : 'Contact'} {...register("mobile", {required: true})} />
                        <input hidden {...register("other_address_type_name")} />
                    </div>
                    <select
                        className='h-[48px] w-full rounded-lg px-3 mb-2 placeholder:font-light bg-white col-span-2 text-sm'
                        {...register("emirate_master_id", {required: true})} >
                        {getEmiratesList.map((data, index) => (
                            <option key={index} value={data.id}>{data.name}</option>))}
                    </select>
                    <div className='flex justify-between items-center flex-wrap col-span-2 px-3'>
                        <div className='inline-flex w-full mb-6'>
                            <label className='sr-only'>Address Type</label>
                            <div className='flex gap-6 address-type'>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input defaultChecked type="radio"
                                                   value={1} {...register("address_type", {required: true})} />
                                        </span>
                                        <span>Home</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                   value={2}  {...register("address_type", {required: true})} />
                                        </span>
                                        <span>Work</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                   value={3}  {...register("address_type", {required: true})} />
                                        </span>
                                        <span>Other</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="inline-flex">
                            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                                <input
                                    {...register("is_default")}
                                    type="checkbox"
                                    className="accent-btn-color w-4 h-4 border-none"
                                    checked={enabled}
                                    onClick={() => {
                                        setEnabled(!enabled);
                                    }}
                                />
                                <span className="ml-3 text-sm font-normal text-gray-900">
                                    Set this address as default
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='flex w-full col-span-2 gap-3 px-3'>
                        <input className='bg-black rounded-full text-white px-3 w-full h-[2.5rem] uppercase mt-6 font-medium' value="Save"
                               type="submit"/>
                    </div>
                </div>
            </form>
        </div>
    </div>);
}

export default AddNewAddressMobileView
