import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllReturnReasonApi, getOrderDetailsApi } from "../services/apiCalls";

export const getOrderDetails = createAsyncThunk("OrderDetails/getOrddrDetails", async (reqData) => {
    const res = await getOrderDetailsApi(reqData);
    return res.data;
})

export const getAllReturnReason = createAsyncThunk("OrderDetails/getAllReturnReason", async () => {
    const res = await getAllReturnReasonApi({});
    return res.data;
})

const INITIAL_STATE = {
    orderDetails: {},
    orderProducts: [],
    returnReason: [],
    loader: false,
}

const orderDetailsSlice = createSlice({
    name: "orderDetails",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrderDetails.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.orderDetails = action.payload.order_data[0];
                    const data = action.payload.order_data_product[0];
                    state.orderProducts = data.order_product_data

                    console.log("haii", action.payload)
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getOrderDetails.rejected, (state, action) => {
                console.log("error api issue")
            })
            .addCase(getAllReturnReason.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getAllReturnReason.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.returnReason = action.payload.result
                }
            })
            .addCase(getAllReturnReason.rejected, (state, action) => {
                console.log("error api issue")
            })
    }
})

export default orderDetailsSlice.reducer;