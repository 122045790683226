import React, {useEffect, useState} from "react";
import ic_cart_white from "../../assets/images/icons/ic_cart_white.png";
import icon_close_bgblack from "../../assets/images/icons/icon_close_bgblack.png";
import trash from "../../assets/images/trash.png";
import ic_cart_blue from "../../assets/images/icons/ic_cart_blue.svg";
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getwishlist} from "../../redux/Wishlist";
import {addToCartAction, wishlistAction} from "../../redux/product";
import {addToCart, toggleWishlist} from "../../services/apiCalls";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getCartPage} from "../../redux/cartRedux";

function Wishlist() {
    const dispatch = useDispatch();
    const {wishlist} = useSelector((state) => state.wishlist);
    const {wishlistSuccess} = useSelector((state) => state.product);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getwishlist());
        return;
    }, []);

    const navigate = useNavigate();

    const wishlistRemoveClick = async (id) => {
        const data = {
            product_id: id,
        };
        const addWishlist = await toggleWishlist(data);
        if (addWishlist.data.code === 1) {
            toast.error(addWishlist.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            dispatch(getwishlist());
        } else {
            toast.error(addWishlist.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const {addToCartSuccess} = useSelector((state) => state.product);
    const { cartpageBilling, cartpageItems } = useSelector((state) => state.cart);
    const addToCartClick = async (product) => {
        const data = {
            product_id: product.id,
            qty: 1,
            mrp: product.mrp,
            selling_price: product.selling_price,
            action: "1",
            flag: "1",
        };
        const addtocart = await addToCart(data);
        console.log("addtocart ", addtocart);
        if (addtocart.data.code === 1) {
            dispatch(getCartPage());
            toast.success("Product Added to Cart", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            window.dataLayer.push(
                {
                  'event': 'add_to_cart',
                  'ecommerce': {
                    "currency": "AED",
                    "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                    "items": cartpageItems,
                  }
                }
              );
            const requestData = {
                product_id: product.id,
            };
            const addWishlist = await toggleWishlist(requestData);
            if (addWishlist.data.code === 1) {
                dispatch(getwishlist());
            }
        } else {
            toast.error(addtocart.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }

    };

    const isTab = window.innerWidth <= 991;

    return (
        <Container className='max-md:px-0'>
            {wishlist.length > 0 ?
                <div>
                    {isTab ? (
                        <div className="mob bg-[#F8F8F8] rounded-[30px] p-6">
                            <div className="text-xs font-medium pl-1 mb-4">
                                Your Shopping Wishlist
                            </div>
                            <ul className="w-full">
                                {wishlist.map((product, index) => {
                                    return (
                                        <li className="text-sm leading-none flex mb-2 p-2 bg-[#f6f6f6] rounded-[16px]"
                                            key={index}>
                                            <div className="block">
                                                <div className="flex items-start">
                                                    {product.product_images.map((image) => {
                                                        return (
                                                            <div className="w-full h-full max-w-[4rem] max-h-[4rem]">
                                                                <img alt='image' className="w-full h-full"
                                                                     src={image.file}/>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className="w-auto px-3" key={index}>
                                                        <p className="font-medium text-xs mb-2">{product.name}</p>
                                                        <div className="price mt-auto">
                                                            <p className="font-semibold text-xs">
                                                                {product.selling_price} <span className='line-through font-normal'>{product.mrp}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="inline-flex flex-col-reverse justify-between gap-2 items-center ml-auto">
                                                <button
                                                    className="w-7 h-7 inline-flex gap-2 items-center justify-center align-middle p-1 bg-transparent border-transparent"
                                                    onClick={() => addToCartClick(product)}
                                                >
                                                    <img
                                                        src={ic_cart_white}
                                                        alt="cart"
                                                        width={"21px"}
                                                        height={"20px"}
                                                        className='grayscale invert brightness-75'
                                                    />
                                                    <span className='sr-only'>Add to Cart</span>
                                                </button>
                                                <button
                                                    className="w-7 h-7 inline-flex gap-2 items-center justify-center align-middle p-1 bg-transparent border-transparent"
                                                    onClick={() => wishlistRemoveClick(product.id)}
                                                >
                                                    <img
                                                        src={trash}
                                                        alt="close"
                                                        width={"18px"}
                                                        height={"18px"}
                                                    />
                                                    <span className="sr-only">close</span>
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : (
                        <div className="desktop bg-[#F8F8F8] px-10 pt-12 pb-28 mb-[90px] rounded-[30px] max-md:p-4">
                            <div className="text-[25px] max-md:text-sm font-bold pl-4 ">
                                Your Shopping Wishlist
                            </div>
                            <table className="w-full">
                                <thead className='max-md:hidden'>
                                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                    <th className="font-medium text-left pl-4 text-[20px]">
                                        Products
                                    </th>
                                    <th className="font-medium text-center px-4 text-[20px]">
                                        Price
                                    </th>
                                    <th className="font-normal text-center px-4 sr-only">Actions</th>
                                </tr>
                                </thead>
                                <tbody className="w-full">
                                {wishlist.map((product, index) => {
                                    return (
                                        <tr className="h-20 text-sm leading-none text-gray-800 max-md:flex" key={index}>
                                            <td className="pl-4 border-r border-[#D5D5D5] cursor-pointer">
                                                <div className="flex items-center">
                                                    {product.product_images.map((image) => {
                                                        return (
                                                            <div className="w-10 h-10">
                                                                <img alt='image' className="w-full h-full"
                                                                     src={image.file}/>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className="px-3" key={index}>
                                                        <p className="font-light text-[20px] max-md:text-sm">{product.name}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-4 border-r border-[#D5D5D5] text-center">
                                                <p className="font-bold text-[25px]">
                                                    {product.selling_price}
                                                </p>
                                            </td>
                                            <td className="md:px-4 2xl:px-0 text-end gap-[40px] justify-end items-center max-md:px-0 whitespace-nowrap">
                                                <button
                                                    className="px-6 h-[36px] text-[20px] font-light inline-flex gap-2 items-center justify-center align-middle max-md:p-1"
                                                    onClick={() => addToCartClick(product)}
                                                >
                                                    <img
                                                        src={ic_cart_white}
                                                        alt="close"
                                                        width={"21px"}
                                                        height={"20px"}
                                                    />
                                                    <span className='max-md:sr-only'>Add to Cart</span>
                                                </button>
                                                <button
                                                    className="bg-transparent w-auto h-auto p-0 border-0 inline-block ml-[40px] align-middle"
                                                    onClick={() => wishlistRemoveClick(product.id)}
                                                >
                                                    <img
                                                        src={icon_close_bgblack}
                                                        alt="close"
                                                        width={"36px"}
                                                        height={"36px"}
                                                    />
                                                    <span className="sr-only">close</span>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div> :
                <div>
                    <div className="text-center">

                        <p className="pb-3 font-semibold pt-4">Look like you haven't made your choice yet</p>
                        <button className="w-full text-sm max-w-[350px] h-[40px] mx-auto  " onClick={() => navigate(-1)}> Start Shopping</button>
                    </div>
                </div>
            }
        </Container>
    );
}

export default Wishlist;
